import 'antd/dist/antd.less';
import 'shookt/dist/index.css';
import './assets/index.less';
import { Shookt, intercept } from 'shookt';
import { onRoute, authenticate } from './session';
import * as pages from './pages';
export const state = {
  SETUPPATH: '/setup',
  pageSize: 20,
  screens: {},
  appName: 'Enay PH',
  logo: 'https://enay.s3.amazonaws.com/test/assets/enay.png',
  APP_VERSION:'v1.2.0'
};
const App = () => (
  <Shookt
    pages={pages}
    state={state}
    onRoute={onRoute}
    authenticate={authenticate}
  />
);

intercept();
export default App;
