import page from 'page';
import PaymentsList from './PaymentsList';
import PaymentsView from './PaymentsView';

export const paymentsRedirect = {
  path: '/payments',
  priv: 'payments',
  handler: () => page.redirect('/payments/all')
};

export const paymentsListRoute = {
  path: '/payments/all',
  priv: 'payments',
  handler: props => <PaymentsList {...props} />
};

export const paymentsViewRoute = {
  path: '/payments/:id',
  priv: 'payments',
  handler: props => <PaymentsView {...props} />
};
