import { Modal, Button } from 'antd';
import React, { useState, useEffect } from 'react';
import { ProvinceSelect, DistributorSelect } from '../../components';
import provinceGet from '../province/provinceGet';
import distributorGet from '../company/distributor/distributorGet';
import pagejs from 'page';
import qs from 'qs';
const MarketModal = ({ visible, onClose, onUpdate }) => {
  const [province, setProvince] = useState(null);
  const [distributor, setDistributor] = useState([]);
  const [isButtonEnabled, setIsButtonEnabled] = useState(false);
  const [distributorOptions, setDistributorOptions] = useState([]);
  const [provinceOptions, setProvinceOptions] = useState([]);
  const [findout, setFindout] = useState(null);
  
  useEffect(() => {
    setDistributor([]);
  }, [province]);
  const fetchDistributors = async (val,id,label) => {
    try {


      const url = `/distributor?provincest=${val}`;
     
      const response = await distributorGet(label);
     
      if (response && response.data && Array.isArray(response.data)) {
        
        const distributorOptions = response.data.map((distributor) => ({
          value: distributor.id,
          label: distributor.name,
        }));
        setDistributorOptions(distributorOptions);
      }
    } catch (error) {
      
    }
  };

  useEffect(() => {
    
    async function fetchProvinces() {
      try {
        const response = await provinceGet();
        if (response && response.data) {
          setProvinceOptions(response.data);
        }
      } catch (error) {
       
      }
    }

    fetchProvinces();
  }, []);



  
  const handleProvinceChange = (val, id, label) => {
    setProvince(val);
  
    setDistributor([]);
    // Check if payload contains the distributor key and remove it
  
  
  
    setIsButtonEnabled(Boolean(id) && Boolean(distributorOptions));
  
    if (label) {
      fetchDistributors(val, id, label);
    } else {
      setDistributorOptions([]);
    }
  };
  
  const upChange = (vals = {}) => {
   
    if ('province' in vals) {
      setDistributor([]);
      setProvince(vals.province || null);
     
    }
    if ('distributor' in vals) {
      setDistributor(vals.distributor || null);
    } 
    
    if (findout) {
      clearTimeout(findout);
    }
    setFindout(
      setTimeout(() => {
      
        const payload = {
          ...{distributor ,province},
          ...vals,
        };
        if (payload.distributor === null || (Array.isArray(payload.distributor) && payload.distributor.length === 0)) {
          delete payload.distributor;
        }
        if (!payload.distributor) {
          delete payload.distributor;
        }
        if (!vals.province) {
          delete payload.province;
        }
        
        let path = '/';
        if (Object.keys(payload).length) {
          path = `/?${qs.stringify(payload)}`;
        }
      
        pagejs(path);
      }, 250)
    );
  };
  
  const handleDistributorChange = (val) => {
    setDistributor([val]);
    setIsButtonEnabled(Boolean(province) && Boolean(val) && val.length > 0);
  };

  useEffect(() => {
   
  }, [province, distributorOptions]);

  const handleProceed = () => {
    
    // onUpdate({ province, distributor,provinceOptions,distributorOptions });
    onClose();
    
  };

  return (
    <Modal
      title="Welcome to Enay"
      visible={visible}
      closable={false}
      footer={[
        <div className="text-center" key="proceed">
          <Button
            key="proceed"
            type="primary"
            onClick={handleProceed}
            disabled={!isButtonEnabled}
            id="proceed-button"
          >
            Proceed
          </Button>
        </div>
      ]}
    >
      <label>So we can help you better, let us know where you're ordering from and which wholesaler/distributor you prefer</label>
      <p></p>
      <label>Thanks!</label>
      <p></p>
      <ProvinceSelect
 onChange={(val,label, id) => {
  handleProvinceChange(val,label, id);
  upChange({ province: val,distributor: [], page: 1 });
}} value={province}
  options={provinceOptions}
  noOnloadChange
  allowClear
/>
      <p></p>
      <DistributorSelect
        mode="multiple"
        onChange={(val) => {
          handleDistributorChange(val);
          upChange({ distributor: val, page: 1 });
        }}value={distributor}
        options={distributorOptions}
        noOnloadChange
        allowClear
        disabled={!province}
        province={province} // Pass the selected 'province' to DistributorSelect
      />
    </Modal>
  );
};

export default MarketModal;
