import page from 'page';
import ProductView from './ProductView';
import ProductList from './ProductList';

export const productRedirect = {
  path: '/product',
  priv: 'product',
  handler: () => page.redirect('/product/all')
};

export const productListRoute = {
  path: '/product/all',
  priv: 'product',
  handler: props => <ProductList {...props} />
};

export const productViewRoute = {
  path: '/product/:id',
  priv: 'product',
  handler: props => <ProductView {...props} />
};
