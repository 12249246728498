import { useContext, useState, useEffect } from 'react';
import { Form, Button, Select } from 'antd';
import { Barangay, Context, Erratum, Utils } from 'shookt';
import coverageAreaPost from './coverageAreaPost';

const CoverageAreaForm = ({ prefix = '', suffix = '', item = {}, companyId }) => {
  const [state, dispatch] = useContext(Context);
  const [form] = Form.useForm();
  const [brgy, setBrgy] = useState({});

  const fields = [
    `${prefix}region${suffix}`,
    `${prefix}province${suffix}`,
    `${prefix}towncity${suffix}`,
    `${prefix}barangay${suffix}`
  ];

  const brgyObj = { values: brgy, onChange: setBrgy, form, fields };
  const [region, province, towncity, barangay] = Barangay(brgyObj);

  useEffect(() => {
    (async () => {
      setBrgy({
        region: item.region,
        province: item.province,
        towncity: item.towncity,
        barangay: item.barangay
      });
    })();
  }, [item.region, item.province, item.towncity, item.barangay]);

  const onFinish = async values => {
    const payload = {
      id: item.id,
      region: values.region,
      province: values.province,
      towncity: values.towncity,
      barangay: values.barangay,
      model_id: companyId
    };

    const { status } = await coverageAreaPost(payload);
    if (status === 200) {
      dispatch({ delete: ['drawer'] });
      dispatch({ upsert: { reload: true } });
    }
  };

  return (
    <Form
      form={form}
      layout='vertical'
      onFinish={onFinish}
      onValuesChange={Utils.clearError(dispatch)}
    >
      <Form.Item
        label='Region'
        name={fields[0]}
        rules={[{
          required: true,
          message: 'Please select a region'
        }]}
      >
        <Select {...region} autoComplete='off' />
      </Form.Item>

      <Form.Item
        label='Province'
        name={fields[1]}
      >
        <Select {...province} autoComplete='off' />
      </Form.Item>

      <Form.Item
        label='Town/City'
        name={fields[2]}
      >
        <Select {...towncity} autoComplete='off' />
      </Form.Item>

      <Form.Item
        label='Barangay'
        name={fields[3]}
      >
        <Select {...barangay} autoComplete='off' />
      </Form.Item>
      <Erratum />

      <Form.Item>
        <Button
          block
          type='primary'
          htmlType='submit'
          disabled={state.loading}
          loading={state.loading}
        >
          {item.id ? 'Save changes' : 'Add new coverage area'}
        </Button>
      </Form.Item>
    </Form>
  );
};

export default CoverageAreaForm;
