import { Row, Col, Space, Divider, Typography } from 'antd';
import s from './header.module.less';

const Header = props => {
  let title = props.title || '';
  if (typeof props.title === 'string') {
    title = (
      <Typography.Title
        className={s.title}
        level={props.level || 5}
        ellipsis
      >
        {props.title}
      </Typography.Title>
    );
  }

  return (
    <div style={props.style}>
      <Row
        wrap={false}
        align='middle'
        style={{
          marginTop: props.top || (props.noTop ? 0 : 15),
          marginBottom: props.bottom || (props.noBottom ? 0 : 15)
        }}
      >
        <Col flex='auto' className='shkt-header'>
          <Divider className={s.divider} orientation={props.orientation || 'left'} orientationMargin={props.orientationMargin || 0} plain={props.plain}>
            <Space
              align='center'
              justify='middle'
            >
              {title}
            </Space>
          </Divider>
        </Col>
        <Col flex='none'>
          <div className={s.extra}>
            {props.extra}
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Header;
