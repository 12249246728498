import page from 'page';
import { useContext, useEffect, useState } from 'react';
import { Typography, Divider, Form, Button } from 'antd';
import { Context, ImgUpload, Erratum, Utils, USERPATH } from 'shookt';
import { FaUserAlt } from 'react-icons/fa';
import { authenticate } from '../../session';
import { Panel } from '../../components';
import profilePost from '../profile/profilePost';

const SetupPhoto = props => {
  const [state, dispatch] = useContext(Context);
  const [avatar, setAvatar] = useState('');

  const onFinish = async () => {
    if (!avatar) {
      return dispatch({ upsert: { error: 'No photo selected.' } });
    }
    const { status } = await profilePost({ avatar });
    if (status === 200) {
      await authenticate(dispatch);
      page(USERPATH);
    }
  };

  useEffect(() => {
    setAvatar(state.user.avatar);
  }, []);

  return (
    <Panel style={{ maxWidth: 345 }}>
      <Form
        layout='vertical'
        onFinish={onFinish}
        onValuesChange={Utils.clearError(dispatch)}
      >
        <Typography.Paragraph className='text-center'>
          Complete your account details.
        </Typography.Paragraph>

        <Divider plain>Profile Photo</Divider>

        <Form.Item className='text-center'>
          <ImgUpload
            message='Browse'
            imgStore={avatar}
            imgSet={setAvatar}
            icon={<FaUserAlt />}
            size={150}
            round
          />
        </Form.Item>

        <Erratum className='text-center' />

        <Form.Item>
          <Button
            block
            type='primary'
            htmlType='submit'
            disabled={state.loading}
            loading={state.loading}
          >
            Save profile photo
          </Button>
        </Form.Item>

        <Button href={USERPATH} type='link' block>
          Skip
        </Button>
      </Form>
    </Panel>
  );
};

export default SetupPhoto;
