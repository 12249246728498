import { useContext, useState } from 'react';
import { Button } from 'antd';
import { Dashboard, DataList, Ellipsis, Header } from '../../components';
import { Context } from 'shookt';
import companyGet from './companyGet';
import CompanyForm from './CompanyForm';

const CompanyList = props => {
  const [state, dispatch] = useContext(Context);
  const [values, setValues] = useState({});
  const toDrawer = Dashboard.toDrawer(dispatch);

  const loadData = ({ find, sort, page = 1 } = {}) => {
    dispatch({ upsert: { loading: true } });
    setTimeout(async () => {
      const { data } = await companyGet('all', {
        sort, page, find, pageSize: state.pageSize
      });
      dispatch({ upsert: { companies: data.data || [] } });
      setValues(data);
    }, 200);
  };

  return (
    <Dashboard>
      <Header
        noTop
        level={3}
        title='Distributors'
        extra={(
          <Button
            type='primary'
            onClick={toDrawer(<CompanyForm />, 'Create new distributor')}
          >
            Add new distributor
          </Button>
        )}
      />

      <DataList
        rowUrl={item => `/company/${item.id}`}
        values={values}
        storeKey='company'
        onChange={loadData}
        hasAvatar={{ size: 46 }}
        countLabel='distributors'
        dataSource={state.companies}
        titleHandler={item => (<Ellipsis>{item.name}</Ellipsis>)}
        bodyHandler={item => (
          <Ellipsis type='secondary' className='lisht-content'>
            {item.description}
          </Ellipsis>
        )}
        sortOptions={[
          { label: 'Newest', value: { column: 'created_at', order: 'desc' } },
          { label: 'Oldest', value: { column: 'created_at', order: 'asc' } },
          { label: 'Updated', value: { column: 'updated_at', order: 'desc' } },
          { label: 'Name A-Z', value: { column: 'name', order: 'asc' } },
          { label: 'Name Z-A', value: { column: 'name', order: 'desc' } }
        ]}
      />
    </Dashboard>
  );
};

export default CompanyList;
