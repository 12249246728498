import { useContext, useEffect, useState } from 'react';
import { Row, Col, Dropdown, Button, Typography, notification } from 'antd';
import { Avatar, Dashboard, Timestamp, Header, Tabs, Desc, Banko } from '../../components';
import { FiChevronLeft, FiChevronDown } from 'react-icons/fi';
import { Context, Utils } from 'shookt';
import page from 'page';
import companyGet from './companyGet';
import CompanyForm from './CompanyForm';
import CompanyAdmin from './CompanyAdmin';
import CompanyUsers from './CompanyUsers';
import CompanyRoles from './CompanyRoles';
import CoverageAreaList from './coverageArea/CoverageAreaList';
import CoverageAreaForm from './coverageArea/CoverageAreaForm';

const CompanyView = props => {
  const [state, dispatch] = useContext(Context);
  const [status, setStatus] = useState(0);
  const [company, setCompany] = useState({});
  const toDrawer = Dashboard.toDrawer(dispatch);
  const isManagerView = state.page === '/company';
  const isAdmin = state.user.type === 'admin';

  const loadData = () => {
    (async () => {
      const { status, data } = await companyGet(isManagerView ? state.user.company.id : props.params.id);
      if (status === 200) {
        setCompany(data);
      }
      setStatus(status);
    })();
  };

  useEffect(() => {
    const qry = JSON.parse(state.query);
    if (qry.success) {
      notification.success({
        message: 'BanKo Account Linked',
        description: 'You have successfully linked your BanKo account.'
      });
    } else if (qry.failed) {
      notification.error({
        message: 'BanKo Account Link Failed',
        description: 'Your BanKo account link failed. Please try again.'
      });
    }
    if (isManagerView && (state.user.type !== 'manager' || !state.user.company.id)) {
      return page.redirect('/');
    }
    if (state.reload) {
      dispatch({ delete: ['reload'] });
    }
    loadData();
  }, [state.reload]);

  // TODO order users by role

  return (
    <Dashboard
      viewspin
      status={status}
      back='/company/all'
    >
      <Header
        noTop
        title={(
          <>
            {
              !isManagerView && (
                <Button
                  shape='circle'
                  onClick={() => window.history.back()}
                  icon={<FiChevronLeft />}
                />
              )
            }
            <Typography.Title className='no-bott-margin' level={4}>
              &nbsp;{company.name}
            </Typography.Title>
          </>
        )}
        extra={(
          <Dropdown.Button
            onClick={toDrawer(<CompanyForm id={company.id} key={company.id} />, 'Update distributor information')}
            trigger={['click']}
            icon={<FiChevronDown />}
            menu={{
              items: [{
                key: '0',
                label: 'Add company admin',
                onClick: toDrawer(
                  <CompanyAdmin
                    id={company.id}
                    key={company.id}
                    companyId={company.id}
                    companyName={company.name}
                  />,
                  'New company admin'
                )
              }]
            }}
          >
            Edit distributor
          </Dropdown.Button>
        )}
      />
      <Row gutter={[10, 10]}>
        <Col xs={24} sm={8} md={6} lg={5}>
          <Avatar size={128} user={company} />
        </Col>
        <Col xs={24} sm={16} md={18} lg={19}>
          <Desc>
            <Desc.Item span={3}>
              {company.description}
            </Desc.Item>
            <Desc.Item label='Name'>
              {company.name}
            </Desc.Item>
            <Desc.Item label='Last update'>
              <Timestamp stamp={company.updated_at} />
            </Desc.Item>
            <Desc.Item label='Last update by'>
              {company.updated_by && company.updated_by.id && (Utils.formatName(company.updated_by))}
            </Desc.Item>
            <Desc.Item label='ID'>
              {company.id}
            </Desc.Item>
            <Desc.Item label='Created at'>
              <Timestamp stamp={company.created_at} />
            </Desc.Item>
            <Desc.Item label='Created by'>
              {company.created_by && company.created_by.id && (Utils.formatName(company.created_by))}
            </Desc.Item>
            <Desc.Item label='Contact Mobile'>
              {company.mobile || '-'}
            </Desc.Item>
            <Desc.Item label='Contact Email'>
              {company.email || '-'}
            </Desc.Item>
            <Desc.Item label='Tier'>
              {company.tier || '-'}
            </Desc.Item>
          </Desc>
        </Col>
      </Row>

      <Banko company={company} />

      <Tabs
        defaultKey='user'
        storeKey='company'
        tabBarExtraContent={isAdmin &&
          <>
            <Button
              onClick={toDrawer(<CoverageAreaForm companyId={company.id} />, 'Add new coverage area')}
            >
              Add coverage area
            </Button>
          </>}
        items={[
          {
            key: 'users',
            label: 'Users',
            children: <CompanyUsers dataSource={company.users} company={company} />
          },
          {
            key: 'roles',
            label: 'Roles',
            children: <CompanyRoles dataSource={company.roles} />
          },
          {
            key: 'coverage',
            label: 'Coverage Area',
            children: <CoverageAreaList dataSource={company.coverage} companyId={company.id} />
          }
        ]}
      />
    </Dashboard>
  );
};

export default CompanyView;
