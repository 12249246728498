import { useContext, useEffect, useState } from 'react';
import { Row, Col, Input, Select, Pagination } from 'antd';
import { Context } from 'shookt';
import { CategorySelect,ProvinceSelect,DistributorSelect } from '../../components';
import pagejs from 'page';
import qs from 'qs';
import s from './market.module.less';
import { sortOptions } from './MarketList';
import MarketModal from './MarketModal';
import distributorGet from '../company/distributor/distributorGet';


const MarketHead = props => {
  const [state] = useContext(Context);
  const [page, setPage] = useState(1);
  const [sort, setSort] = useState([]);
  const [find, setFind] = useState('');
  const [findout, setFindout] = useState(null);
  const [category, setCategory] = useState(null);
  const [province, setProvince] = useState(null);
  const [distributor, setDistributor] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);
  const [distributorOptions, setDistributorOptions] = useState([]);
  const params = JSON.parse(state.params);

  
  const upChange = (vals = {}) => {
    if ('category' in vals) {
      setCategory(vals.category || null);
    }
    if ('province' in vals) {
      setProvince(vals.province || null);
      setDistributor([]);
    
    }
    if ('distributor' in vals) {
      setDistributor(vals.distributor || null);
    }
    if ('sort' in vals) {
      setSort(vals.sort);
    }
    if ('page' in vals) {
      setPage(vals.page);
    }
    if ('find' in vals) {
      setFind(vals.find);
    }
    if (findout) {
      clearTimeout(findout);
    }
    setFindout(setTimeout(() => {
      const payload = {
        ...{ find, sort, page, category,province,distributor },
        ...vals
      };
      if (!payload.category) {
        delete payload.category;
      }
      if (payload.distributor === null || (Array.isArray(payload.distributor) && payload.distributor.length === 0)) {
        delete payload.distributor;
      }
      if (!payload.distributor) {
        delete payload.distributor;
      }
      if (!payload.province) {
        delete payload.province;
      }
      if (!payload.find) {
        delete payload.find;
      }
      if (!payload.sort) {
        delete payload.sort;
      }
      if (payload.page === 1) {
        delete payload.page;
      }
      let path = '/';
      if (Object.keys(payload).length) {
        path = `/?${qs.stringify(payload)}`;
      }
     
      pagejs(path);
    }, 250));
  };
  const handleDistributorChange = (val) => {
    setDistributor([val]);
   
  };

  useEffect(() => {
   
  }, [province, distributorOptions]);

  const fetchDistributors = async (val,id,label) => {
    try {


      const url = `/distributor?provincest=${val}`;
     
      const response = await distributorGet(label);
     
      if (response && response.data && Array.isArray(response.data)) {
        
        const distributorOptions = response.data.map((distributor) => ({
          value: distributor.id,
          label: distributor.name,
        }));
        setDistributorOptions(distributorOptions);
      }
    } catch (error) {
      
    }
  };


  const handleProvinceChange = (val,id,label) => {
       
    setDistributor([]);

    setProvince(val);
 
    setDistributorOptions([]);
if (label) {

fetchDistributors(val,id,label);
} else {

setDistributorOptions([]);
}
};
  useEffect(() => {
    const queries = JSON.parse(state.query);
    setCategory(queries.category);
    setProvince(queries.province);
    setDistributor(queries.distributor);
    setSort(queries.sort);
    setPage(queries.page);
    setFind(queries.find);
      // Check if the modal has been shown in session storage
  const hasModalBeenShownSession = sessionStorage.getItem('modalShownSession');

  // If the modal has not been shown in local storage and modalVisible is false, set modalVisible to true and store the local storage flag
  
  // If the modal has not been shown in session storage and modalVisible is false, set modalVisible to true and store the session storage flag
  if (!hasModalBeenShownSession && !modalVisible) {
    setModalVisible(true);
    sessionStorage.setItem('modalShownSession', 'true');
  }
  }, [state.query]);

  return (
    <div className={s.box}>
      <Row gutter={[10, 10]} justify='space-between' align='bottom'>
        <Col xs={24} lg={24} xl={14}>
          <Row gutter={[13, 13]}>
          <Col xs={24} md={6} xl={7} xxl={6}>
              <ProvinceSelect
                onChange={(val, id) => {
                  handleProvinceChange(val.label,id);
                  upChange({ province: val, distributor: [], page: 1 });
                }}value={province}
                noOnloadChange
                allowClear
              />
            </Col>
            <Col xs={24} md={6} xl={7} xxl={6}>
            <DistributorSelect
                onChange={(val) => {
                  handleDistributorChange(val);
                  upChange({ distributor: val, page: 1 });
                }}value={distributor}
                options={distributorOptions} 
                noOnloadChange
                allowClear
               province={province} // Pass the selected 'province' to DistributorSelect
              />
            </Col>
            <Col xs={24} md={6} xl={7} xxl={6}>
              <CategorySelect
                onChange={val => upChange({ category: val, page: 1 })}
                value={category}
                noOnloadChange
                allowClear
              />
            </Col>
            <Col xs={24} md={14} xl={13} xxl={14}>
              <Input.Search
                placeholder='Search'
                onSearch={val => upChange({ find: val, page: 1 })}
                onChange={e => upChange({ find: e.currentTarget.value, page: 1 })}
                maxLength={100}
                value={find}
                allowClear
              />
            </Col>
            <Col xs={24} md={4}>
              <Select
                placeholder='Sort by'
                className={s.sort}
                onChange={val => upChange({ sort: val, page: 1 })}
                options={Object.keys(sortOptions).map(k => ({ label: sortOptions[k].label, value: k }))}
                value={sort}
              />
            </Col>
          </Row>
        </Col>
        <Col xs={24} lg={24} xl={10}>
          {
            !params.id && (
              <Pagination
                showTitle={false}
                showSizeChanger={false}
                className={s.pagination}
                onChange={page => upChange({ page })}
                pageSize={state.pageSize || 1}
                current={state.marketPage || 1}
                total={state.marketTotal || 0}
                size='small'
              />
            )
          }
        </Col>
      </Row>
      <MarketModal
  visible={modalVisible}
  onClose={() => setModalVisible(false)}
  // province={province} // Pass province directly
  // distributor={distributor} // Pass distributor directly
  // provinceOptions={provinceOptions}
  // distributorOptions={distributorOptions}
/>
    </div>
  );
};

export default MarketHead;
