import { useContext, useEffect, useState } from 'react';
import { Row, Col, Button } from 'antd';
import { CompanySelect, Dashboard, DataList, Header, Ellipsis } from '../../components';
import { Context, Utils } from 'shookt';
import s from './role.module.less';
import roleGet from './roleGet';
import RoleForm from './RoleForm';

const RoleList = props => {
  const [state, dispatch] = useContext(Context);
  const [values, setValues] = useState({});
  const [params, setParams] = useState({});
  const [company, setCompany] = useState();
  const toDrawer = Dashboard.toDrawer(dispatch);

  useEffect(() => {
    if (params && params.page) {
      dispatch({ upsert: { loading: true } });
      setTimeout(async () => {
        const { data } = await roleGet('all', {
          company: company || 0,
          find: params.find,
          sort: params.sort,
          page: params.page || 1,
          pageSize: state.pageSize
        });
        dispatch({ upsert: { roles: data.data || [] } });
        setValues(data);
      }, 200);
    }
  }, [params, company]);

  return (
    <Dashboard>
      <Header
        noTop
        level={3}
        title='Roles'
        extra={(
          <Button
            type='primary'
            onClick={toDrawer(<RoleForm />, 'Create new role')}
          >
            Add new role
          </Button>
        )}
      />

      <DataList
        values={values}
        storeKey='role'
        onChange={setParams}
        countLabel='roles'
        dataSource={state.roles}
        rowUrl={item => `/role/${item.id}`}
        extra={CompanySelect.get(state, { allowClear: true, storeKey: 'role', onChange: setCompany })}
        bodyHandler={item => (
          <Row gutter={[10, 10]}>
            <Col xs={24} lg={5} xl={7}>
              <div
                style={{
                  borderLeft: `2px solid #${item.color || 'cccccc'}`,
                  paddingLeft: 7
                }}
              >
                <Ellipsis className={s.name}>
                  {item.name}
                </Ellipsis>
              </div>
            </Col>
            <Col xs={24} lg={19} xl={17}>
              <Ellipsis type='secondary'>
                {Utils.toCapitalize(item.description)}
              </Ellipsis>
            </Col>
          </Row>
        )}
        sortOptions={[
          { label: 'Newest', value: { column: 'created_at', order: 'desc' } },
          { label: 'Oldest', value: { column: 'created_at', order: 'asc' } },
          { label: 'Updated', value: { column: 'updated_at', order: 'desc' } },
          { label: 'Name A-Z', value: { column: 'name', order: 'asc' } },
          { label: 'Name Z-A', value: { column: 'name', order: 'desc' } }
        ]}
      />
    </Dashboard>
  );
};

export default RoleList;
