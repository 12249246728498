import { useContext, useEffect, useState } from 'react';
import { Row, Col, List, Space, Button, Collapse, Dropdown, Typography } from 'antd';
import { Header, Avatar, Created, Dashboard, Variations, Spacer, Ellipsis } from '../../../components';
import { AiOutlineMore } from 'react-icons/ai';
import { Context, Utils } from 'shookt';
import s from '../pricing.module.less';
import PriceForm from './PriceForm';
import productGet from '../../product/productGet';
import priceDelete from './priceDelete';

const PriceList = ({ product, pricing }) => {
  const [state, dispatch] = useContext(Context);
  const [produck, setProduck] = useState({});
  const toDrawer = Dashboard.toDrawer(dispatch);

  useEffect(() => {
    (async () => {
      if (state[`reload${product.id}`]) {
        const { data } = await productGet(product.id, { pricing: pricing.id });
        setProduck(data);
        dispatch({ delete: [`reload${product.id}`] });
      }
    })();
  }, [state[`reload${product.id}`]]);

  useEffect(() => {
    setProduck(product);
  }, [product.id]);

  let range = 'No pricing';
  if (Array.isArray(produck.pricing)) {
    let lowest;
    let highest;
    produck.pricing.forEach(p => {
      const unitPrice = parseFloat(p.unit_price);
      if (!lowest || unitPrice < lowest) {
        lowest = unitPrice;
      }
      if (!highest || unitPrice > highest) {
        highest = unitPrice;
      }
    });
    range = '';
    if (lowest) {
      range = `₱${Utils.toMonetary(lowest)}`;
    }
    if (highest && highest !== lowest) {
      if (lowest) {
        range = `${range} - `;
      }
      range = `${range}₱${Utils.toMonetary(highest)}`;
    }
  }

  return (
    <Collapse key={produck.id} bordered={false} className='collapse-list dashed-list-border' expandIconPosition='end' ghost>
      <Collapse.Panel
        header={(
          <Row gutter={[10, 10]} className={s.panel} wrap={false}>
            <Col flex='none'>
              <Avatar shape='square' size={46} user={produck} thumb />
            </Col>
            <Col flex='auto'>
              <Row gutter={[13, 10]} className={s.top} wrap={false}>
                <Col flex='none'>
                  <Ellipsis className={s.product}>
                    {produck.code}: {produck.name}
                  </Ellipsis>
                </Col>
                <Col flex='auto'>
                  <Ellipsis className={s.desc} type='secondary'>
                    {Utils.toCapitalize(produck.description)}
                  </Ellipsis>
                </Col>
              </Row>
              <Row gutter={[13, 10]} className={s.bott} wrap={false}>
                <Col flex='none'>
                  <Ellipsis className={s.price} type={produck.pricing ? '' : 'danger'}>
                    {range}
                  </Ellipsis>
                </Col>
                <Col flex='auto'>
                  <Ellipsis className={s.category} type='secondary'>
                    {produck.category && produck.category.name}
                  </Ellipsis>
                </Col>
              </Row>
            </Col>
          </Row>
        )}
      >
        <div className={s.prices}>
          <Header
            bottom={1}
            top={5}
            title={<Typography.Text className={s.label}>Variation prices</Typography.Text>}
            extra={(
              <Button onClick={toDrawer(<PriceForm pricing={pricing} productId={product.id} key={product.id} />, 'Set new price')}>
                Set new price
              </Button>
            )}
          />
          <List
            dataSource={produck.pricing || []}
            renderItem={item => (
              <List.Item
                extra={(
                  <Dropdown
                    className='cursor-pointer'
                    trigger={['click']}
                    menu={{
                      items: [{
                        key: 'delete',
                        label: 'Delete',
                        danger: true,
                        onClick: async () => {
                          const { status } = await priceDelete(item.id);
                          if (status === 200) {
                            dispatch({ upsert: { [`reload${product.id}`]: true } });
                          }
                        }
                      }]
                    }}
                  >
                    <AiOutlineMore />
                  </Dropdown>
                )}
              >
                <List.Item.Meta
                  className='cursor-pointer'
                  title={(
                    <Spacer center block>
                      <span>₱{Utils.toMonetary(item.unit_price)}</span>
                      <Created item={item} />
                    </Spacer>
                  )}
                  description={(
                    <Spacer split block>
                      <Space align='start'>
                        {item.code ? item.code : <>ID: {item.id}</>}
                      </Space>
                      <Variations item={item} noBott view />
                    </Spacer>
                  )}
                  onClick={toDrawer(<PriceForm pricing={pricing} productId={product.id} item={item} key={item.id} />, 'Edit price')}
                />
              </List.Item>
            )}
          />
        </div>
      </Collapse.Panel>
    </Collapse>
  );
};

export default PriceList;
