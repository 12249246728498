import axios from 'axios';


  const provinceGet = async () => {
    try {
      const response = await axios({
        method: 'get',
        url: '/province'
      });
      return response;
    } catch ({ response }) {
      return response;
      // internal
    }
  };
export default provinceGet;
