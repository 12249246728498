import page from 'page';
import DeliveryList from './DeliveryList';
import DeliveryView from './DeliveryView';

export const deliveryRedirect = {
  path: '/delivery',
  priv: 'delivery',
  handler: () => page.redirect('/delivery/all')
};

export const deliveryListRoute = {
  path: '/delivery/all',
  priv: 'delivery',
  handler: props => <DeliveryList {...props} />
};

export const deliveryViewRoute = {
  path: '/delivery/:id',
  priv: 'delivery',
  handler: props => <DeliveryView {...props} />
};
