import { useState } from 'react';
import dayjs from 'dayjs';
import weekday from 'dayjs/plugin/weekday';
import localeData from 'dayjs/plugin/localeData';
import relativeTime from 'dayjs/plugin/relativeTime';
import s from './timestamp.module.less';
dayjs.extend(weekday);
dayjs.extend(localeData);
dayjs.extend(relativeTime);

const Timestamp = props => {
  const [isRelative, setRelative] = useState(true);

  if (!props.stamp) {
    return props.ifEmpty || '';
  }

  return (
    <div onClick={e => { e.preventDefault(); e.stopPropagation(); setRelative(!isRelative); }} className={s.timestamp}>
      {props.prefix} {isRelative && (dayjs(props.stamp).fromNow())} {!isRelative && (dayjs(props.stamp).format(props.format || 'YYYY/MM/DD HH:mm'))} {props.suffix}
    </div>
  );
};

Timestamp.dayjs = dayjs;

export default Timestamp;
