import { useContext, useEffect, useState } from 'react';
import { Form, Input, Button, AutoComplete } from 'antd';
import { FaImage } from 'react-icons/fa';
import { Context, ImgUpload, Erratum, Utils } from 'shookt';
import { CompanySelect, CategorySelect } from '../../components';
import page from 'page';
import productGet from './productGet';
import productPost from './productPost';
import brands from './options/brands';
import manufacturers from './options/manufacturers';
import principals from './options/principals';

const ProductForm = props => {
  const [state, dispatch] = useContext(Context);
  const [avatar, setAvatar] = useState('');
  const [form] = Form.useForm();

  const onFinish = async values => {
    const payload = {
      name: values.newprod_name.trim(),
      code: values.newprod_code.trim(),
      description: values.newprod_description.trim(),
      category_id: values.newprod_category,
      brand: values.newprod_brand,
      principal: values.newprod_principal,
      manufacturer: values.newprod_manufacturer
    };
    if (values.newprod_company) {
      payload.company_id = values.newprod_company;
    }
    if (props.id) {
      payload.id = props.id;
    }
    if (avatar && avatar.startsWith('data:')) {
      payload.avatar = avatar;
    }
    const { status, data } = await productPost(payload);
    if (status === 200) {
      if (props.id) {
        dispatch({ upsert: { reload: true } });
      } else {
        page(`/product/${data.id}`);
      }
      dispatch({ delete: ['drawer'] });
    }
  };

  useEffect(() => {
    (async () => {
      if (props.id) {
        const { status, data } = await productGet(props.id);
        if (status === 200) {
          setAvatar(data.avatar);
          form.setFieldsValue({
            newprod_company: data.company_id,
            newprod_category: data.category_id,
            newprod_name: data.name,
            newprod_code: data.code,
            newprod_description: data.description,
            newprod_brand: data.brand,
            newprod_principal: data.principal,
            newprod_manufacturer: data.manufacturer
          });
        }
      }
    })();
  }, []);

  return (
    <Form
      form={form}
      layout='vertical'
      onFinish={onFinish}
      onValuesChange={Utils.clearError(dispatch)}
    >
      <Form.Item className='text-center'>
        <ImgUpload
          message={`${props.id ? 'Update' : 'Attach'} product photo`}
          imgStore={avatar}
          imgSet={setAvatar}
          icon={<FaImage />}
          size={168}
        />
      </Form.Item>

      <CompanySelect
        name='newprod_company'
        rules={[{
          required: true,
          message: 'Please select a distributor'
        }]}
      />

      <CategorySelect
        name='newprod_category'
        rules={[{
          required: true,
          message: 'Please select a category'
        }]}
      />

      <Form.Item
        label='Product name'
        name='newprod_name'
        rules={[{
          required: true,
          whitespace: true,
          pattern: '^[a-zA-Z0-9ñÑ. ]*$',
          message: 'Please enter a product name'
        }]}
      >
        <Input
          minLength={1}
          maxLength={100}
          placeholder='Product name'
        />
      </Form.Item>

      <Form.Item
        label='Description'
        name='newprod_description'
        rules={[{
          max: 300,
          required: true,
          whitespace: true,
          message: 'Please enter a description'
        }]}
      >
        <Input.TextArea placeholder='Description' maxLength={300} autoSize={{ minRows: 2 }} showCount />
      </Form.Item>

      <Form.Item
        label='Product SKU'
        name='newprod_code'
        rules={[{
          required: true,
          whitespace: true,
          pattern: '^[a-zA-Z0-9ñÑ. ]*$',
          message: 'Please enter a product SKU'
        }]}
      >
        <Input
          minLength={1}
          maxLength={100}
          placeholder='Product SKU'
        />
      </Form.Item>

      <Form.Item
        label='Brand'
        name='newprod_brand'
      >
        <AutoComplete
          options={brands}
          placeholder='Brand'
          filterOption={(inputValue, option) =>
            option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1}
        />
      </Form.Item>

      <Form.Item
        label='Principal'
        name='newprod_principal'
      >
        <AutoComplete
          options={principals}
          placeholder='Principal'
          filterOption={(inputValue, option) =>
            option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1}
        />
      </Form.Item>

      <Form.Item
        label='Manufacturer'
        name='newprod_manufacturer'
      >
        <AutoComplete
          options={manufacturers}
          placeholder='Manufacturer'
          filterOption={(inputValue, option) =>
            option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1}
        />
      </Form.Item>

      <Erratum />

      <Form.Item>
        <Button
          block
          type='primary'
          htmlType='submit'
          disabled={state.loading}
          loading={state.loading}
        >
          {props.id ? 'Save changes' : 'Add new product'}
        </Button>
      </Form.Item>
    </Form>
  );
};

export default ProductForm;
