import { Typography } from 'antd';
import s from './ellipsis.module.less';

const Ellipsis = props => {
  const tooltip = props.noHover ? {} : { tooltip: props.children, trigger: ['click'] };
  return (
    <Typography.Paragraph
      code={props.code}
      type={props.type}
      style={{ marginBottom: props.bottom, marginTop: props.top, ...props.style }}
      keyboard={props.keyboard}
      className={`${s.ellipsis} ${props.className}`}
      ellipsis={{ ...tooltip, ...props.ellipsis || {} }}
    >
      {props.children}
    </Typography.Paragraph>
  );
};

export default Ellipsis;
