import { useContext, useEffect, useState } from 'react';
import { Row, Col, Form, Input, Button, DatePicker, Typography, Divider } from 'antd';
import { Context, Erratum, Utils } from 'shookt';
import { Dashboard, Header, Timestamp, CompanySelect } from '../../../components';
import { FiChevronLeft } from 'react-icons/fi';
import page from 'page';
import batchGet from './batchGet';
import batchPost from './batchPost';
import BatchItemForm from './BatchItemForm';
import BatchItemList from './BatchItemList';

const BatchForm = props => {
  const [state, dispatch] = useContext(Context);
  const [company, setCompany] = useState(null);
  const [form] = Form.useForm();
  const toDrawer = Dashboard.toDrawer(dispatch);

  const onFinish = async values => {
    if (!state.inventoryItems || !state.inventoryItems.length) {
      return dispatch({ upsert: { error: 'No items for this batch' } });
    }
    const payload = {
      batch_no: values.newinv_batchno,
      items: state.inventoryItems.map(i => {
        const { avatar, avatar_thumb: a, ...product } = i.product;
        return { ...i, product };
      })
    };
    if (values.newinv_arrival) {
      payload.arrival_date = values.newinv_arrival.format('YYYY-MM-DD');
    }
    if (values.newinv_company) {
      payload.company_id = values.newinv_company;
    }
    if (props.query.id) {
      payload.id = props.query.id;
    }
    const { status, data } = await batchPost(payload);
    if (status === 200) {
      page(`/inventory/batch/${data.id}`);
      dispatch({ delete: ['inventoryItems'] });
    }
  };

  useEffect(() => {
    (async () => {
      if (props.query.id) {
        const { status, data } = await batchGet(props.query.id);
        if (status === 200) {
          setCompany(data.company_id);
          form.setFieldsValue({
            newinv_company: data.company_id,
            newinv_batchno: data.batch_no,
            newinv_arrival: data.arrival_date ? Timestamp.dayjs(data.arrival_date) : null
          });
        }
      }
    })();
  }, []);

  return (
    <Dashboard back='/inventory/batch'>
      <Header
        noTop
        title={(
          <>
            <Button
              shape='circle'
              onClick={() => page.redirect('/inventory/batch')}
              icon={<FiChevronLeft />}
            />
            <Typography.Title className='no-bott-margin' level={4}>
              &nbsp; {props.query.id ? 'Edit' : 'New'} inventory
            </Typography.Title>
          </>
        )}
      />
      <Form
        form={form}
        layout='vertical'
        onFinish={onFinish}
        onValuesChange={Utils.clearError(dispatch)}
      >
        <Row gutter={[10, 10]}>
          <Col>
            <Form.Item
              label='Inventory Batch No./ID'
              name='newinv_batchno'
              className='no-bott-margin'
              rules={[{
                required: true,
                pattern: '^[a-zA-Z0-9ñÑ. ]*$',
                message: 'Please enter Batch No. or ID'
              }]}
            >
              <Input
                minLength={1}
                maxLength={100}
                placeholder='Batch No/ID'
              />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item
              label='Arrival date'
              name='newinv_arrival'
              className='no-bott-margin'
              rules={[{
                required: true,
                message: 'Please select arrival date'
              }]}
            >
              <DatePicker placeholder='Arrival date' format='YYYY/MM/DD' />
            </Form.Item>
          </Col>
          <Col>
            <CompanySelect
              name='newinv_company'
              onChange={setCompany}
              disabled={props.query.id || company}
              style={{ maxWidth: 140 }}
              rules={[{
                required: true,
                message: 'Please select a distributor'
              }]}
            />
          </Col>
        </Row>

        <Header
          title='Products'
          extra={(
            <Button
              disabled={state.user.type === 'admin' && !company}
              onClick={toDrawer(<BatchItemForm companyId={company} />, 'Add inventory product')}
            >
              {state.user.type === 'admin' && !company ? 'Select distributor first' : 'Add product'}
            </Button>
          )}
        />

        <BatchItemList dataSource={state.inventoryItems} company={company} />
        <Divider />

        <Row gutter={[10, 10]}>
          <Col xs={24} md={15} lg={16}>
            <Erratum />
          </Col>
          <Col xs={24} md={4} lg={3}>
            <Button block onClick={() => page('/inventory/batch')}>
              Cancel
            </Button>
          </Col>
          <Col xs={24} md={5}>
            <Button
              block
              type='primary'
              htmlType='submit'
              disabled={state.loading}
              loading={state.loading}
            >
              {props.query.id ? 'Save changes' : 'Add new inventory'}
            </Button>
          </Col>
        </Row>

      </Form>
    </Dashboard>
  );
};

export default BatchForm;
