import page from 'page';
import MarketList from './MarketList';
import MarketView from './MarketView';
import MyOrderView from './MyOrderView';
import MyOrders from './MyOrders';
import MyCart from './MyCart';
import MyFavorites from './MyFavorites';
import Checkout from './Checkout';

export const marketRedirect = {
  path: '/market',
  handler: () => page.redirect('/')
};

export const marketListRoute = {
  path: '/',
  code: 'market',
  handler: props => <MarketList {...props} />
};

export const marketViewRoute = {
  path: '/market/:id',
  handler: props => <MarketView {...props} />
};

export const myOrderViewRoute = {
  path: '/myorders/:id',
  auth: true,
  handler: props => <MyOrderView {...props} />
};

export const myOrdersRoute = {
  path: '/myorders',
  auth: true,
  handler: props => <MyOrders {...props} />
};

export const myCartRoute = {
  path: '/mycart',
  auth: true,
  handler: props => <MyCart {...props} />
};

export const myFavoritesRoute = {
  path: '/myfavorites',
  auth: true,
  handler: props => <MyFavorites {...props} />
};

export const checkoutRoute = {
  path: '/checkout',
  auth: true,
  handler: props => <Checkout {...props} />
};
