import page from 'page';
import { Utils, USERPATH, LOGINPATH } from 'shookt';
import { Result } from 'antd';
import { Dashboard } from './components';
import profileGet from './pages/profile/profileGet';
const accessdenied = (
  <Dashboard>
    <Result
      status='error'
      title='Access Denied'
      subTitle='You do not have permission to access this page.'
    />
  </Dashboard>
);

export const authenticate = async (dispatch) => {
  const { status, data } = await profileGet();
  if (status === 200) {
    const fullName = Utils.formatName(data);
    sessionStorage.setItem('sid', data.password ? 'done' : 'none');
    dispatch({ upsert: { user: { ...data, fullName } } });
    return data;
  } else {
    sessionStorage.clear();
    dispatch({ delete: ['user'] });
  }
};

export const onRoute = ({ state, dispatch, route }) => {
  const isLoggedIn = sessionStorage.getItem('sid');
  if (!isLoggedIn && (route.auth === true || route.priv)) {
    page.redirect(LOGINPATH);
    return true;
  }
  if (isLoggedIn && route.auth === false) {
    page.redirect(USERPATH);
    return true;
  }
  if (isLoggedIn === 'done' && route.path === state.SETUPPATH) {
    page.redirect(USERPATH);
    return true;
  }
  if (isLoggedIn === 'none' && route.path !== state.SETUPPATH) {
    page.redirect(state.SETUPPATH);
    return true;
  }
  if (isLoggedIn && state.user && state.user.privileges && state.user.type !== 'admin' &&
    route.priv && !(route.priv in state.user.privileges)) {
    dispatch({ upsert: { accessdenied } });
    return true;
  }
  if (window.innerWidth < 1860) {
    dispatch({ delete: ['drawer'] });
  }
};
