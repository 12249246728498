import { useState, useContext, useEffect } from 'react';
import { Button, Badge } from 'antd';
import { Context } from 'shookt';
import { FaSquareFull } from 'react-icons/fa';
import { CompanySelect, Avatar, Dashboard, DataList, Header, Ellipsis } from '../../components';
import s from './product.module.less';
import productGet from './productGet';
import ProductForm from './ProductForm';

const ProductList = props => {
  const [state, dispatch] = useContext(Context);
  const [values, setValues] = useState({});
  const [params, setParams] = useState({});
  const [company, setCompany] = useState();
  const toDrawer = Dashboard.toDrawer(dispatch);

  useEffect(() => {
    if (params && params.page) {
      dispatch({ upsert: { loading: true } });
      setTimeout(async () => {
        const { data } = await productGet('all', {
          company: company || 0,
          find: params.find,
          sort: params.sort,
          page: params.page || 1,
          pageSize: state.pageSize
        });
        dispatch({ upsert: { products: data.data || [] } });
        setValues(data);
      }, 200);
    }
  }, [params, company]);

  return (
    <Dashboard>
      <Header
        noTop
        level={3}
        title='Products'
        extra={(
          <Button
            type='primary'
            onClick={toDrawer(<ProductForm />, 'Create new product')}
          >
            Add new product
          </Button>
        )}
      />

      <DataList
        values={values}
        storeKey='product'
        onChange={setParams}
        countLabel='products'
        dataSource={state.products}
        rowUrl={item => `/product/${item.id}`}
        extra={CompanySelect.get(state, { allowClear: true, storeKey: 'product', onChange: setCompany })}
        avatarHandler={item => {
          return (
            <Badge
              offset={[-3, 43]}
              count={<FaSquareFull style={{ fontSize: 8, color: item.is_enabled ? '#52c41a' : '#aaa' }} />}
              style={{ border: '1px solid #fff' }}
            >
              <Avatar size={46} user={item} square thumb />
            </Badge>
          );
        }}
        titleHandler={item => (<Ellipsis>{item.code}: {item.name}</Ellipsis>)}
        bodyHandler={item => (
          <Ellipsis type='secondary' className='lisht-content'>
            {item.category && item.category.name} <span className={s.divider}>|</span> {item.description}
          </Ellipsis>
        )}
        sortOptions={[
          { label: 'Newest', value: { column: 'created_at', order: 'desc' } },
          { label: 'Oldest', value: { column: 'created_at', order: 'asc' } },
          { label: 'Updated', value: { column: 'updated_at', order: 'desc' } },
          { label: 'Name A-Z', value: { column: 'name', order: 'asc' } },
          { label: 'Name Z-A', value: { column: 'name', order: 'desc' } }
        ]}
      />
    </Dashboard>
  );
};

export default ProductList;
