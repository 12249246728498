import page from 'page';
import { useState, useContext } from 'react';
import { Context, Captcha, Erratum } from 'shookt';
import { Typography, Result } from 'antd';
import { authenticate } from '../../session';
import { Panel } from '../../components';
import verifyPut from './verifyPut';

const Verify = props => {
  const [state, dispatch] = useContext(Context);
  const [done, setDone] = useState('');
  const [message, setMessage] = useState(Erratum.internal);

  const onChange = async () => {
    const { status, data } = await verifyPut({ code: props.params.code });
    if (status === 200) {
      await authenticate(dispatch);
      if (data.message === 'new') {
        return page(state.SETUPPATH);
      }
      if (data.message === 'old') {
        setMessage('Your email was successfully verified.');
        return setDone('success');
      }
    }
    setMessage(data.message);
    setDone('failed');
  };

  let body = (
    <>
      <Typography.Paragraph className='text-center'>
        Please verify you are not a robot.
      </Typography.Paragraph>
      <Captcha onChange={onChange} />
      <Erratum />
    </>
  );

  if (done) {
    body = (
      <Result
        title={`Verification ${done}`}
        status={done === 'failed' ? 'error' : 'success'}
        subTitle={message}
      />
    );
  }

  return (
    <Panel style={{ maxWidth: 345 }}>
      {body}
    </Panel>
  );
};

export default Verify;
