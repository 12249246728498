import { useEffect, useState } from 'react';
import { Tabs as Tahbs } from 'antd';

const Tabs = props => {
  const { children, defaultKey, storeKey, ...tabProps } = props;
  const [tabkey, setTabkey] = useState(defaultKey);

  useEffect(() => {
    const key = sessionStorage.getItem(`${storeKey}_tabkey`);
    if (key) {
      setTabkey(key);
    }
  }, []);

  return (
    <Tahbs
      activeKey={tabkey}
      onChange={key => {
        setTabkey(key);
        sessionStorage.setItem(`${storeKey}_tabkey`, key);
      }}
      {...tabProps}
    >
      {children}
    </Tahbs>
  );
};

export default Tabs;
