import { useContext, useEffect, useState } from 'react';
import { MdKeyboardArrowUp, MdKeyboardArrowLeft, MdKeyboardArrowRight, MdKeyboardArrowDown } from 'react-icons/md';
import { Menu } from 'antd';
import { Context } from 'shookt';
import categoryGet from '../../pages/category/categoryGet';

const CategoryMenu = props => {
  const [state, dispatch] = useContext(Context);
  const [selectedKeys, setSelectedKeys] = useState([]);

  useEffect(() => {
    (async () => {
      let categories = state.categories;
      let categoriesObj = state.categoriesObj;
      if (!categories) {
        const { status, data, objData } = await categoryGet();
        if (status === 200) {
          dispatch({
            upsert: {
              categories: data,
              categoriesObj: objData
            }
          });
          categories = data;
          categoriesObj = objData;
        } else {
          categories = [];
          categoriesObj = {};
        }
      }
      const mainMenu = [];
      const childMenu = [];
      const queries = JSON.parse(state.query);
      const cat = queries.category ? categoriesObj[queries.category] || {} : {};
      if (cat.id && (cat.children.length || cat.ancestors.length)) {
        setSelectedKeys(`/?category=${cat.id}`);
        mainMenu.push({
          icon: <MdKeyboardArrowUp />,
          label: <a href='/'>Back to top</a>
        });
        let node = cat;
        if (!cat.children.length && cat.parent_id) {
          node = categoriesObj[cat.parent_id];
        }
        node.ancestors.forEach(a => {
          mainMenu.push({
            key: `/?category=${a.id}`,
            icon: <MdKeyboardArrowLeft />,
            label: <a href={`/?category=${a.id}`}>{a.name}</a>
          });
        });
        mainMenu.push({
          key: `/?category=${node.id}`,
          icon: node.children.length ? <MdKeyboardArrowDown /> : <MdKeyboardArrowRight />,
          label: <a href={`/?category=${node.id}`}>{node.name}</a>
        });
        node.children.forEach(c => {
          childMenu.push({
            key: `/?category=${c.id}`,
            icon: <MdKeyboardArrowRight />,
            label: <a href={`/?category=${c.id}`}>{c.name}</a>
          });
        });
      } else {
        if (cat.id) {
          setSelectedKeys(`/?category=${cat.id}`);
        } else {
          setSelectedKeys([]);
        }
        categories.forEach(c => {
          mainMenu.push({
            icon: c.children.length ? <MdKeyboardArrowDown /> : <MdKeyboardArrowRight />,
            label: <a href={`/?category=${c.id}`}>{c.name}</a>,
            key: c.id
          });
        });
      }
      dispatch({
        upsert: {
          categoryMenuMain: mainMenu,
          categoryMenuChild: childMenu
        }
      });
    })();
  }, [state.query, state.categories]);

  return (
    <>
      <Menu
        mode='inline'
        selectedKeys={selectedKeys}
        inlineIndent={15}
        className='category-menu'
        items={state.categoryMenuMain}
      />
      <Menu
        mode='inline'
        selectedKeys={selectedKeys}
        inlineIndent={28}
        className='category-menu'
        items={state.categoryMenuChild}
      />
    </>
  );
};

export default CategoryMenu;
