import { useContext, useEffect } from 'react';
import { Form, Typography, InputNumber, Button, Select } from 'antd';
import { Context, Erratum, Utils } from 'shookt';

const OrderItemForm = props => {
  const [state, dispatch] = useContext(Context);
  const [form] = Form.useForm();

  const onFinish = async values => {
    // const payload = {
    // };
    // const { status, data } = await orderItemPost(payload);
    // if (status === 200) {
    //   if (props.id) {
    //     dispatch({ upsert: { reload: true } });
    //   } else {
    //     page(`/order/${data.id}`);
    //   }
    //   dispatch({ delete: ['drawer'] });
    // }
  };

  useEffect(() => {
    // (async () => {
    //   if (props.id) {
    //     const { status, data } = await orderItemGet(props.id);
    //     if (status === 200) {
    //     }
    //   }
    // })();
  }, []);

  return (
    <Form
      form={form}
      layout='vertical'
      onFinish={onFinish}
      onValuesChange={Utils.clearError(dispatch)}
    >
      <Typography.Paragraph>
        <Typography.Text type='secondary'>
          Order id
        </Typography.Text>
        <br />
        {props.order.code}
      </Typography.Paragraph>

      <Typography.Paragraph>
        <Typography.Text type='secondary'>
          Order item id
        </Typography.Text>
        <br />
        {props.item.id}
      </Typography.Paragraph>

      <Typography.Paragraph>
        <Typography.Text type='secondary'>
          Product
        </Typography.Text>
        <br />
        {props.item.product.code}: {props.item.product.name}
      </Typography.Paragraph>

      <Form.Item
        label='Stock Id'
        name='newcomp_name'
        rules={[{
          required: true,
          message: 'Please select a stock'
        }]}
      >
        <Select />
      </Form.Item>

      <Form.Item
        label='Quantity'
        name='newledg_quantity'
        rules={[{
          required: true,
          message: 'Please enter a quantity'
        }]}
      >
        <InputNumber
          precision={0}
          minLength={1}
          maxLength={15}
          placeholder='Quantity'
          controls
        />
      </Form.Item>

      <Erratum />

      <Form.Item>
        <Button
          block
          type='primary'
          htmlType='submit'
          disabled={state.loading}
          loading={state.loading}
        >
          Tag stock to order item
        </Button>
      </Form.Item>
    </Form>
  );
};

export default OrderItemForm;
