import { useState, useContext, useEffect } from 'react';
import { Row, Col, Space, Typography, Tag, Select } from 'antd';
import { FaMoneyBillWave, FaTruck, FaShoppingCart } from 'react-icons/fa';
import { Context, Utils } from 'shookt';
import { CompanySelect, Ellipsis, Avatar, Dashboard, DataList, Header, Created } from '../../components';
import orderGet from './orderGet';

const OrderList = props => {
  const [state, dispatch] = useContext(Context);
  const [values, setValues] = useState({});
  const [params, setParams] = useState({});
  const [company, setCompany] = useState();
  const [status, setStatus] = useState(null);

  useEffect(() => {
    if (params && params.page) {
      dispatch({ upsert: { loading: true } });
      setTimeout(async () => {
        const { data } = await orderGet('all', {
          company: company || 0,
          find: params.find,
          sort: params.sort,
          page: params.page || 1,
          pageSize: state.pageSize,
          status
        });
        dispatch({ upsert: { orders: data.data || [] } });
        setValues(data);
      }, 200);
    }
  }, [params, company, status]);

  return (
    <Dashboard>
      <Header
        noTop
        level={3}
        title='Orders'
      />

      <DataList
        values={values}
        storeKey='order'
        onChange={setParams}
        countLabel='orders'
        dataSource={state.orders}
        topPager={CompanySelect.get(state, { allowClear: true, storeKey: 'order', onChange: setCompany })}
        rowUrl={item => `/order/${item.id}`}
        extra={(
          <Select
            allowClear
            placeholder='Select status'
            onChange={setStatus}
            value={status}
            style={{ width: '100%' }}
            options={orderGet.typeStatus.order}
          />
        )}
        bodyHandler={item => (
          <Row gutter={[10, 10]}>
            <Col xs={24} md={8} lg={5} xl={7}>
              <Ellipsis type='secondary'>
                <Tag color={orderGet.toStatusColor(item.status)}>
                  <Space align='center'>
                    <FaShoppingCart />
                    {orderGet.statusToLabel(item.status)}
                  </Space>
                </Tag>&nbsp;
                {item.code}
              </Ellipsis>
            </Col>
            <Col xs={24} md={8} lg={8} xl={6}>
              <Tag color={orderGet.toStatusColor(item.status_payment)}>
                <Space align='center'>
                  <FaMoneyBillWave />
                  {orderGet.statusToLabel(item.status_payment)}
                </Space>
              </Tag>&nbsp;
              <Tag color={orderGet.toStatusColor(item.status_delivery)}>
                <Space align='center'>
                  <FaTruck />
                  {orderGet.statusToLabel(item.status_delivery)}
                </Space>
              </Tag>
            </Col>
            <Col xs={24} md={8} lg={11} xl={11}>
              <Ellipsis type='secondary'>
                {item.address.barangay}, {item.address.towncity}
              </Ellipsis>
            </Col>
          </Row>
        )}
        bottbox1Handler={item => (
          <>
            {item.processed_by && item.processed_by.id && (
              <Space align='center' direction='horizontal' className='cursor-pointer'>
                <Typography.Text type='secondary'>
                  Handled by
                </Typography.Text>
                <Avatar tooltip={`${Utils.formatName(item.processed_by)}`} user={item.processed_by} size={20} thumb />
              </Space>
            )}
            {!item.processed_by && (<Tag>No handler</Tag>)}
          </>
        )}
        bottbox2Handler={item => (
          <Created item={item} noAvatar />
        )}
        sortOptions={[
          { label: 'Newest', value: { column: 'created_at', order: 'desc' } },
          { label: 'Oldest', value: { column: 'created_at', order: 'asc' } }
        ]}
      />
    </Dashboard>
  );
};

export default OrderList;
