import { useContext } from 'react';
import { Result } from 'antd';
import { Context } from 'shookt';
import { Dashboard } from '../../components';

const CompanyHome = props => {
  const [state] = useContext(Context);

  return (
    <Dashboard>
      <Result
        icon={<></>}
        title={state.appName}
        subTitle={`Welcome to ${state.user.type === 'admin' ? state.appName : state.user.company.name} dashboard!`}
      />
    </Dashboard>
  );
};

export default CompanyHome;
