import axios from 'axios';

const variationDelete = async id => {
  try {
    const response = await axios({
      method: 'delete',
      url: `/product/variation/${id}`
    });
    return response;
  } catch ({ response }) {
    return response;
    // internal
  }
};

export default variationDelete;
