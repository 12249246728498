import { useContext } from 'react';
import { List, Empty, Button, Badge, Card, Tag, Typography, ConfigProvider } from 'antd';
import { Avatar, Dashboard, Ellipsis } from '../../components';
import { Context, Utils } from 'shookt';
import { FaStar } from 'react-icons/fa';
import s from './company.module.less';
import page from 'page';
import CompanyAdmin from './CompanyAdmin';

const CompanyUsers = props => {
  const [, dispatch] = useContext(Context);
  const toDrawer = Dashboard.toDrawer(dispatch);
  const company = props.company;

  return (
    <ConfigProvider renderEmpty={() => (
      <Empty
        image={Empty.PRESENTED_IMAGE_SIMPLE}
        imageStyle={{ height: 60 }}
        description={<>No users yet</>}
      >
        <Button
          type='primary'
          onClick={toDrawer(
            <CompanyAdmin
              id={company.id}
              key={company.id}
              companyId={company.id}
              companyName={company.name}
            />,
            'Create company admin'
          )}
        >
          Add company admin
        </Button>
      </Empty>
    )}
    >
      <List
        grid={{ gutter: 16, xs: 1, sm: 2, md: 3, lg: 4 }}
        dataSource={props.dataSource}
        renderItem={item => {
          let usertag = (
            <Typography.Paragraph className={`text-center no-bott-margin ${s.usertext}`}>
              <Tag color='#fff' className='no-right-margin'>&nbsp;</Tag>
            </Typography.Paragraph>
          );
          if (item.is_admin) {
            usertag = (
              <Typography.Paragraph keyboard className={`text-center no-bott-margin ${s.usertext}`}>
                Company Admin
              </Typography.Paragraph>
            );
          } else if (item.role && item.role.id) {
            usertag = (
              <Ellipsis className={`text-center ${s.usertext}`}>
                <Tag color={`#${item.role.color}`} className='no-right-margin'>
                  {item.role.name}
                </Tag>
              </Ellipsis>
            );
          }
          let badgeIcon;
          if (item.is_admin) {
            badgeIcon = <FaStar className={s.userstar} />;
          }
          return (
            <List.Item>
              <Card
                onClick={() => page(`/user/${item.id}`)}
                className='cursor-pointer text-center'
              >
                <Badge
                  offset={[-7, 50]}
                  count={badgeIcon}
                >
                  <Avatar thumb size={64} user={item} />
                </Badge>
                <div className={s.userbox}>
                  <Ellipsis className={`text-center ${s.usertext}`}>
                    {Utils.formatName(item)}
                  </Ellipsis>
                  {usertag}
                </div>
              </Card>
            </List.Item>
          );
        }}
      />
    </ConfigProvider>
  );
};

export default CompanyUsers;
