import { useState, useContext } from 'react';
import { Context, Erratum, Utils } from 'shookt';
import { Form, Input, Button, Result, Typography } from 'antd';
import { PasswordInput } from 'antd-password-input-strength';
import { FaLock } from 'react-icons/fa';
import profilePasswordPost from './profilePasswordPost';

const ProfilePassword = props => {
  const [state, dispatch] = useContext(Context);
  const [done, setDone] = useState(false);
  const [form] = Form.useForm();

  const onFinish = async values => {
    const { status } = await profilePasswordPost({
      password: values.editprof_new_password,
      password_old: values.editprof_password
    });
    if (status === 200) {
      setDone(true);
    }
  };

  let body = (
    <Form
      form={form}
      layout='vertical'
      onFinish={onFinish}
      onValuesChange={Utils.clearError(dispatch)}
    >
      <Form.Item
        label='Current Password'
        name='editprof_password'
        rules={[{
          min: 8,
          max: 100,
          required: true,
          message: 'Please enter your current password'
        }]}
      >
        <Input.Password placeholder='Current password' />
      </Form.Item>

      <Form.Item
        label='New password'
        name='editprof_new_password'
        rules={[Utils.passwordPolicy({
          minLength: 8,
          hasNumbers: true,
          hasLowerCase: true,
          hasUpperCase: true,
          hasNonalphas: true
        })]}
      >
        <PasswordInput placeholder='New Password' />
      </Form.Item>

      <Form.Item
        label='Repeat new password'
        name='editprof_repeat_password'
        rules={[{
          required: true,
          message: 'Please confirm your password'
        }, Utils.mustMatch('editprof_new_password',
          'Both passwords must match')
        ]}
      >
        <Input.Password placeholder='Repeat new password' />
      </Form.Item>

      <Erratum />

      <Form.Item>
        <Button
          block
          type='primary'
          htmlType='submit'
          disabled={state.loading}
          loading={state.loading}
        >
          Save changes
        </Button>
      </Form.Item>
    </Form>
  );

  if (done) {
    body = (
      <Result
        status='success'
        title='Password updated'
        subTitle={
          <>
            <Typography.Paragraph type='secondary'>
              Your new password was successfully saved.<br /><br />
              <FaLock />
            </Typography.Paragraph>
          </>
        }
      />
    );
  }

  return body;
};

export default ProfilePassword;
