import { useContext, useEffect, useState } from 'react';
import { Row, Col, Dropdown, Button, Badge, Card, Breadcrumb, Typography, Upload, Modal, Popconfirm } from 'antd';
import { Dashboard, Timestamp, Header, Picture, Tabs, Desc } from '../../components';
import { Context, Utils } from 'shookt';
import { FiChevronLeft, FiChevronDown } from 'react-icons/fi';
import s from './product.module.less';
import productGet from './productGet';
import productPost from './productPost';
import ProductForm from './ProductForm';
import imagePost from './image/imagePost';
import imageDelete from './image/imageDelete';
import VariationForm from './variation/VariationForm';
import VariationList from './variation/VariationList';

const ProductView = props => {
  const [state, dispatch] = useContext(Context);
  const [status, setStatus] = useState(0);
  const [product, setProduct] = useState({});
  const [imageList, setImageList] = useState([]);
  const [confirmArray, setConfirmArray] = useState([]);
  const [previewImg, setPreviewImg] = useState(null);
  const [preview, setPreview] = useState(false);
  const [key, setKey] = useState('1');
  const toDrawer = Dashboard.toDrawer(dispatch);
  const hidden = new Array(10).fill(false);
  const crumb = [];
  if (Array.isArray(product.category_crumb)) {
    product.category_crumb.forEach(a => {
      crumb.push(<Breadcrumb.Item key={a.id}>{a.name}</Breadcrumb.Item>);
    });
  }
  if (product.category) {
    crumb.push(<Breadcrumb.Item key={product.category.id}>{product.category.name}</Breadcrumb.Item>);
  }

  const enableProduct = async () => {
    const { status } = await productPost({
      is_enabled: !product.is_enabled,
      id: product.id
    });
    if (status === 200) {
      setTimeout(() => {
        loadData();
      }, 100);
    }
  };

  const loadData = () => {
    (async () => {
      const { status, data } = await productGet(props.params.id);
      if (status === 200) {
        if (data.category_crumb) {
          data.category_crumb.reverse();
        }
        setProduct(data);
        createImgList(data.images);
      }
      setStatus(status);
    })();
  };

  const createImgList = images => {
    const dataList = [];
    const keyArr = Array.from({ length: 10 }, (_, i) => i + 1).map(String);
    images.forEach(e => {
      keyArr.splice(keyArr.indexOf(e.model_key), 1);
      dataList.push({
        uid: e.id,
        status: 'done',
        name: e.model_key,
        url: e.file
      });
    });

    setConfirmArray(hidden);
    setImageList(dataList.reverse());
    keyArr.length > 0 ? setKey(keyArr[0]) : setKey('0');
  };

  const uploadImage = result => {
    (async () => {
      dispatch({ upsert: { loading: true } });
      const { status } = await imagePost({
        product_id: product.id,
        key: key,
        file: result
      });
      if (status === 200) {
        dispatch({ upsert: { reload: true } });
      }
    })();
  };

  const deleteImage = file => {
    dispatch({ upsert: { loading: true } });
    (async () => {
      const { status } = await imageDelete(file.uid);
      if (status === 200) {
        dispatch({ upsert: { reload: true } });
      }
    })();
  };

  const handleChange = props => {
    if (!props.file.status && props.file.status !== 'removed') {
      imageList.push({
        uid: key,
        status: 'uploading',
        name: key
      });
    }
  };

  const handlePreview = file => {
    setConfirmArray(hidden);
    setPreviewImg(file.url);
    setPreview(true);
  };

  const showPopconfirm = file => {
    const newArray = hidden;
    newArray[parseInt(file.name) - 1] = true;
    setConfirmArray(newArray);
  };

  useEffect(() => {
    if (state.reload) {
      dispatch({ delete: ['reload'] });
    }
    loadData();
  }, [state.reload]);

  return (
    <Dashboard
      viewspin
      status={status}
      back='/product/all'
    >
      <Header
        noTop
        title={(
          <>
            <Button
              shape='circle'
              onClick={() => window.history.back()}
              icon={<FiChevronLeft />}
            />
            <Typography.Title className='no-bott-margin' level={4}>
              &nbsp;{product.name}
            </Typography.Title>
          </>
        )}
        extra={(
          <Dropdown.Button
            onClick={toDrawer(<ProductForm id={product.id} key={product.id} />, 'Update product information')}
            trigger={['click']}
            icon={<FiChevronDown />}
            menu={{
              items: [
                {
                  key: 'new',
                  label: 'Add product variation',
                  onClick: toDrawer(<VariationForm productId={product.id} />, 'New product variation')
                },
                {
                  key: 'enable',
                  label: `${product.is_enabled ? 'Close' : 'Open'} product for sale`,
                  onClick: enableProduct
                }
              ]
            }}
          >
            Edit product
          </Dropdown.Button>
        )}
      />
      <Breadcrumb className={s.crumb}>
        {crumb}
      </Breadcrumb>
      <Row gutter={[10, 10]}>
        <Col xs={24} md={8} lg={7}>
          <Badge.Ribbon
            text={product.is_enabled ? 'Open For Sale' : 'Not Open For Sale'}
            color={product.is_enabled ? 'green' : '#aaa'}
            className={s.isForSale}
          >
            <Card
              hoverable
              className={`product ${s.cover}`}
              cover={<Picture width='100%' src={product.avatar} />}
            >
              <Card.Meta title={product.code} description={product.name} />
            </Card>
          </Badge.Ribbon>
        </Col>
        <Col xs={24} md={16} lg={17}>
          <br />
          <Desc>
            <Desc.Item span={3}>
              {product.description}
            </Desc.Item>
            <Desc.Item label='Name'>
              {product.name}
            </Desc.Item>
            <Desc.Item label='Last update'>
              <Timestamp stamp={product.updated_at} />
            </Desc.Item>
            <Desc.Item label='Last update by'>
              {product.updated_by && product.updated_by.id && (Utils.formatName(product.updated_by))}
            </Desc.Item>
            <Desc.Item label='ID'>
              {product.id}
            </Desc.Item>
            <Desc.Item label='Created at'>
              <Timestamp stamp={product.created_at} />
            </Desc.Item>
            <Desc.Item label='Created by'>
              {product.created_by && product.created_by.id && (Utils.formatName(product.created_by))}
            </Desc.Item>
            {
              product.company &&
              product.company.id &&
              state.user &&
              state.user.privileges.company && (
                <Desc.Item label='Distributor'>
                  {product.company.name}
                </Desc.Item>
              )
            }
            <Desc.Item label='Brand'>
              {product.brand}
            </Desc.Item>
            <Desc.Item label='Principal'>
              {product.principal}
            </Desc.Item>
            <Desc.Item label='Manufacturer'>
              {product.manufacturer}
            </Desc.Item>
          </Desc>
          <Upload
            accept='image/png,image/jpeg'
            showUploadList
            fileList={imageList}
            listType='picture-card'
            beforeUpload={Utils.readFile(uploadImage)}
            onChange={handleChange}
            onPreview={handlePreview}
            onRemove={showPopconfirm}
            itemRender={(originNode, file) => {
              return (
                <Popconfirm
                  title='Delete this image?'
                  onConfirm={() => deleteImage(file)}
                  onCancel={() => setConfirmArray(hidden)}
                  open={confirmArray[parseInt(file.name) - 1]}
                >
                  {originNode}
                </Popconfirm>
              );
            }}
          >
            {(imageList && imageList.length) < 10 ? '+' : null}
          </Upload>
          <Modal
            open={preview}
            onCancel={() => { setPreview(false); }}
            footer={null}
          >
            <img alt='' style={{ width: '100%' }} src={previewImg} />
          </Modal>
        </Col>
      </Row>
      <Tabs
        defaultKey='variation'
        storeKey='product'
        tabBarExtraContent={(
          <Button onClick={toDrawer(<VariationForm productId={product.id} />, 'New product variation')}>
            Add variation
          </Button>
        )}
        items={[
          {
            key: 'variation',
            label: 'Variations',
            children: <VariationList productId={product.id} dataSource={product.variations} />
          }
        ]}
      />
    </Dashboard>
  );
};

export default ProductView;
