import page from 'page';
import { useState, useContext } from 'react';
import { Context, Captcha, Erratum, Mobile, Utils, USERPATH } from 'shookt';
import { Checkbox, Radio, Button, Form, Input } from 'antd';
import { AiOutlineLock, AiOutlineMail } from 'react-icons/ai';
import { authenticate } from '../../session';
import { Panel } from '../../components';
import authPost from './authPost';

const Login = props => {
  const [state, dispatch] = useContext(Context);
  const [phone, setPhone] = useState('');
  const [idMode, setIdMode] = useState('email');
  const [form] = Form.useForm();

  const onFinish = async values => {
    if (idMode === 'mobile' && !phone) {
      return dispatch({ upsert: { error: 'Please enter a valid mobile number.' } });
    }
    const { status } = await authPost({
      mobile: phone,
      email: values.login_email,
      password: values.login_password,
      remember: values.login_remember
    });
    if (status === 200) {
      await authenticate(dispatch);
      page(USERPATH);
    }
  };

  const onIdChange = e => {
    setPhone('');
    setIdMode(e.target.value);
    dispatch({ delete: ['error'] });
    form.resetFields();
  };

  let idInput;

  if (idMode === 'mobile') {
    idInput = (
      <Form.Item
        name='login_mobile'
        rules={[{
          min: 12,
          max: 100,
          required: true,
          message: 'Please enter a valid mobile'
        }]}
      >
        <Mobile onPhone={setPhone} country='ph' />
      </Form.Item>
    );
  }

  if (idMode === 'email') {
    idInput = (
      <Form.Item
        name='login_email'
        rules={[{
          type: 'email',
          required: true,
          message: 'Please enter a valid email'
        }]}
      >
        <Input
          prefix={<AiOutlineMail />}
          minLength={10}
          maxLength={80}
          placeholder='Email'
        />
      </Form.Item>
    );
  }

  return (
    <Panel style={{ maxWidth: 344 }}>
      <Form
        form={form}
        layout='vertical'
        onFinish={onFinish}
        onValuesChange={Utils.clearError(dispatch)}
      >
        <Form.Item>
          <Radio.Group onChange={onIdChange} value={idMode}>
            <Radio value='email'>Email</Radio>
            <Radio value='mobile'>Mobile</Radio>
          </Radio.Group>
        </Form.Item>

        {idInput}

        <Form.Item
          name='login_password'
          rules={[{
            min: 8,
            max: 100,
            required: true,
            message: 'Please enter your password'
          }]}
        >
          <Input.Password
            prefix={<AiOutlineLock />}
            placeholder='Password'
          />
        </Form.Item>

        <Form.Item
          name='login_remember'
          valuePropName='checked'
        >
          <Checkbox>Remember me</Checkbox>
        </Form.Item>

        <Captcha />
        <Erratum />

        <Form.Item>
          <Button
            block
            type='primary'
            htmlType='submit'
            disabled={state.loading}
            loading={state.loading}
          >
            Login
          </Button>
        </Form.Item>

        <Button href='/forgot' type='link'>
          Forgot password?
        </Button>
        <Button href='/register' type='link'>
          Register an account
        </Button>
      </Form>
    </Panel>
  );
};

export default Login;
