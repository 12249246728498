import { useContext, useEffect } from 'react';
import { Context } from 'shookt';
import { Button } from 'antd';
import { Dashboard, Panel, Header, Markdown } from '../../components';
import { FiChevronLeft } from 'react-icons/fi';
import literaryGet from './literaryGet';

const PrivacyPolicy = props => {
  const [state, dispatch] = useContext(Context);

  useEffect(() => {
    (async () => {
      if (!state.privacypolicy) {
        const { status, data } = await literaryGet('privacypolicy');
        if (status === 200) {
          dispatch({ upsert: { privacypolicy: data } });
        }
      }
    })();
  }, []);

  return (
    <Dashboard back='/'>
      <Header
        noTop
        noBottom
        title={(
          <Button
            shape='circle'
            onClick={() => window.history.back()}
            icon={<FiChevronLeft />}
          />
        )}
      />
      <Panel>
        <Markdown style={{ maxWidth: 852 }} value={state.privacypolicy} />
      </Panel>
    </Dashboard>
  );
};

export default PrivacyPolicy;
