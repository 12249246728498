import { useContext, useEffect, useState } from 'react';
import { Dropdown, Tag, List, Space, Button, Typography } from 'antd';
import { Dashboard, Timestamp, Address, Avatar, Variations, Header, Ellipsis, Spacer, Desc } from '../../components';
import { Context, GoogleMap, Utils } from 'shookt';
import { FiChevronLeft, FiChevronDown } from 'react-icons/fi';
import s from './order.module.less';
import orderGet from './orderGet';
import orderPost from './orderPost';
import OrderLogs from './OrderLogs';
import OrderForm from './OrderForm';
import OrderItemForm from './OrderItemForm';

const OrderView = props => {
  const [state, dispatch] = useContext(Context);
  const [status, setStatus] = useState(0);
  const [total, setTotal] = useState(0);
  const [qty, setQty] = useState(0);
  const [order, setOrder] = useState({ delivery: [{}], delivery_status: [{}], payment: [{}], payment_status: [{}], address: {}, recipient: {}, billing: {} });
  const toDrawer = Dashboard.toDrawer(dispatch);
  const isAssigned = !!(order.processed_by && String(order.processed_by.id) === String(state.user.id));
  const addr = order.address || {};
  const lat = addr.lat ? parseFloat(addr.lat) : '';
  const lng = addr.lng ? parseFloat(addr.lng) : '';

  const updateHandler = async () => {
    const { status } = await orderPost({
      id: order.id,
      type: 'assign',
      status: isAssigned ? 'unassigned' : 'assigned'
    });
    if (status === 200) {
      loadData();
    }
  };

  const loadData = () => {
    (async () => {
      const { status, data } = await orderGet(props.params.id);
      if (status === 200) {
        let qty = 0;
        let ttl = 0;
        data.items.forEach(item => {
          ttl += item.quantity * item.pricing.unit_price;
          qty += item.quantity;
        });
        setQty(qty);
        setTotal(ttl);
        setOrder(data);
      }
      setStatus(status);
    })();
  };

  useEffect(() => {
    if (state.reload) {
      dispatch({ delete: ['reload'] });
    }
    loadData();
  }, [state.reload]);

  return (
    <Dashboard
      viewspin
      status={status}
      back='/order/all'
    >
      <Header
        noTop
        title={(
          <>
            <Button
              shape='circle'
              onClick={() => window.history.back()}
              icon={<FiChevronLeft />}
            />
            <Typography.Title className='no-bott-margin' level={4}>
              &nbsp;{state.screens.lg ? order.code : order.code && `...${order.code.split('-')[2]}`}
            </Typography.Title>
          </>
        )}
        extra={(
          <Dropdown.Button
            onClick={toDrawer(<OrderLogs order={order} />, 'Order logs')}
            trigger={['click']}
            icon={<FiChevronDown />}
            menu={{
              items: [
                {
                  key: 'status',
                  label: 'Update order status',
                  onClick: toDrawer(<OrderForm order={order} />, 'Update order status')
                },
                {
                  key: 'handler',
                  label: `${isAssigned ? 'Unassign' : 'Assign'} order to me`,
                  disabled: state.loading,
                  onClick: updateHandler
                }
              ]
            }}
          >
            View logs
          </Dropdown.Button>
        )}
      />
      <Desc>
        <Desc.Item label='Handler'>
          {Utils.formatName(order.processed_by) || 'None'}
        </Desc.Item>
        <Desc.Item label='Status'>
          <Tag color={orderGet.toStatusColor(order.status)}>
            {orderGet.statusToLabel(order.status)}
          </Tag>
        </Desc.Item>
        <Desc.Item label='Code'>
          {order.code}
        </Desc.Item>
        <Desc.Item label='Created at'>
          <Timestamp stamp={order.created_at} />
        </Desc.Item>
        <Desc.Item label='Last update at'>
          <Timestamp stamp={order.updated_at} />
        </Desc.Item>
        {
          state.user.type === 'admin' && (
            <Desc.Item label='Distributor'>
              <Spacer block align='center' direction='horizontal'>
                <Ellipsis style={!state.screens.lg && { width: 'calc(100vw - 130px)' }}>
                  {order.company && order.company.name}
                </Ellipsis>
                <Avatar size={20} user={order.company} thumb />
              </Spacer>
            </Desc.Item>
          )
        }
      </Desc>
      <Desc
        label='Payment details'
        extra={(
          <Tag color={orderGet.toStatusColor(order.status_payment)}>
            {orderGet.statusToLabel(order.status_payment)}
          </Tag>
        )}
      >
        <Desc.Item label='Reference No.'>
          {order.payment[0].code || '-'}
        </Desc.Item>
        <Desc.Item label='Method'>
          {orderGet.methodCodeToName(order.method_payment)}
        </Desc.Item>
        <Desc.Item label='Total'>
          ₱{Utils.toMonetary(total)}
        </Desc.Item>
      </Desc>
      <Desc
        label='Delivery address'
        extra={(
          <Tag color={orderGet.toStatusColor(order.status_delivery)}>
            {orderGet.statusToLabel(order.status_delivery)}
          </Tag>
        )}
      >
        <Desc.Item label='Reference No.'>
          {order.delivery.reference_code || '-'}
        </Desc.Item>
        <Desc.Item label='Method'>
          {orderGet.methodCodeToName(order.method_delivery) || '-'}
        </Desc.Item>
        <Desc.Item label='Recipient'>
          {order.recipient.name}&nbsp;
          {order.recipient.mobile ? <a href={`tel:${order.recipient.mobile.replace('+63', '0')}`}>{order.recipient.mobile}</a> : '-'}
        </Desc.Item>
        <Desc.Item label='House/Subdivision'>
          {order.address.housesubd || '-'}
        </Desc.Item>
        <Desc.Item label='Zip Code'>
          {order.address.zipcode || '-'}
        </Desc.Item>
        <Desc.Item label='Barangay'>
          {Utils.toTitleCase(order.address.barangay || '-')}
        </Desc.Item>
        <Desc.Item label='Town/City'>
          {Utils.toTitleCase(order.address.towncity || '-')}
        </Desc.Item>
        <Desc.Item label='Province'>
          {Utils.toTitleCase(order.address.province || '-')}
        </Desc.Item>
        <Desc.Item label='Region'>
          {Utils.toTitleCase(order.address.region || '-')}
        </Desc.Item>
        <Desc.Item>
          <GoogleMap
            reCenter
            readOnly
            theme={Address.theme}
            values={{ zoom: 16, lat, lng, center: [lat, lng] }}
          />
        </Desc.Item>
      </Desc>
      <Header title='Products' noBottom extra={<Typography.Text>Total ₱{Utils.toMonetary(total)}</Typography.Text>} />
      <List
        itemLayout='vertical'
        dataSource={order.items}
        renderItem={item => {
          const product = item.product || (item.ledger && item.ledger.product);
          return (
            <List.Item
              onClick={toDrawer(<OrderItemForm key={item.id} order={order} item={item} />, 'Update order item inventory stock')}
              className='cursor-pointer'
            >
              <List.Item.Meta
                avatar={<Avatar shape='square' size={46} user={product} thumb />}
                title={(
                  <Ellipsis>
                    {product.code}: {product.name}
                  </Ellipsis>
                )}
                description={(
                  <>
                    <Spacer block>
                      <Space>
                        <Typography.Text type='secondary'>
                          Quantity:
                        </Typography.Text>
                        <Typography.Text className={s.qty}>
                          {Utils.toMonetary(item.quantity, 0)}
                        </Typography.Text>
                      </Space>
                      <Space>
                        <Typography.Text type='secondary'>
                          Price:
                        </Typography.Text>
                        <Typography.Text className={s.qty}>
                          ₱{Utils.toMonetary(item.pricing.unit_price)}
                        </Typography.Text>
                      </Space>
                      <Space>
                        <Typography.Text type='secondary'>
                          Sub-total:
                        </Typography.Text>
                        <Typography.Text className={s.qty}>
                          ₱{Utils.toMonetary(item.pricing.unit_price * item.quantity)}
                        </Typography.Text>
                      </Space>
                    </Spacer>
                    <Variations item={item} view />
                    <Header
                      noTop
                      noBottom
                      title={(
                        <Typography.Text type='secondary'>
                          Stock items
                        </Typography.Text>
                      )}
                    />
                    <List
                      dataSource={[1]}
                      renderItem={item => {
                        return (
                          <Spacer center block className={s.varideets}>
                            <Space>
                              <Typography.Text type='secondary'>
                                Stock Id:
                              </Typography.Text>
                              <Ellipsis>
                                -
                              </Ellipsis>
                            </Space>
                            <Space>
                              <Typography.Text type='secondary'>
                                Expiry:
                              </Typography.Text>
                              <Typography.Text>
                                -
                              </Typography.Text>
                            </Space>
                            <Space>
                              <Typography.Text type='secondary'>
                                Batch No:
                              </Typography.Text>
                              <Ellipsis>
                                -
                              </Ellipsis>
                            </Space>
                            <Space>
                              <Typography.Text type='secondary'>
                                Quantity:
                              </Typography.Text>
                              <Ellipsis>
                                0
                              </Ellipsis>
                            </Space>
                          </Spacer>
                        );
                      }}
                    />
                  </>
                )}
              />
            </List.Item>
          );
        }}
      />
      <Header title={`Total ${Utils.toMonetary(qty, 0)} pcs`} extra={<Typography.Text>Total ₱{Utils.toMonetary(total)}</Typography.Text>} />
    </Dashboard>
  );
};

export default OrderView;
