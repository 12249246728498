import { Descriptions } from 'antd';
import { Header } from '..';

const defaults = {
  colon: false,
  layout: 'vertical',
  labelStyle: {
    color: '#bbb',
    fontSize: 13
  }
};

const Desc = props => {
  const { children, noTop, noBottom, label, extra, ...desc } = props;

  return (
    <>
      {label ? <Header title={label} extra={extra} noTop={noTop} noBottom={noBottom} /> : ''}
      <Descriptions {...defaults} {...desc}>
        {children}
      </Descriptions>
    </>
  );
};

Desc.Item = Descriptions.Item;

export default Desc;
