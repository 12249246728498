import { useEffect, useContext, useState } from 'react';
import { Button, Row, Col, List, Card, Breadcrumb, Typography, Tag, message } from 'antd';
import { Dashboard, Header, Picture, Ellipsis, Spacer, Avatar } from '../../components';
import { ShoppingCartOutlined, HeartOutlined, ShareAltOutlined, HeartFilled } from '@ant-design/icons';
import { Context, Utils } from 'shookt';
import copy from 'copy-to-clipboard';
import page from 'page';
import s from './market.module.less';
import marketGet from './marketGet';
import MarketHead from './MarketHead';
import MarketForm from './MarketForm';
import MarketButton from './MarketButton';
import marketFavoritePost from './marketFavoritePost';

const MarketView = props => {
  const [state, dispatch] = useContext(Context);
  const [product, setProduct] = useState({ company: {} });
  const [noprice, setNoprice] = useState();
  const toDrawer = Dashboard.toDrawer(dispatch);
  const crumb = [];
  if (Array.isArray(product.category_crumb)) {
    product.category_crumb.forEach(a => {
      crumb.push(<Breadcrumb.Item key={a.id} href={`/?category=${a.id}`}>{a.name}</Breadcrumb.Item>);
    });
  }

  const toCart = () => {
    if (!state.user) {
      return page('/login');
    }
    toDrawer(<MarketForm productId={product.id} />, 'Add to cart')();
  };

  const toBuy = () => {
    if (!state.user) {
      return page('/login');
    }
    toDrawer(<MarketForm productId={product.id} buy />, `Buy ${product.name}`)();
  };

  const toFavorite = async () => {
    if (!state.user) {
      return page('/login');
    }
    const { status } = await marketFavoritePost({
      is_deleted: product.favorite,
      product_id: product.id
    });
    if (status === 200) {
      dispatch({ upsert: { reload: true } });
    }
  };

  const loadData = () => {
    (async () => {
      const { status, data } = await marketGet(props.params.id);
      if (status === 200) {
        if (data.category_crumb) {
          data.category_crumb.reverse();
        }
        setProduct(data);
        setNoprice(!data.pricing);
      }
    })();
  };

  useEffect(() => {
    if (state.reload) {
      // dispatch({ delete: ['reload'] });
      loadData();
    }
    loadData();
  }, [state.reload]);

  let pricing = 'No price yet';
  if (Array.isArray(product.pricing)) {
    let lowest;
    let highest;
    product.pricing.forEach(p => {
      if (!lowest || p.unit_price < lowest) {
        lowest = p.unit_price;
      }
      if (!highest || p.unit_price > highest) {
        highest = p.unit_price;
      }
    });
    pricing = '';
    if (lowest) {
      pricing = `₱${Utils.toMonetary(lowest)}`;
    }
    if (highest && highest !== lowest) {
      if (lowest) {
        pricing = `${pricing} - `;
      }
      pricing = `${pricing}₱${Utils.toMonetary(highest)}`;
    }
  }

  return (
    <Dashboard>
      <MarketHead />
      <Header
        noTop
        bottom={8}
        title={(
          <>
            <MarketButton />
            <Ellipsis className={s.header}>{product.category && product.category.name}</Ellipsis>
          </>
        )}
      />
      <Breadcrumb className={s.crumb}>
        {crumb}
      </Breadcrumb>
      <Row gutter={[20, 20]}>
        <Col xs={24} sm={10}>
          <Picture width='100%' height='330px' src={product.avatar} className={s.picture} />
          <Spacer direction='horizontal' align='center' className={s.picbuttons} split>
            <Button
              type='text'
              icon={<ShareAltOutlined />}
              onClick={() => {
                message.success(`${product.name} link copied to clipboard`);
                copy(window.location.href);
              }}
            >
              Share
            </Button>
            <Button
              type='text'
              icon={product.favorite ? <HeartFilled /> : <HeartOutlined />}
              onClick={toFavorite}
            >
              Favorite
            </Button>
          </Spacer>
        </Col>
        <Col xs={24} sm={14}>
          <Typography.Paragraph className={s.bigproduct}>
            {product.name}
          </Typography.Paragraph>
          <Typography.Paragraph className={`${s.bigprice} ${product.pricing ? s.primary : ''}`} type={product.pricing ? '' : 'secondary'}>
            {pricing}
          </Typography.Paragraph>
          <Spacer direction='horizontal' className={s.cartbuttons}>
            <Button
              disabled={noprice}
              icon={<ShoppingCartOutlined />}
              onClick={toCart}
            >
              Add to cart
            </Button>
            <Button
              disabled={noprice}
              type='primary'
              onClick={toBuy}
            >
              BUY
            </Button>
          </Spacer>
          {
            product.description && (
              <>
                <Header title={<>Product information</>} plain />
                <Typography.Paragraph>
                  <blockquote className={s.quote}>
                    {Utils.toCapitalize(product.description)}
                  </blockquote>
                </Typography.Paragraph>
              </>
            )
          }
          {
            product.variations &&
            product.variations.length > 0 && (
              <>
                <Header title={<>Product variations</>} plain bottom={20} />
                <List
                  split={false}
                  dataSource={product.variations || []}
                  renderItem={item => (
                    <Spacer className={s.variation}>
                      <Typography.Paragraph className='no-bott-margin'>
                        {item.name}:
                      </Typography.Paragraph>
                      <div>
                        {item.options.map(o => (<Tag className={s.option} key={o}>{Utils.toCapitalize(o)}</Tag>))}
                      </div>
                    </Spacer>
                  )}
                />
              </>
            )
          }
          <Header title={<>Distributor</>} plain top={20} />
          <Card bordered={false} className={s.nobg} bodyStyle={{ padding: '5px 12px 20px' }}>
            <Card.Meta
              className={s.nobg}
              avatar={<Avatar user={product.company} thumb />}
              title={product.company.name}
              description={(product.company.mobile || product.company.email) && (
                <>
                  <Typography.Text>
                    Contact Details:
                  </Typography.Text><br />
                  {product.company.mobile}<br />
                  {product.company.email}
                </>
              )}
            />
          </Card>
        </Col>
      </Row>
    </Dashboard>
  );
};

export default MarketView;
