import axios from 'axios';

const profilePasswordPost = async params => {
  try {
    const response = await axios({
      method: 'post',
      url: '/profile/password',
      data: params
    });
    return response;
  } catch ({ response }) {
    return response;
    // internal
  }
};

export default profilePasswordPost;
