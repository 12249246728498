import { useContext, useEffect, useState } from 'react';
import { Row, Col, Form, Input, Select, Button, DatePicker, message } from 'antd';
import { Context, ImgUpload, Mobile, Erratum, Utils } from 'shookt';
import { AiOutlineMail } from 'react-icons/ai';
import { FaUserAlt } from 'react-icons/fa';
import { Timestamp, CompanySelect } from '../../components';
import s from './user.module.less';
import page from 'page';
import userGet from './userGet';
import userPost from './userPost';
import roleGet from '../role/roleGet';

const UserForm = props => {
  const [state, dispatch] = useContext(Context);
  const [phone, setPhone] = useState('');
  const [avatar, setAvatar] = useState('');
  const [company, setCompany] = useState('');
  const [roleOpts, setRoleOpts] = useState([]);
  const [oldEmail, setOldEmail] = useState('');
  const [oldMobile, setOldMobile] = useState('');
  const [isActive, setIsActive] = useState(false);
  const [form] = Form.useForm();
  const isAdmin = state.user.type === 'admin';
  const isManager = state.user.type === 'manager';

  const onFinish = async values => {
    const payload = {
      role_id: values.newuser_role || null,
      first_name: values.newuser_first_name.trim(),
      middle_name: values.newuser_middle_name.trim(),
      last_name: values.newuser_last_name.trim(),
      religion: values.newuser_religion,
      civil_status: values.newuser_civil_status,
      sex: values.newuser_sex
    };
    if (values.newuser_birthdate) {
      payload.birthdate = values.newuser_birthdate.format('YYYY-MM-DD');
    }
    if (props.id) {
      payload.id = props.id;
    }
    if (avatar && avatar.startsWith('data:')) {
      payload.avatar = avatar;
    }
    if (values.newuser_company && isAdmin) {
      payload.company_id = values.newuser_company;
    }
    if (values.newuser_email && (isAdmin || isManager)) {
      payload.email = values.newuser_email;
    }
    if (phone && (isAdmin || isManager)) {
      payload.mobile = phone;
    }
    const { status, data } = await userPost(payload);
    if (status === 200) {
      if (!isActive) {
        if (data.email && data.email !== oldEmail) {
          message.success(`Invite sent to ${data.email}`);
        }
        if (data.mobile && data.mobile !== oldMobile) {
          message.success(`Invite sent to ${data.mobile}`);
        }
      }
      if (props.id) {
        dispatch({ upsert: { reload: true } });
      } else {
        page(`/user/${data.id}`);
      }
      dispatch({ delete: ['drawer'] });
    }
  };

  const loadRoles = async (cmp) => {
    const opts = cmp ? ({ company: cmp }) : {};
    const { data } = await roleGet('all', opts);
    setRoleOpts(data.data.map(i => ({
      label: i.name,
      value: i.id
    })));
  };

  useEffect(() => {
    (async () => {
      if (!isAdmin) {
        await loadRoles();
      }
      if (props.id) {
        const { status, data } = await userGet(props.id);
        if (status === 200) {
          if (isAdmin || isManager) {
            if (data.company_id) {
              await loadRoles(data.company_id);
            }
            if (data.mobile) {
              setPhone(data.mobile);
              setOldMobile(data.mobile);
            }
            setOldEmail(data.email);
            form.setFieldsValue({
              newuser_email: data.email,
              newuser_company: data.company_id
            });
          }
          form.setFieldsValue({
            newuser_first_name: Utils.toTitleCase(data.first_name),
            newuser_middle_name: Utils.toTitleCase(data.middle_name),
            newuser_last_name: Utils.toTitleCase(data.last_name),
            newuser_birthdate: data.birthdate ? Timestamp.dayjs(data.birthdate) : null,
            newuser_religion: Utils.toTitleCase(data.religion),
            newuser_civil_status: data.civil_status,
            newuser_sex: data.sex
          });
          if (data.role_id) {
            form.setFieldsValue({ newuser_role: data.role_id });
          }
          setAvatar(data.avatar);
          setIsActive(data.password);
        }
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      if (isAdmin && company) {
        await loadRoles(company);
        form.resetFields(['newuser_role']);
      }
    })();
  }, [company]);

  let rolePlaceHolder = 'Select role';
  if (roleOpts.length) {
    rolePlaceHolder = 'Select role';
  } else if (isAdmin && !company) {
    rolePlaceHolder = 'Select company first';
  } else {
    rolePlaceHolder = 'No roles available';
  }

  return (
    <Form
      form={form}
      layout='vertical'
      onFinish={onFinish}
      onValuesChange={Utils.clearError(dispatch)}
    >
      <Form.Item className='text-center'>
        <ImgUpload
          message={`${props.id ? 'Update' : 'Attach'} user photo`}
          imgStore={avatar}
          imgSet={setAvatar}
          icon={<FaUserAlt />}
          size={128}
          round
        />
      </Form.Item>

      <CompanySelect name='newuser_company' onChange={setCompany} />

      <Form.Item
        label='Role'
        name='newuser_role'
      >
        <Select
          showSearch
          allowClear
          options={roleOpts}
          disabled={state.user.id === props.id}
          placeholder={rolePlaceHolder}
          optionFilterProp='children'
          filterOption={(input, option) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        />
      </Form.Item>

      <Form.Item
        label='First name'
        name='newuser_first_name'
        hasFeedback
        rules={[{
          required: true,
          whitespace: true,
          pattern: '^[a-zA-ZñÑ. ]*$',
          message: 'Please enter your first name'
        }]}
      >
        <Input
          minLength={1}
          maxLength={100}
          placeholder='First name'
        />
      </Form.Item>

      <Form.Item
        label='Middle name'
        name='newuser_middle_name'
        hasFeedback
        rules={[{
          required: true,
          whitespace: true,
          pattern: '^[a-zA-ZñÑ. ]*$',
          message: 'Please enter your middle name'
        }]}
      >
        <Input
          minLength={1}
          maxLength={100}
          placeholder='Middle name'
        />
      </Form.Item>

      <Form.Item
        label='Last name'
        name='newuser_last_name'
        hasFeedback
        rules={[{
          required: true,
          whitespace: true,
          pattern: '^[a-zA-ZñÑ. ]*$',
          message: 'Please enter your last name'
        }]}
      >
        <Input
          minLength={1}
          maxLength={100}
          placeholder='Last name'
        />
      </Form.Item>

      <Row>
        <Col xs={12}>
          <Form.Item
            label='Birthday'
            name='newuser_birthdate'
            className={s.userbday}
          >
            <DatePicker placeholder='Birthday' format='YYYY/MM/DD' className={s.userbdayin} />
          </Form.Item>

          <Form.Item
            label='Civil Status'
            name='newuser_civil_status'
            className={s.usercivil}
          >
            <Select placeholder='Civil Status'>
              <Select.Option value='single'>Single</Select.Option>
              <Select.Option value='married'>Married</Select.Option>
              <Select.Option value='divorced'>Divorced</Select.Option>
              <Select.Option value='widowed'>Widowed</Select.Option>
            </Select>
          </Form.Item>
        </Col>

        <Col xs={12}>
          <Form.Item
            label='Sex'
            name='newuser_sex'
          >
            <Select placeholder='Sex'>
              <Select.Option value='male'>Male</Select.Option>
              <Select.Option value='female'>Female</Select.Option>
            </Select>
          </Form.Item>

          <Form.Item
            label='Religion'
            name='newuser_religion'
            rules={[{
              whitespace: true,
              pattern: '^[a-zA-ZñÑ. ]*$',
              message: 'Please enter a valid religion'
            }]}
          >
            <Input
              minLength={1}
              maxLength={200}
              placeholder='Religion'
            />
          </Form.Item>
        </Col>
      </Row>

      {
        (isAdmin || isManager) && (
          <>
            <Form.Item
              label='Email'
              name='newuser_email'
              rules={[{
                type: 'email',
                message: 'Please enter a valid email'
              }]}
            >
              <Input
                prefix={<AiOutlineMail />}
                minLength={10}
                maxLength={80}
                placeholder='Email'
              />
            </Form.Item>

            <Form.Item label='Mobile'>
              <Mobile value={phone} onPhone={setPhone} country='ph' className='ant-input' noAutoFocus />
            </Form.Item>
          </>
        )
      }

      <Erratum />

      <Form.Item>
        <Button
          block
          type='primary'
          htmlType='submit'
          disabled={state.loading}
          loading={state.loading}
        >
          {props.id ? 'Save changes' : 'Add new user'}
        </Button>
      </Form.Item>
    </Form>
  );
};

export default UserForm;
