import { useState, useContext, useEffect } from 'react';
import { Context, Erratum, Utils } from 'shookt';
import { Form, Button } from 'antd';
import { authenticate } from '../../session';
import { Address } from '../../components';
import profileAddressPost from './profileAddressPost';
import profileGet from './profileGet';

const ProfileAddress = props => {
  const [state, dispatch] = useContext(Context);
  const [address, setAddress] = useState({});
  const [form] = Form.useForm();

  const onFinish = async values => {
    const { status } = await profileAddressPost({
      housesubd: values.editprof_housesubd.trim(),
      zipcode: values.editprof_zipcode,
      region: values.editprof_region,
      province: values.editprof_province,
      towncity: values.editprof_towncity,
      barangay: values.editprof_barangay,
      lat: values.editprof_lat,
      lng: values.editprof_lng
    });
    if (status === 200) {
      await authenticate(dispatch);
      dispatch({ delete: ['drawer'] });
    }
  };

  useEffect(() => {
    (async () => {
      const { status, data } = await profileGet();
      if (status === 200) {
        const { address } = data;
        if (address) {
          setTimeout(() => {
            setAddress({
              housesubd: address.housesubd,
              zipcode: address.zipcode,
              region: address.region,
              province: address.province,
              towncity: address.towncity,
              barangay: address.barangay,
              lat: address.lat ? parseFloat(address.lat) : '',
              lng: address.lng ? parseFloat(address.lng) : ''
            });
          }, 100);
        }
      }
    })();
  }, []);

  return (
    <Form
      form={form}
      layout='vertical'
      onFinish={onFinish}
      onValuesChange={Utils.clearError(dispatch)}
      autoComplete='off'
    >
      <Address
        form={form}
        values={address}
        prefix='editprof_'
      />

      <Erratum />

      <Form.Item>
        <Button
          block
          type='primary'
          htmlType='submit'
          disabled={state.loading}
          loading={state.loading}
        >
          Save changes
        </Button>
      </Form.Item>
    </Form>
  );
};

export default ProfileAddress;
