import { Utils } from 'shookt';
import { Typography, Space } from 'antd';
import { Timestamp, Avatar } from '../';
import s from './created.module.less';

const Created = props => {
  if (!props.item) {
    return null;
  }
  if (props.updated && !props.item.updated_at) {
    return null;
  }
  let user = props.item[props.updated ? 'updated_by' : 'created_by'];
  let time = props.item[props.updated ? 'updated_at' : 'created_at'];
  if (props.user) {
    user = props.item[props.user];
  }
  if (props.time) {
    time = props.item[props.time];
  }

  return (
    <Space align='center' direction='horizontal' className='cursor-pointer'>
      <Typography.Text type='secondary' className={s.created}>
        <Timestamp stamp={time} prefix={props.prefix || (props.updated ? 'Updated' : 'Created')} suffix={(user && user.id && !props.noAvatar) ? 'by' : ''} />
      </Typography.Text>
      {(user && user.id && !props.noAvatar) && (<Avatar tooltip={`${user.id} ${Utils.formatName(user)}`} user={user} size={20} thumb />)}
    </Space>
  );
};

export default Created;
