import page from 'page';
import UploadView from './UploadView';


export const UploadRedirect = {
  path: '/upload',
  priv: 'upload',
  handler: () => page.redirect('/Upload/all')
};



export const UploadViewRoute = {
  path: '/upload/all',
  priv: 'upload',
  handler: props => <UploadView {...props} />
};
