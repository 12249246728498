import axios from 'axios';

const uploadCheck = async (pricing_id) => {
  try {
    const response = await axios({
      method: 'GET',
      url: '/upload/check',
      params: { pricing_id }, // Include pricing_id in the GET request parameters
    });
    return response;
  } catch ({ response }) {
    return response;
    // Handle errors if needed
  }
};

export default uploadCheck;
