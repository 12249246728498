// uploadPost.js

import axios from 'axios';

const uploadPost = async (jsonData, setProgress, setUploading) => {
  try {
    const response = await axios.post('/upload', jsonData, {
      headers: {
        'Content-Type': 'application/json',
      },
      onUploadProgress: (progressEvent) => {
        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        setProgress(percentCompleted);
      },
    });

    // Reset progress and indicate that uploading is complete
    setProgress(0);
    setUploading(false);

    // Return the response for further analysis or use in your application
    return response;
  } catch (error) {
    // Reset progress and indicate that uploading is complete
    setProgress(0);
    setUploading(false);

    if (error.response) {
      console.error('Server Error:', error.response.data);
      console.error('Status Code:', error.response.status);
      console.error('Headers:', error.response.headers);

      if (error.response.status === 400) {
        // Handle specific error condition
      } else {
        // Display a generic error message
      }
    } else if (error.request) {
      console.error('No response received from the server:', error.request);
    } else {
      console.error('Error setting up the request:', error.message);
    }

    // Return the error for further analysis or use in your application
    return error.response || error;
  }
};

export default uploadPost;
