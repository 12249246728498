import { Form, Input, message } from 'antd';
import categoryPost from './categoryPost';

const CategoryForm = props => {
  const [form] = Form.useForm();

  const onFinish = async ({ name }) => {
    if (!name.trim()) {
      message.error('Empty values are not allowed.');
      return;
    }
    const { status, data } = await categoryPost({ name, parent_id: props.parentId });
    if (status === 200) {
      await props.onFinish();
      form.resetFields();
    } else {
      message.error(data.message);
    }
  };

  return (
    <Form form={form} onFinish={onFinish}>
      <Form.Item name='name' noStyle>
        <Input
          required
          minLength={1}
          maxLength={100}
          placeholder='New category'
        />
      </Form.Item>
    </Form>
  );
};

export default CategoryForm;
