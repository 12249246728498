import { useContext, useEffect, useState } from 'react';
import { Form, Select } from 'antd';
import { Context } from 'shookt';
import s from './companyselect.module.less';
import distributorGet from '../../pages/company/distributor/distributorGet';

const DistributorSelect = (props) => {
  const [state] = useContext(Context);
  const [distributorOptions, setDistributorOptions] = useState([]);
  const [distributor, setDistributor] = useState([]);


  const onChange = (val = [], label = null) => {
    props.onChange && props.onChange(val);
    const selectedData = val.map((value, index) => ({
      value,
      label: label ? label[index]?.label : '',
    }));
    sessionStorage.setItem(`_company`, JSON.stringify(selectedData));
    setDistributor(val);
  };

  // Fetch distributors when 'props.province' changes
  useEffect(() => {
    const fetchData = async () => {
      if (props.province !== undefined) {
                const { status, data } = await distributorGet(props.province);
        if (status === 200) {
          setDistributorOptions(data.map((i) => ({
            label: i.model_id ? i.model_id.name : '',
            value: i.model_id ? i.model_id.id : '',
          })));
        
        }
      } 
    };
  
    fetchData();
  }, [props.province]);
  
  // useEffect(() => {
  //   setDistributor([]);
  //   setDistributorOptions([]);
  // }, [props.province]);

  const compLabel = sessionStorage.getItem('_company'); // Retrieve the stored data
  const selectedData = compLabel ? JSON.parse(compLabel) : []; // Parse the JSON string
  
  // Extract selected values from selectedData or use the distributor state if no data is in session storage
  const distDt = selectedData.map((item) => ({ key: item.value, label: item.label })) || distributor;
  let body = (
    <Select
      mode="multiple"
      showSearch
      value={distDt}
      style={props.style || {}}
      options={distributorOptions}
      onChange={onChange}
      disabled={props.disabled}
      defaultActiveFirstOption={true}
      className={s.select}
      allowClear={props.allowClear || true}
      placeholder='Select Wholesaler/Distributor'
      optionFilterProp='children'
      filterOption={(input, option) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
    />
  );

  if (props.name) {
    body = (
      <Form.Item
        name={props.name}
        label={props.label || 'Distributor'}
        rules={props.rules || []}
      >
        {body}
      </Form.Item>
    );
  }

  return body;
};

DistributorSelect.get = (props) => {
  return <DistributorSelect {...props} />;
};

export default DistributorSelect;
