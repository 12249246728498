import page from 'page';
import UserView from './UserView';
import UserList from './UserList';

export const userRedirect = {
  path: '/user',
  priv: 'user',
  handler: () => page.redirect('/user/all')
};

export const userListRoute = {
  path: '/user/all',
  priv: 'user',
  handler: props => <UserList {...props} />
};

export const userViewRoute = {
  path: '/user/:id',
  priv: 'user',
  handler: props => <UserView {...props} />
};
