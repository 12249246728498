import { useContext, useState } from 'react';
import { Form, Input, Button, Typography, message } from 'antd';
import { Context, ImgUpload, Erratum, Utils } from 'shookt';
import { AiOutlineMail } from 'react-icons/ai';
import { FaUserAlt } from 'react-icons/fa';
import userPost from '../user/userPost';

const CompanyAdmin = props => {
  const [state, dispatch] = useContext(Context);
  const [avatar, setAvatar] = useState('');
  const [form] = Form.useForm();

  const onFinish = async values => {
    const payload = {
      is_admin: true,
      company_id: props.companyId,
      role_id: values.newuser_role || null,
      first_name: values.newuser_first_name,
      middle_name: values.newuser_middle_name,
      last_name: values.newuser_last_name
    };
    if (values.newuser_birthdate) {
      payload.birthdate = values.newuser_birthdate.format('YYYY-MM-DD');
    }
    if (avatar && avatar.startsWith('data:')) {
      payload.avatar = avatar;
    }
    if (values.newuser_email) {
      payload.email = values.newuser_email;
    }
    const { status } = await userPost(payload);
    if (status === 200) {
      if (payload.email) {
        message.success(`Invite sent to ${payload.email}`);
      }
      dispatch({ upsert: { reload: true } });
      dispatch({ delete: ['drawer'] });
    }
  };

  return (
    <Form
      form={form}
      layout='vertical'
      onFinish={onFinish}
      onValuesChange={Utils.clearError(dispatch)}
    >
      <Form.Item>
        <Typography.Paragraph className='text-center'>
          {props.companyName}
        </Typography.Paragraph>
        <Typography.Paragraph keyboard className='text-center no-bott-margin'>
          Company Admin
        </Typography.Paragraph>
      </Form.Item>

      <Form.Item className='text-center'>
        <ImgUpload
          message='Attach user photo'
          imgStore={avatar}
          imgSet={setAvatar}
          icon={<FaUserAlt />}
          size={128}
          round
        />
      </Form.Item>

      <Form.Item
        label='Email'
        name='newuser_email'
        hasFeedback
        rules={[{
          min: 10,
          max: 80,
          type: 'email',
          required: true,
          message: 'Please enter a valid email'
        }]}
      >
        <Input
          prefix={<AiOutlineMail />}
          minLength={10}
          maxLength={80}
          placeholder='Email'
        />
      </Form.Item>

      <Form.Item
        label='First name'
        name='newuser_first_name'
        hasFeedback
        rules={[{
          required: true,
          pattern: '^[a-zA-ZñÑ. ]*$',
          message: 'Please enter your first name'
        }]}
      >
        <Input
          minLength={1}
          maxLength={100}
          placeholder='First name'
        />
      </Form.Item>

      <Form.Item
        label='Middle name'
        name='newuser_middle_name'
        hasFeedback
        rules={[{
          required: true,
          pattern: '^[a-zA-ZñÑ. ]*$',
          message: 'Please enter your middle name'
        }]}
      >
        <Input
          minLength={1}
          maxLength={100}
          placeholder='Middle name'
        />
      </Form.Item>

      <Form.Item
        label='Last name'
        name='newuser_last_name'
        hasFeedback
        rules={[{
          required: true,
          pattern: '^[a-zA-ZñÑ. ]*$',
          message: 'Please enter your last name'
        }]}
      >
        <Input
          minLength={1}
          maxLength={100}
          placeholder='Last name'
        />
      </Form.Item>

      <Erratum />

      <Form.Item>
        <Button
          block
          type='primary'
          htmlType='submit'
          disabled={state.loading}
          loading={state.loading}
        >
          Add company admin
        </Button>
      </Form.Item>
    </Form>
  );
};

export default CompanyAdmin;
