import Forgot from './Forgot';
import Recover from './Recover';

export const forgotRoute = {
  path: '/forgot',
  code: 'forgot',
  auth: false,
  handler: props => <Forgot {...props} />
};

export const recoverRoute = {
  path: '/recover/:code',
  code: 'recover',
  handler: props => <Recover {...props} />
};
