import { useState, useEffect } from 'react';
import { Select, Input, InputNumber, Form } from 'antd';
import { Barangay, GoogleMap } from 'shookt';
import geocodePost from './geocodePost';
import s from './address.module.less';
import theme from './theme';

const Address = ({ form, prefix = '', suffix = '', values = {} }) => {
  const [brgy, setBrgy] = useState({});
  const [gmap, setGmap] = useState({});
  const fields = [
    `${prefix}region${suffix}`,
    `${prefix}province${suffix}`,
    `${prefix}towncity${suffix}`,
    `${prefix}barangay${suffix}`,
    `${prefix}housesubd${suffix}`,
    `${prefix}zipcode${suffix}`,
    `${prefix}lat${suffix}`,
    `${prefix}lng${suffix}`
  ];
  const brgyObj = { values: brgy, onChange: setBrgy, form, fields };
  const [region, province, towncity, barangay] = Barangay(brgyObj);

  useEffect(() => {
    (async () => {
      if (!brgy.active || !brgy.province) {
        return;
      }

      let zoom = 11;
      let towncity = '';
      let barangay = '';
      if (brgy.towncity) {
        towncity = `${brgy.towncity}, `;
        zoom = 12;
        if (brgy.barangay) {
          barangay = `${brgy.barangay}, `;
          zoom = 15;
        }
      }
      const addrStr = `${barangay}${towncity}${brgy.province},`.trim();
      const res = await geocodePost(addrStr);
      if (res.data) {
        const { lat, lng } = res.data;
        if (lat && lng) {
          setGmap({
            ...gmap,
            zoom,
            center: [
              parseFloat(lat),
              parseFloat(lng)
            ]
          });
        }
      }
    })();
  }, [brgy.region, brgy.province, brgy.towncity, brgy.barangay]);

  useEffect(() => {
    (async () => {
      setBrgy({
        region: values.region,
        province: values.province,
        towncity: values.towncity,
        barangay: values.barangay
      });
    })();
  }, [values.region, values.province, values.towncity, values.barangay]);

  useEffect(() => {
    if (values.housesubd) {
      form.setFieldsValue({ [fields[4]]: values.housesubd });
    }
    if (values.zipcode) {
      form.setFieldsValue({ [fields[5]]: values.zipcode });
    }
  }, [values.housesubd, values.zipcode]);

  useEffect(() => {
    if (values.lat && values.lng) {
      form.setFieldsValue({ [fields[6]]: values.lat });
      form.setFieldsValue({ [fields[7]]: values.lng });
      setGmap({
        ...gmap,
        zoom: 16,
        lat: values.lat,
        lng: values.lng,
        center: [
          parseFloat(values.lat),
          parseFloat(values.lng)
        ]
      });
    }
  }, [values.lat, values.lng]);

  return (
    <>
      <Form.Item
        label='House/Subd'
        name={fields[4]}
        rules={[{
          required: true,
          whitespace: true,
          pattern: '^[a-zA-Z0-9ñÑ. ]*$',
          message: 'Please enter a house/subd'
        }]}
      >
        <Input
          minLength={1}
          maxLength={200}
          placeholder='House/Subd'
          autoComplete='off'
        />
      </Form.Item>

      <Form.Item
        label='Zip Code'
        name={fields[5]}
        rules={[{
          required: true,
          message: 'Please enter a zip code'
        }]}
      >
        <InputNumber
          minLength={1}
          maxLength={10}
          placeholder='Zip Code'
          autoComplete='off'
          className={s.zip}
        />
      </Form.Item>

      <Form.Item
        label='Region'
        name={fields[0]}
        rules={[{
          required: true,
          message: 'Please select a region'
        }]}
      >
        <Select {...region} autoComplete='off' />
      </Form.Item>

      <Form.Item
        label='Province'
        name={fields[1]}
        rules={[{
          required: true,
          message: 'Please select a province'
        }]}
      >
        <Select {...province} autoComplete='off' />
      </Form.Item>

      <Form.Item
        label='Town/City'
        name={fields[2]}
        rules={[{
          required: true,
          message: 'Please select a town/city'
        }]}
      >
        <Select {...towncity} autoComplete='off' />
      </Form.Item>

      <Form.Item
        label='Barangay'
        name={fields[3]}
        rules={[{
          required: true,
          message: 'Please select a barangay'
        }]}
      >
        <Select {...barangay} autoComplete='off' />
      </Form.Item>

      <GoogleMap
        form={form}
        theme={theme}
        values={gmap}
        onChange={setGmap}
        latField={fields[6]}
        lngField={fields[7]}
        reCenter
      />
      <div className={s.mapfill} />
      <br />

      <Form.Item
        name={fields[6]}
        hidden
      >
        <Input />
      </Form.Item>

      <Form.Item
        name={fields[7]}
        hidden
      >
        <Input />
      </Form.Item>
    </>
  );
};

Address.theme = theme;

export default Address;
