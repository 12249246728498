import axios from 'axios';

const profileDocumentsPost = async params => {
  try {
    const response = await axios({
      method: 'post',
      url: '/profile/documents',
      data: params
    });
    return response;
  } catch ({ response }) {
    return response;
    // internal
  }
};

export default profileDocumentsPost;
