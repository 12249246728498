import HelpGuide from './HelpGuide';
import CookiePolicy from './CookiePolicy';
import PrivacyPolicy from './PrivacyPolicy';
import TermsOfService from './TermsOfService';

export const helpGuide = {
  path: '/helpguide',
  handler: props => <HelpGuide {...props} />
};

export const cookiePolicy = {
  path: '/cookiepolicy',
  handler: props => <CookiePolicy {...props} />
};

export const privacyPolicy = {
  path: '/privacypolicy',
  handler: props => <PrivacyPolicy {...props} />
};

export const termsOfService = {
  path: '/termsofservice',
  handler: props => <TermsOfService {...props} />
};
