import { Remarkable } from 'remarkable';
import s from './markdown.module.less';

const Markdown = props => {
  const markdown = new Remarkable();

  return (
    <div className={`${s.md} ${props.className}`} style={props.style}>
      <span
        dangerouslySetInnerHTML={{
          __html: markdown
            .render(props.value)
            .replace(/&lt;br\/&gt;/gi, '<br />')
        }}
      />
    </div>
  );
};

export default Markdown;
