import { useContext, useEffect, useState } from 'react';
import { Form, Card, InputNumber, Button, Typography, message } from 'antd';
import { Context, Erratum, Utils } from 'shookt';
import { ShoppingCartOutlined } from '@ant-design/icons';
import { Variations, Avatar } from '../../components';
import page from 'page';
import s from './market.module.less';
import marketGet from './marketGet';
import marketItemPost from './marketItemPost';
import marketItemDelete from './marketItemDelete';

const MarketForm = ({ item = {}, itemPrice, productId, buy }) => {
  const [state, dispatch] = useContext(Context);
  const [product, setProduct] = useState({ code: '', variations: [], company: {} });
  const [pricing, setPricing] = useState(itemPrice);
  const [noprice, setNoprice] = useState('Select variations');
  const [nosend, setNosend] = useState(false);
  const [form] = Form.useForm();

  const onFinish = async values => {
    if (nosend) {
      const selections = JSON.parse(JSON.stringify(Variations.getVariations(values)).toLowerCase());
      const price = product.pricing.filter(p => {
        let match = true;
        for (const s in selections) {
          if (p.variations[s] !== selections[s]) {
            match = false;
          }
        }
        return match;
      })[0];
      if (price) {
        setPricing(price.unit_price);
      } else {
        setPricing(null);
        setNoprice('No price yet');
      }
      setNosend(false);
      return;
    }
    if (!values.newcart_qty) {
      return dispatch({ upsert: { error: 'Quantity is required' } });
    }
    const payload = {
      product_id: productId,
      variations: Variations.getVariations(values),
      quantity: values.newcart_qty
 
    };
    if (item.id) {
      payload.id = item.id;
    }
    if (buy) {
      dispatch({
        upsert: {
          buy: [{
            ...product.company,
            total: pricing * values.newcart_qty,
            payload,
            items: [{
              product,
              variation_log: Object.keys(payload.variations).map((v, i) => ({ id: i, name: v, value: payload.variations[v] })),
              quantity: values.newcart_qty,
              price: pricing
            }]
          }]
        }
      });
      return page('/checkout');
    }
    const { status } = await marketItemPost(payload);
    if (status === 200) {
      if (item.id) {
        dispatch({ upsert: { reload: true } });
        message.success(`${product.name} updated cart`);
      } else {
        message.success(`${product.name} added to cart`);
      }
      dispatch({ delete: ['drawer'] });
    }
  };

  useEffect(() => {
    (async () => {
      const { status, data } = await marketGet(productId);
      if (status === 200) {
        setProduct(data);
        if (data.pricing.length === 1) {
          setPricing(data.pricing[0].unit_price);
        }
      }
    })();
  }, []);

  return (
    <Form
      form={form}
      layout='vertical'
      onFinish={onFinish}
      initialValues={{ newcart_qty: item.quantity || 1 }}
      onValuesChange={Utils.clearError(dispatch)}
    >
      <Card bordered={false} className={s.nobg} bodyStyle={{ padding: '0 0 20px 0' }}>
        <Card.Meta
          className={s.nobg}
          avatar={<Avatar size={58} user={product} square />}
          title={product.name}
          description={product.company.name}
        />
      </Card>

      <Variations product={product} item={item} onChange={() => { setNosend(true); form.submit(); }} form />

      <Form.Item label='Price'>
        <Typography.Paragraph className={`${s.bigprice} ${pricing ? s.primary : ''}`} type={pricing ? '' : 'secondary'}>
          {pricing ? `₱${Utils.toMonetary(pricing)}` : noprice}
        </Typography.Paragraph>
      </Form.Item>

      <Form.Item
        label='Quantity'
        name='newcart_qty'
        required
      >
        <InputNumber
          placeholder='Quantity'
          maxLength={15}
          minLength={1}
          precision={0}
          min={1}
          controls
        />
      </Form.Item>

      <Erratum />

      <Form.Item>
        <Button
          block
          type='primary'
          htmlType='submit'
          disabled={!pricing || state.loading}
          loading={state.loading}
          icon={<ShoppingCartOutlined />}
        >
          {item.id ? 'Update cart item' : buy ? 'Go to Checkout' : 'Add to cart'}
        </Button>
      </Form.Item>

      <Form.Item>
        {
          item.id && (
            <Button
              block
              loading={state.loading}
              onClick={async () => {
                const { status } = await marketItemDelete(item.id);
                if (status === 200) {
                  dispatch({ upsert: { reload: true } });
                  dispatch({ delete: ['drawer'] });
                }
              }}
            >
              Remove cart item
            </Button>
          )
        }
      </Form.Item>
    </Form>
  );
};

export default MarketForm;
