import { useState, useContext, useEffect } from 'react';
import { Row, Col, Badge, Button, Avatar, Tag } from 'antd';
import { CompanySelect, Avatar as Avatahr, Dashboard, DataList, Header, Ellipsis } from '../../components';
import { Context, Utils } from 'shookt';
import { FaStar } from 'react-icons/fa';
import s from './user.module.less';
import userGet from './userGet';
import UserForm from './UserForm';

const UserList = props => {
  const [state, dispatch] = useContext(Context);
  const [values, setValues] = useState({});
  const [params, setParams] = useState({});
  const [company, setCompany] = useState();
  const toDrawer = Dashboard.toDrawer(dispatch);

  useEffect(() => {
    if (params && params.page) {
      dispatch({ upsert: { loading: true } });
      setTimeout(async () => {
        const { data } = await userGet('all', {
          company: company || 0,
          find: params.find,
          sort: params.sort,
          page: params.page || 1,
          pageSize: state.pageSize
        });
        dispatch({ upsert: { users: data.data || [] } });
        setValues(data);
      }, 200);
    }
  }, [params, company]);

  return (
    <Dashboard>
      <Header
        noTop
        level={3}
        title='Users'
        extra={(
          <Button
            type='primary'
            onClick={toDrawer(<UserForm />, 'Create new user')}
          >
            Add new user
          </Button>
        )}
      />

      <DataList
        values={values}
        storeKey='user'
        onChange={setParams}
        countLabel='users'
        dataSource={state.users}
        rowUrl={item => `/user/${item.id}`}
        extra={CompanySelect.get(state, { allowClear: true, storeKey: 'user', onChange: setCompany })}
        titleHandler={item => {
          return (
            <Ellipsis>
              {Utils.formatName(item)}
            </Ellipsis>
          );
        }}
        avatarHandler={item => {
          let badgeIcon;
          if (item.is_admin) {
            badgeIcon = <FaStar className={s.userstar} />;
          } else if (item.role && item.role.id) {
            badgeIcon = (
              <Avatar
                size={10}
                style={{
                  backgroundColor: `#${item.role.color}`,
                  border: '1px solid #fff'
                }}
              />
            );
          }
          return (
            <Badge
              offset={[-8, 38]}
              count={badgeIcon}
            >
              <Avatahr thumb size={46} user={item} />
            </Badge>
          );
        }}
        bodyHandler={item => {
          let sus = '';
          if (!item.is_enabled) {
            sus = <><Tag className={`no-right-margin ${s.usersus}`} color='error'>SUSPENDED</Tag>&nbsp;</>;
          }
          let userlabel = <Ellipsis type='secondary'>{sus}No role</Ellipsis>;
          if (item.is_admin) {
            userlabel = <Ellipsis code>{sus}Company Admin</Ellipsis>;
          } else if (item.role && item.role.id) {
            userlabel = <Ellipsis>{sus}<span style={{ color: `#${item.role.color}` }}>{item.role.name}</span></Ellipsis>;
          } else if (item.company && !item.company.id) {
            userlabel = <Ellipsis type='secondary'>{sus}Public</Ellipsis>;
          }
          return (
            <Row gutter={[10, 0]} className={s.userlist}>
              <Col xs={24} md={8} lg={5} xl={7}>
                {userlabel}
              </Col>
              <Col xs={0} md={8} lg={8} xl={6}>
                <Ellipsis>{item.mobile || <span className={s.usernone}>No mobile</span>}</Ellipsis>
              </Col>
              <Col xs={0} md={8} lg={11} xl={11}>
                <Ellipsis>{item.email || <span className={s.usernone}>No email</span>}</Ellipsis>
              </Col>
            </Row>
          );
        }}
        sortOptions={[
          { label: 'Newest', value: { column: 'created_at', order: 'desc' } },
          { label: 'Oldest', value: { column: 'created_at', order: 'asc' } },
          { label: 'Updated', value: { column: 'updated_at', order: 'desc' } },
          { label: 'Last name A-Z', value: { column: 'last_name', order: 'asc' } },
          { label: 'Last name Z-A', value: { column: 'last_name', order: 'desc' } },
          { label: 'First name A-Z', value: { column: 'first_name', order: 'asc' } },
          { label: 'First name Z-A', value: { column: 'first_name', order: 'desc' } }
        ]}
      />
    </Dashboard>
  );
};

export default UserList;
