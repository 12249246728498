import page from 'page';
import OrderView from './OrderView';
import OrderList from './OrderList';

export const orderRedirect = {
  path: '/order',
  priv: 'order',
  handler: () => page.redirect('/order/all')
};

export const orderListRoute = {
  path: '/order/all',
  priv: 'order',
  handler: props => <OrderList {...props} />
};

export const orderViewRoute = {
  path: '/order/:id',
  priv: 'order',
  handler: props => <OrderView {...props} />
};
