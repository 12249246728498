import { useState, useContext, useEffect } from 'react';
import { Button, Tag } from 'antd';
import { Context } from 'shookt';
import { CompanySelect, Dashboard, DataList, Header, Ellipsis } from '../../components';
import pricingGet from './pricingGet';
import PricingForm from './PricingForm';

const PricingList = props => {
  const [state, dispatch] = useContext(Context);
  const [values, setValues] = useState({});
  const [params, setParams] = useState({});
  const [company, setCompany] = useState();
  const toDrawer = Dashboard.toDrawer(dispatch);

  useEffect(() => {
    if (params && params.page) {
      dispatch({ upsert: { loading: true } });
      setTimeout(async () => {
        const { data } = await pricingGet('all', {
          company: company || 0,
          find: params.find,
          sort: params.sort,
          page: params.page || 1,
          pageSize: state.pageSize
        });
        dispatch({ upsert: { pricings: data.data || [] } });
        setValues(data);
      }, 200);
    }
  }, [params, company]);

  return (
    <Dashboard>
      <Header
        noTop
        level={3}
        title='Price Groups'
        extra={(
          <Button
            type='primary'
            onClick={toDrawer(<PricingForm />, 'Create new pricing')}
          >
            Add new pricing
          </Button>
        )}
      />

      <DataList
        values={values}
        storeKey='pricing'
        onChange={setParams}
        countLabel='price groups'
        dataSource={state.pricings}
        rowUrl={item => `/pricing/${item.id}`}
        extra={CompanySelect.get(state, { allowClear: true, storeKey: 'pricing', onChange: setCompany })}
        titleHandler={item => (<Ellipsis>{item.name}</Ellipsis>)}
        bodyHandler={item => (
          <Ellipsis type='secondary' className='lisht-content'>
            <Tag color={item.is_enabled ? 'green' : ''}>{item.is_enabled ? 'LIVE' : 'Offline'}</Tag>
            {item.description}
          </Ellipsis>
        )}
        sortOptions={[
          { label: 'Newest', value: { column: 'created_at', order: 'desc' } },
          { label: 'Oldest', value: { column: 'created_at', order: 'asc' } },
          { label: 'Updated', value: { column: 'updated_at', order: 'desc' } }
        ]}
      />
    </Dashboard>
  );
};

export default PricingList;
