import axios from 'axios';

const profilePost = async params => {
  try {
    const response = await axios({
      method: 'post',
      url: '/profile',
      data: params
    });
    return response;
  } catch ({ response }) {
    return response;
    // internal
  }
};

export default profilePost;
