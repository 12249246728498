import { useState } from 'react';
import { Typography, Button, Space, List } from 'antd';
import { Utils } from 'shookt';
import { Variations, Ellipsis, Created, Spacer, Header, Timestamp } from '../../components';
import s from './inventory.module.less';

const InventoryItems = props => {
  const [byVariation, setByVariation] = useState(false);

  let body;
  if (byVariation) {
    const byVari = {};
    if (Array.isArray(props.inventory)) {
      props.inventory.forEach(i => {
        const key = JSON.stringify(Variations.format(i.variations));
        byVari[key] = byVari[key] || { variation_log: i.variation_log, created_at: i.created_at, count: 0 };
        byVari[key].count += i.ledger.current_value;
        const newDate = new Date(i.created_at);
        const currDate = new Date(byVari[key].created_at);
        if (newDate.getTime() > currDate.getTime()) {
          byVari[key].created_at = i.created_at;
        }
      });
    }
    const inventory = [];
    for (const key in byVari) {
      inventory.push(byVari[key]);
    }
    body = (
      <List
        dataSource={inventory}
        className={s.byvariation}
        renderItem={item => (
          <List.Item>
            <List.Item.Meta
              title={(
                <Spacer center block className={s.varideets}>
                  <Space>
                    <Typography.Text type='secondary'>
                      Quantity:
                    </Typography.Text>
                    {Utils.toMonetary(item.count, 0)}
                  </Space>
                  <Created prefix='as of' item={item} />
                </Spacer>
              )}
              description={(
                <Spacer split block>
                  <Variations item={item} noBott view />
                </Spacer>
              )}
            />
          </List.Item>
        )}
      />
    );
  } else {
    body = (
      <List
        dataSource={props.inventory || []}
        renderItem={item => (
          <List.Item>
            <a href={`/inventory/${item.id}`} className={s.block}>
              <List.Item.Meta
                title={(
                  <Spacer center block className={s.varideets}>
                    <Space>
                      <Typography.Text type='secondary'>
                        Stock Id:
                      </Typography.Text>
                      <Ellipsis>
                        {item.code}
                      </Ellipsis>
                    </Space>
                    <Space>
                      <Typography.Text type='secondary'>
                        Expiry:
                      </Typography.Text>
                      <Typography.Text>
                        {item.expiry && (<Timestamp stamp={item.expiry} format='YYYY/MM/DD' />)}
                        {!item.expiry && 'None'}
                      </Typography.Text>
                    </Space>
                    <Space>
                      <Typography.Text type='secondary'>
                        Batch No:
                      </Typography.Text>
                      <Ellipsis>
                        {item.inventory.batch_no}
                      </Ellipsis>
                    </Space>
                    <Space>
                      <Typography.Text type='secondary'>
                        Quantity:
                      </Typography.Text>
                      {Utils.toMonetary(item.ledger.current_value, 0)}
                    </Space>
                    <Created prefix='as of' item={item.ledger} />
                  </Spacer>
                )}
                description={(
                  <Variations item={item} noBott view />
                )}
              />
            </a>
          </List.Item>
        )}
      />
    );
  }

  return (
    <div className={s.stocks}>
      <Header
        bottom={1}
        top={5}
        title={<Typography.Text className={s.label}>Stocks</Typography.Text>}
        extra={<Button onClick={() => setByVariation(!byVariation)}>{byVariation ? 'View by Stock Id' : 'View by Variation'}</Button>}
      />
      {body}
    </div>
  );
};

export default InventoryItems;
