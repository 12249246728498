import CompanyView from './CompanyView';
import CompanyList from './CompanyList';

export const companyAdmin = {
  path: '/company',
  auth: true,
  handler: props => <CompanyView {...props} />
};

export const companyListRoute = {
  path: '/company/all',
  priv: 'company',
  handler: props => <CompanyList {...props} />
};

export const companyViewRoute = {
  path: '/company/:id',
  priv: 'company',
  handler: props => <CompanyView {...props} />
};
