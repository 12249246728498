
import { useState } from 'react';
import { Captcha, Erratum } from 'shookt';
import { Typography, Result } from 'antd';
import { Panel } from '../../components';
import recoverPut from './recoverPut';
import Reclaim from './Reclaim';

const Recover = props => {
  const [done, setDone] = useState('');
  const [message, setMessage] = useState(Erratum.internal);

  const onChange = async () => {
    const { status, data } = await recoverPut({ code: props.params.code });
    if (status === 200) {
      return setDone('claim');
    }
    setMessage(data.message);
    setDone('fail');
  };

  let body = (
    <>
      <Typography.Paragraph className='text-center'>
        Please verify you are not a robot.
      </Typography.Paragraph>
      <Captcha onChange={onChange} />
      <Erratum />
    </>
  );

  if (done === 'fail') {
    body = (
      <Result
        status='error'
        title='Recovery Failed'
        subTitle={message}
      />
    );
  }

  if (done === 'claim') {
    body = <Reclaim code={props.params.code} />;
  }

  return (
    <Panel style={{ maxWidth: 345 }}>
      {body}
    </Panel>
  );
};

export default Recover;
