import { useContext, useEffect, useState } from 'react';
import { Row, Col, Skeleton, List, Input, Select, Pagination, Button } from 'antd';
import { Context, Utils } from 'shookt';
import RenderItem from './RenderItem';
import s from './datalist.module.less';

const DataList = ({
  onClick, onChange, spinning, storeKey, dataSource, values, countLabel,
  hasAvatar, noBottBox, noPadding, noHover, searchPlaceholder,
  avatarHandler, titleHandler, bodyHandler, sortOptions,
  bottbox1Handler, bottbox2Handler, rowUrl, renderItem,
  bottbox3Handler, bottbox4Handler, extra, topPager
}) => {
  const [state] = useContext(Context);
  const [page, setPage] = useState(1);
  const [sort, setSort] = useState([]);
  const [find, setFind] = useState('');
  const [findout, setFindout] = useState(null);

  if (!sortOptions) {
    sortOptions = [
      { label: 'Newest', value: { column: 'created_at', order: 'desc' } },
      { label: 'Oldest', value: { column: 'created_at', order: 'asc' } }
    ];
  }

  sortOptions = sortOptions.map(op => {
    return {
      value: JSON.stringify(op.value),
      label: op.label
    };
  });

  const upChange = (vals = {}) => {
    Utils.cleanQuery(vals, sortOptions);
    if ('sort' in vals) {
      storeKey && sessionStorage.setItem(`${storeKey}_sort`, JSON.stringify(vals.sort));
      setSort(vals.sort);
    }
    if ('page' in vals) {
      storeKey && sessionStorage.setItem(`${storeKey}_page`, vals.page);
      setPage(vals.page);
    }
    if ('find' in vals) {
      storeKey && sessionStorage.setItem(`${storeKey}_find`, vals.find);
      storeKey && sessionStorage.setItem(`${storeKey}_page`, 1);
      setFind(vals.find);
    }
    if (findout) {
      clearTimeout(findout);
    }
    setFindout(setTimeout(() => {
      onChange({
        ...{ find, sort, page },
        ...vals
      });
    }, 250));
  };

  const sortVal = sortOptions.filter(op => op.value === JSON.stringify(sort[0]));

  useEffect(() => {
    const payload = {};
    if (storeKey) {
      payload.page = sessionStorage.getItem(`${storeKey}_page`);
      payload.find = sessionStorage.getItem(`${storeKey}_find`);
      payload.sort = sessionStorage.getItem(`${storeKey}_sort`);
      if (payload.sort) {
        try {
          payload.sort = JSON.parse(payload.sort);
        } catch (err) {
          payload.sort = [];
        }
      }
    }
    payload.page = payload.page || 1;
    payload.find = payload.find || '';
    payload.sort = payload.sort || [];
    Utils.cleanQuery(payload, sortOptions);
    setSort(payload.sort);
    setFind(payload.find);
    setPage(payload.page);
    onChange(payload);
  }, []);

  let loading = state.loading;
  if (dataSource === undefined) {
    loading = true;
  } else if (dataSource) {
    loading = false;
  }
  if (spinning !== undefined) {
    loading = spinning;
  }

  let searchSpan = 18;
  if (extra) {
    searchSpan = 12;
  }

  return (
    <>
      <Row gutter={[10, 10]} justify='space-between' align='bottom'>
        <Col xs={24} lg={24} xl={14}>
          <Row gutter={[10, 10]}>
            <Col xs={{ span: 24, order: 1 }} md={{ span: searchSpan, order: 1 }} xl={11} xxl={12}>
              <Input.Search
                placeholder={searchPlaceholder || 'Search'}
                onSearch={val => upChange({ find: val, page: 1 })}
                onChange={e => upChange({ find: e.currentTarget.value, page: 1 })}
                maxLength={100}
                value={find}
                allowClear
              />
            </Col>
            <Col xs={{ span: 10, order: 3 }} sm={{ span: 15, order: 3 }} md={{ span: 6, order: 2 }}>
              <Select
                placeholder='Sort by'
                className={s.sort}
                onChange={val => upChange({ sort: val ? [JSON.parse(val)] : [], page: 1 })}
                options={sortOptions}
                value={sortVal[0] && sortVal[0].label}
                allowClear
              />
            </Col>
            {extra && (<Col xs={{ span: 24, order: 2 }} md={{ span: 6, order: 3 }} xl={7} xxl={6}>{extra}</Col>)}
            <Col xs={{ span: 14, order: 4 }} sm={{ span: 9, order: 4 }} md={0}>
              <Pagination
                itemRender={(current, type, originalElement) => {
                  if (type === 'page') {
                    return <>{current}</>;
                  }
                  if (type === 'prev') {
                    return <Button>Previous</Button>;
                  }
                  if (type === 'next') {
                    return <Button>Next</Button>;
                  }
                  return originalElement;
                }}
                showTitle={false}
                showSizeChanger={false}
                className={s.paginationMobile}
                onChange={page => upChange({ page })}
                pageSize={values.pageSize || 1}
                current={values.page}
                total={values.total}
                size='small'
              />
            </Col>
          </Row>
        </Col>
        <Col xs={0} md={24} lg={24} xl={10}>
          {
            topPager || (
              <Pagination
                showTitle={false}
                showSizeChanger={false}
                onChange={page => upChange({ page })}
                className={s.pagination}
                pageSize={values.pageSize || 1}
                current={values.page}
                total={values.total}
                size='small'
              />
            )
          }
        </Col>
      </Row>
      <List
        className={s.list}
        itemLayout='vertical'
        dataSource={dataSource}
        renderItem={renderItem || (item => (
          <RenderItem
            item={item}
            rowUrl={rowUrl}
            noHover={noHover}
            noPadding={noPadding}
            noBottBox={noBottBox}
            hasAvatar={hasAvatar}
            onRowClick={onClick}
            bodyHandler={bodyHandler}
            titleHandler={titleHandler}
            avatarHandler={avatarHandler}
            bottbox1Handler={bottbox1Handler}
            bottbox2Handler={bottbox2Handler}
            bottbox3Handler={bottbox3Handler}
            bottbox4Handler={bottbox4Handler}
          />
        ))}
        pagination={{
          position: 'bottom',
          showTitle: false,
          showTotal: state.screens && (state.screens.lg || state.screens.xl || state.screens.xxl) && (total => `Total ${Utils.toMonetary(total, 0)} ${countLabel || 'items'}`),
          showSizeChanger: false,
          onChange: page => upChange({ page }),
          className: s.pagination,
          pageSize: values.pageSize || 1,
          current: values.page,
          total: values.total,
          size: 'small'
        }}
        loading={{
          spinning: loading,
          indicator: (
            <>
              <Skeleton active />
              <Skeleton active />
              <Skeleton active />
              <Skeleton active />
              <Skeleton active />
              <Skeleton active />
            </>
          )
        }}
      />
    </>
  );
};

export default DataList;
