import { useContext, useEffect } from 'react';
import { Typography, Button, Empty, Drawer, Layout, Skeleton, Spin, Space, Menu, Row, Col } from 'antd';
import { LoadingOutlined, PoweroffOutlined, HeartOutlined, ShoppingCartOutlined, ShoppingOutlined } from '@ant-design/icons';
import { AiOutlineClose } from 'react-icons/ai';
import { FiChevronLeft } from 'react-icons/fi';
import { FaPowerOff } from 'react-icons/fa';
import { Context, Utils } from 'shookt';
import { useWindowSize } from 'rooks';
import { Header, Avatar } from '..';
import { state as reset } from '../../App';
import page from 'page';
import s from './dashboard.module.less';
import menuItems from './menuItems';
import CategoryMenu from './CategoryMenu';
import authDelete from '../../pages/auth/authDelete';

const rowProps = {
  justify: 'center',
  align: 'middle'
};

const Dashboard = ({ back, status, viewspin, children, style = {} }) => {
  const [state, dispatch] = useContext(Context);
  const { innerWidth } = useWindowSize();
  const user = state.user || {};
  const menu = menuItems(state);
  const logout = async () => {
    await authDelete();
    const r = [...Object.keys(reset), 'loading', 'content', 'routes', 'params', 'query', 'error', 'page', 'pagecall'];
    dispatch({ delete: Object.keys(state).filter(s => !r.includes(s)) });
    sessionStorage.clear();
    page.redirect('/');
  };

  if (user.type) {
    menu.push({
      icon: user.type === 'public' ? <PoweroffOutlined /> : <FaPowerOff />,
      label: <a href='/' onClick={logout}>Sign out</a>
    });
  }

  let size = 378;
  if (innerWidth > 2000) {
    size = 490;
  }
  if (innerWidth > 2100) {
    size = 590;
  }
  if (innerWidth > 2190) {
    size = 650;
  }
  if (innerWidth > 2250) {
    size = 736;
  }

  const onDrawerClose = () => {
    dispatch({ delete: ['drawer'] });
  };

  const onKeyDown = e => {
    switch (e.keyCode) {
      case 27:
        if (state.drawer) {
          dispatch({ delete: ['drawer'] });
        }
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (!state.drawer) {
      dispatch({ delete: ['drawerTitle'] });
    }
    if ((!state.drawerReal || innerWidth > 1860) &&
      state.drawerKey !== `${state.drawer && state.drawer.key}${state.drawerTitle}`) {
      dispatch({ upsert: { drawerKey: `${state.drawer && state.drawer.key}${state.drawerTitle}`, drawerReal: state.drawer } });
      dispatch({ delete: ['error', 'good'] });
    } else {
      dispatch({ delete: ['error', 'good', 'drawerReal'] });
      setTimeout(() => {
        dispatch({ upsert: { drawerReal: state.drawer } });
      }, 100);
    }
    // TODO: on drawer close, show success message if bigscreen
    // TODO: prevent blinking of drawer on page change
  }, [`${state.drawer && state.drawer.key}${state.drawerTitle}`]);
  // TODO back should check if theres history

  useEffect(() => {
    document.addEventListener('keydown', onKeyDown);
    return () => {
      document.removeEventListener('keydown', onKeyDown);
    };
  }, []);

  const backBtn = back && (
    <Button shape='circle' icon={<FiChevronLeft />} onClick={() => page(back)} />
  );
  let body = children;
  if (viewspin && status !== 200) {
    if (status === 404) {
      body = <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;
    } else {
      body = <Skeleton active />;
    }
    body = (<><Header noTop level={3} title={backBtn} /><br />{body}</>);
  }

  return (
    <>
      <Layout>
        <Layout.Sider
          theme='light'
          breakpoint='lg'
          collapsedWidth={0}
          width={innerWidth > 1440 ? 260 : 200}
        >
          <Row className={s.row} {...rowProps}>
            <Col>
              <a href='/'>
                <img
                  src={state.logo}
                  alt={state.appName}
                  className={s.logo}
                />
              </a>
            </Col>
          </Row>
          <Row className={s.row} {...rowProps}>
            <Col xs={24} className={!user.type && 'text-center'}>
              {
                !user.type && (
                  <div className={s.registerlogin}>
                    <Space align='center' size='middle'>
                      <Button type='primary'>
                        <a href='/register'>
                          Register
                        </a>
                      </Button>
                      <Button>
                        <a href='/login'>
                          Login
                        </a>
                      </Button>
                    </Space>
                  </div>
                )
              }
              {
                user.type && (
                  <a href='/profile' className={s.profile}>
                    <Space
                      size='small'
                      align='center'
                      direction='horizontal'
                    >
                      <Avatar thumb />
                      <Typography.Paragraph className={`no-bott-margin ${s.deets}`} ellipsis>
                        {Utils.formatName(user, true)}.<br />
                        <Typography.Text type='secondary'>
                          {Utils.toTitleCase(user.type)}
                        </Typography.Text>
                      </Typography.Paragraph>
                    </Space>
                  </a>
                )
              }
            </Col>
          </Row>
          {
            user.type === 'public' && (
              <Menu
                mode='inline'
                selectedKeys={[state.page]}
                inlineIndent={15}
                items={[
                  {
                    key: '/mycart',
                    icon: <ShoppingCartOutlined />,
                    label: <a href='/mycart'>My cart</a>
                  },
                  {
                    key: '/myorders',
                    icon: <ShoppingOutlined />,
                    label: <a href='/myorders'>My orders</a>
                  },
                  {
                    key: '/myfavorites',
                    icon: <HeartOutlined />,
                    label: <a href='/myfavorites'>My favorites</a>
                  }
                ]}
              />
            )
          }
          {(!user.type || user.type === 'public') && <CategoryMenu />}
          {
            user.type === 'manager' &&
            user.company.id && (
              <Row {...rowProps}>
                <Col xs={24}>
                  <a href='/company' className={s.company}>
                    <Space
                      size='small'
                      align='center'
                      direction='horizontal'
                    >
                      <Avatar user={user.company} thumb />
                      <Typography.Paragraph className={`no-bott-margin ${s.deets}`} ellipsis>
                        {user.company.name}<br />
                        <Typography.Text type='secondary'>
                          Distributor
                        </Typography.Text>
                      </Typography.Paragraph>
                    </Space>
                  </a>
                </Col>
              </Row>
            )
          }
          <Menu
            mode='inline'
            selectedKeys={[state.page]}
            inlineIndent={15}
            items={menu}
          />
          <Row className={s.row} {...rowProps}>
            <Col xs={24}>
              <div className={s.foot}>
                <a href='/helpguide'>Help Guide</a><br />
                <a href='/termsofservice'>Terms of Service</a><br />
                <a href='/privacypolicy'>Privacy Policy</a><br />
                <a href='/cookiepolicy'>Cookie Policy</a><br /><br />
                &copy;{new Date().getFullYear()} {state.appName}
                <br />
                    {state.APP_VERSION}
              </div>
            </Col>
          </Row>
        </Layout.Sider>

        <Layout className={s.layout}>
          <Layout.Content>
            <div className={s.main} style={style}>
              {body}
            </div>
          </Layout.Content>
        </Layout>
      </Layout>

      <Drawer
        width={size}
        mask={innerWidth < 577}
        size={innerWidth > 2250 ? 'large' : 'default'}
        open={innerWidth > 1860 ? true : (state.drawerReal)}
        onClose={onDrawerClose}
        closable={false}
        className='shkt-drawer'
      >
        <Header
          noTop
          className={s.drawerhead}
          title={(
            <>
              {
                state.drawerTitle && (
                  <Button
                    shape='circle'
                    onClick={onDrawerClose}
                    icon={<AiOutlineClose />}
                  />
                )
              }
              <Typography.Title className={`no-bott-margin ${s.drawertitle}`} level={5} ellipsis>
                &nbsp;{state.drawerTitle}
              </Typography.Title>
            </>
          )}
        />
        {state.drawerReal}
      </Drawer>

      <div className={s.spin}>
        <Spin
          spinning={state.loading}
          indicator={<LoadingOutlined className={s.spinner} />}
        />
      </div>
    </>
  );
};

Dashboard.toDrawer = dispatch => {
  return (component, title) => {
    return () => {
      dispatch({
        upsert: {
          drawer: component,
          drawerTitle: title
        }
      });
    };
  };
};

export default Dashboard;
