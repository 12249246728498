import { useContext } from 'react';
import { Context } from 'shookt';
import { Result } from 'antd';
import { Dashboard, Panel } from '../../components';

const NotFound = () => {
  const [state] = useContext(Context);
  const msg = (
    <Result
      icon={<></>}
      title='Page Not Found'
      subTitle='Sorry, the page you are trying to visit does not exist.'
    />
  );
  let body = <Panel>{msg}</Panel>;
  if (state.user) {
    body = <Dashboard>{msg}</Dashboard>;
  }

  return body;
};

export default NotFound;
