import { useContext, useEffect, useState } from 'react';
import { Form, Input, Button, AutoComplete } from 'antd';
import { Context, ImgUpload, Erratum, Mobile, Utils } from 'shookt';
import { AiOutlineMail } from 'react-icons/ai';
import { FaImage } from 'react-icons/fa';
import page from 'page';
import companyGet from './companyGet';
import companyPost from './companyPost';

const CompanyForm = props => {
  const [state, dispatch] = useContext(Context);
  const [phone, setPhone] = useState('');
  const [avatar, setAvatar] = useState('');
  const [form] = Form.useForm();
  const canModify = state.user.type === 'admin' || state.user.type === 'manager';

  const onFinish = async values => {
    const payload = {
      tier: values.newcomp_tier,
      name: values.newcomp_name.trim(),
      description: values.newcomp_description.trim()
    };
    if (canModify && values.newcomp_email) {
      payload.email = values.newcomp_email;
    }
    if (canModify && phone) {
      payload.mobile = phone;
    }
    if (payload.tier) {
      payload.tier = payload.tier.trim();
    }
    if (props.id) {
      payload.id = props.id;
    }
    if (avatar && avatar.startsWith('data:')) {
      payload.avatar = avatar;
    }
    const { status, data } = await companyPost(payload);
    if (status === 200) {
      if (props.id) {
        dispatch({ upsert: { reload: true } });
      } else {
        page(`/company/${data.id}`);
      }
      dispatch({ delete: ['drawer'] });
    }
  };

  useEffect(() => {
    (async () => {
      if (props.id) {
        const { status, data } = await companyGet(props.id);
        if (status === 200) {
          setAvatar(data.avatar);
          form.setFieldsValue({
            newcomp_name: data.name,
            newcomp_description: data.description,
            newcomp_tier: data.tier
          });
          if (canModify) {
            if (data.mobile) {
              setPhone(data.mobile);
            }
            form.setFieldsValue({
              newcomp_email: data.email
            });
          }
        }
      }
    })();
  }, []);
  // TODO if not edit, show user add after create
  // TODO user create for this includes mobile and email and simple

  return (
    <Form
      form={form}
      layout='vertical'
      onFinish={onFinish}
      onValuesChange={Utils.clearError(dispatch)}
    >
      <Form.Item
        label='Distributor name'
        name='newcomp_name'
        rules={[{
          required: true,
          whitespace: true,
          pattern: '^[a-zA-Z0-9ñÑ. ]*$',
          message: 'Please enter a distributor name'
        }]}
      >
        <Input
          minLength={1}
          maxLength={100}
          placeholder='Distributor name'
        />
      </Form.Item>

      <Form.Item
        label='Description'
        name='newcomp_description'
        rules={[{
          max: 300,
          required: true,
          whitespace: true,
          message: 'Please enter a description'
        }]}
      >
        <Input.TextArea placeholder='Description' maxLength={300} autoSize={{ minRows: 2 }} showCount />
      </Form.Item>

      {canModify && (
        <>
          <Form.Item
            label='Contact Email (Public)'
            name='newcomp_email'
            rules={[{
              type: 'email',
              message: 'Please enter a valid email'
            }]}
          >
            <Input
              prefix={<AiOutlineMail />}
              minLength={10}
              maxLength={80}
              placeholder='Email'
            />
          </Form.Item>

          <Form.Item label='Contact Mobile (Public)'>
            <Mobile value={phone} onPhone={setPhone} country='ph' className='ant-input' noAutoFocus />
          </Form.Item>
        </>
      )}

      <Form.Item
        label='Tier'
        name='newcomp_tier'
      >
        <AutoComplete
          placeholder='Tier'
          filterOption={(inputValue, option) => option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1}
          options={[{ value: 'I' }, { value: 'II' }, { value: 'III' }]}
        />
      </Form.Item>

      <Form.Item className='text-center'>
        <ImgUpload
          message={`${props.id ? 'Update' : 'Attach'} distributor logo`}
          imgStore={avatar}
          imgSet={setAvatar}
          icon={<FaImage />}
          size={128}
          round
        />
      </Form.Item>

      <Erratum />

      <Form.Item>
        <Button
          block
          type='primary'
          htmlType='submit'
          disabled={state.loading}
          loading={state.loading}
        >
          {props.id ? 'Save changes' : 'Add new distributor'}
        </Button>
      </Form.Item>
    </Form>
  );
};

export default CompanyForm;
