import { useContext, useEffect, useState } from 'react';
import { Row, Col, Form, Input, Button, Switch, Space, Tag, Typography } from 'antd';
import { Context, Erratum, Utils } from 'shookt';
import { ColorPicker, CompanySelect, Ellipsis } from '../../components';
import { authenticate } from '../../session';
import page from 'page';
import roleGet from './roleGet';
import rolePost from './rolePost';

const RoleForm = props => {
  const [state, dispatch] = useContext(Context);
  const [name, setName] = useState('Role name');
  const [color, setColor] = useState('cccccc');
  const [privs, setPrivs] = useState([]);
  const [form] = Form.useForm();

  const onFinish = async values => {
    const privVals = [];
    for (const key in values) {
      if ([
        'newrole_name',
        'newrole_description',
        'newrole_company'
      ].indexOf(key) === -1) {
        if (values[key]) {
          privVals.push(key);
        }
      }
    }
    if (!privVals.length) {
      return dispatch({ upsert: { error: 'Role must have at least one privilege.' } });
    }
    const payload = {
      name: values.newrole_name.trim(),
      description: values.newrole_description.trim(),
      privileges: privVals
    };
    if (values.newrole_company) {
      payload.company_id = values.newrole_company;
    }
    if (props.id) {
      payload.id = props.id;
    }
    if (color) {
      payload.color = color;
    }
    const { status, data } = await rolePost(payload);
    if (status === 200) {
      if (props.id) {
        dispatch({ upsert: { reload: true } });
      } else {
        page(`/role/${data.id}`);
      }
      dispatch({ delete: ['drawer'] });
    }
  };

  useEffect(() => {
    (async () => {
      const data = await authenticate(dispatch);
      setPrivs(Object.keys(data.privileges).map(p => ({
        code: p,
        description: data.privileges[p]
      })));

      if (props.id) {
        const { status, data } = await roleGet(props.id);
        if (status === 200) {
          setName(data.name);
          setColor(data.color);
          form.setFieldsValue({
            newrole_company: data.company_id,
            newrole_name: data.name,
            newrole_description: data.description
          });
          for (const priv in data.privileges) {
            form.setFieldsValue({ [priv]: true });
          }
        }
      }
    })();
  }, []);

  const privileges = [];
  privs.forEach(p => {
    privileges.push(
      <Form.Item key={p.code}>
        <Space>
          <Form.Item name={p.code} valuePropName='checked' noStyle>
            <Switch />
          </Form.Item>
          <Typography.Text>
            &nbsp; {p.description}
          </Typography.Text>
        </Space>
      </Form.Item>
    );
  });

  return (
    <Form
      form={form}
      layout='vertical'
      onFinish={onFinish}
      onValuesChange={Utils.clearError(dispatch)}
    >
      <CompanySelect name='newrole_company' />

      <Row gutter={[15, 0]}>
        <Col xs={14}>
          <Form.Item
            label='Role name'
            name='newrole_name'
            rules={[{
              required: true,
              whitespace: true,
              pattern: '^[a-zA-Z0-9ñÑ. ]*$',
              message: 'Please enter a role name'
            }]}
          >
            <Input
              minLength={1}
              maxLength={100}
              placeholder='Role name'
              onChange={e => setName(e.currentTarget.value)}
            />
          </Form.Item>
        </Col>

        <Col xs={10}>
          <Form.Item label='Role color'>
            <Ellipsis>
              <ColorPicker value={`#${color}`} onChange={setColor}>
                <Tag color={`#${color}`} className='no-right-margin'>
                  {name}
                </Tag>
              </ColorPicker>
            </Ellipsis>
          </Form.Item>
        </Col>
      </Row>

      <Form.Item
        label='Description'
        name='newrole_description'
        rules={[{
          max: 300,
          required: true,
          whitespace: true,
          message: 'Please enter a description'
        }]}
      >
        <Input.TextArea placeholder='Description' maxLength={300} autoSize={{ minRows: 2 }} showCount />
      </Form.Item>

      {privileges}
      <Erratum />

      <Form.Item>
        <Button
          block
          type='primary'
          htmlType='submit'
          disabled={state.loading}
          loading={state.loading}
        >
          {props.id ? 'Save changes' : 'Add new role'}
        </Button>
      </Form.Item>
    </Form>
  );
};

export default RoleForm;
