import { useContext, useEffect } from 'react';
import { Context, Erratum, Utils } from 'shookt';
import { Row, Col, Form, Input, Select, Button, DatePicker } from 'antd';
import { Timestamp } from '../../components';
import { authenticate } from '../../session';
import profilePost from './profilePost';

const ProfileForm = props => {
  const [state, dispatch] = useContext(Context);
  const [form] = Form.useForm();

  const onFinish = async values => {
    const { status } = await profilePost({
      first_name: values.profedit_first_name.trim(),
      middle_name: values.profedit_middle_name.trim(),
      last_name: values.profedit_last_name.trim(),
      religion: values.profedit_religion.trim(),
      birthdate: values.profedit_birthdate.format('YYYY-MM-DD'),
      civil_status: values.profedit_civil_status,
      sex: values.profedit_sex
    });
    if (status === 200) {
      await authenticate(dispatch);
      dispatch({ delete: ['drawer'] });
    }
  };

  useEffect(() => {
    (async () => {
      const data = await authenticate(dispatch);
      if (data) {
        form.setFieldsValue({
          profedit_first_name: Utils.toTitleCase(data.first_name),
          profedit_middle_name: Utils.toTitleCase(data.middle_name),
          profedit_last_name: Utils.toTitleCase(data.last_name),
          profedit_birthdate: data.birthdate ? Timestamp.dayjs(data.birthdate) : null,
          profedit_religion: Utils.toTitleCase(data.religion),
          profedit_civil_status: data.civil_status,
          profedit_sex: data.sex
        });
      }
    })();
  }, []);

  return (
    <Form
      form={form}
      layout='vertical'
      onFinish={onFinish}
      onValuesChange={Utils.clearError(dispatch)}
    >
      <Form.Item
        label='First name'
        name='profedit_first_name'
        hasFeedback
        rules={[{
          required: true,
          whitespace: true,
          pattern: '^[a-zA-ZñÑ. ]*$',
          message: 'Please enter your first name'
        }]}
      >
        <Input
          minLength={1}
          maxLength={100}
          placeholder='First name'
        />
      </Form.Item>

      <Form.Item
        label='Middle name'
        name='profedit_middle_name'
        hasFeedback
        rules={[{
          required: true,
          whitespace: true,
          pattern: '^[a-zA-ZñÑ. ]*$',
          message: 'Please enter your middle name'
        }]}
      >
        <Input
          minLength={1}
          maxLength={100}
          placeholder='Middle name'
        />
      </Form.Item>

      <Form.Item
        label='Last name'
        name='profedit_last_name'
        hasFeedback
        rules={[{
          required: true,
          whitespace: true,
          pattern: '^[a-zA-ZñÑ. ]*$',
          message: 'Please enter your last name'
        }]}
      >
        <Input
          minLength={1}
          maxLength={100}
          placeholder='Last name'
        />
      </Form.Item>

      <Row gutter={[20, 10]}>
        <Col xs={12}>
          <Form.Item
            label='Birthday'
            name='profedit_birthdate'
            rules={[{
              required: true,
              message: 'Please enter your birthday'
            }]}
          >
            <DatePicker placeholder='Birthday' format='YYYY/MM/DD' />
          </Form.Item>

          <Form.Item
            label='Civil Status'
            name='profedit_civil_status'
            rules={[{
              required: true,
              message: 'Please select your civil status'
            }]}
          >
            <Select placeholder='Civil Status'>
              <Select.Option value='single'>Single</Select.Option>
              <Select.Option value='married'>Married</Select.Option>
              <Select.Option value='divorced'>Divorced</Select.Option>
              <Select.Option value='widowed'>Widowed</Select.Option>
            </Select>
          </Form.Item>
        </Col>

        <Col xs={12}>
          <Form.Item
            label='Sex'
            name='profedit_sex'
            rules={[{
              required: true,
              message: 'Please select your sex'
            }]}
          >
            <Select placeholder='Sex'>
              <Select.Option value='male'>Male</Select.Option>
              <Select.Option value='female'>Female</Select.Option>
            </Select>
          </Form.Item>

          <Form.Item
            label='Religion'
            name='profedit_religion'
            rules={[{
              whitespace: true,
              pattern: '^[a-zA-ZñÑ. ]*$',
              message: 'Please enter a valid religion'
            }]}
          >
            <Input
              minLength={1}
              maxLength={200}
              placeholder='Religion'
            />
          </Form.Item>
        </Col>
      </Row>

      <Erratum />

      <Form.Item>
        <Button
          block
          type='primary'
          htmlType='submit'
          disabled={state.loading}
          loading={state.loading}
        >
          Save changes
        </Button>
      </Form.Item>
    </Form>
  );
};

export default ProfileForm;
