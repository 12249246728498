import { Result } from 'antd';
import { Dashboard } from '../../components';

const MyOrderView = props => {
  return (
    <Dashboard>
      <Result
        status='warning'
        title='Under construction'
        extra={<>This page is still a work in progress.</>}
      />
    </Dashboard>
  );
};

export default MyOrderView;
