import { useContext, useState } from 'react';
import { Form, Input, Button, Select, Typography } from 'antd';
import { Context, Erratum, Utils } from 'shookt';
import orderGet from './orderGet';
import orderPost from './orderPost';

const OrderForm = props => {
  const [state, dispatch] = useContext(Context);
  const [type, setType] = useState('order');
  const [form] = Form.useForm();

  const onFinish = async values => {
    const { status } = await orderPost({
      id: props.order.id,
      status: values.orderform_status,
      remarks: values.orderform_remarks,
      type
    });
    if (status === 200) {
      dispatch({ upsert: { reload: true } });
      dispatch({ delete: ['drawer'] });
    }
  };

  return (
    <Form
      form={form}
      layout='vertical'
      onFinish={onFinish}
      onValuesChange={Utils.clearError(dispatch)}
    >
      <Typography.Paragraph>
        <Typography.Text type='secondary'>
          Order Id
        </Typography.Text>
        <br />
        {props.order.code}
      </Typography.Paragraph>

      <Form.Item label='Type'>
        <Select
          value={type}
          onChange={setType}
          options={[
            { label: 'Order', value: 'order' },
            { label: 'Delivery', value: 'delivery' },
            { label: 'Payment', value: 'payment' }
          ]}
        />
      </Form.Item>

      <Form.Item
        label='Status'
        name='orderform_status'
        rules={[{
          required: true,
          message: 'Please select a new status'
        }]}
      >
        <Select
          placeholder='Status'
          options={orderGet.typeStatus[type]}
        />
      </Form.Item>

      <Form.Item
        label='Remarks'
        name='orderform_remarks'
        rules={[{
          max: 300,
          required: true,
          whitespace: true,
          message: 'Please enter a remarks'
        }]}
      >
        <Input.TextArea placeholder='Remarks' maxLength={300} autoSize={{ minRows: 2 }} showCount />
      </Form.Item>

      <Erratum />

      <Form.Item>
        <Button
          block
          type='primary'
          htmlType='submit'
          disabled={state.loading}
          loading={state.loading}
        >
          Save new status
        </Button>
      </Form.Item>
    </Form>
  );
};

export default OrderForm;
