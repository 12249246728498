import axios from 'axios';

const literaryGet = async id => {
  try {
    const response = await axios({
      method: 'get',
      url: `/literary/${id}`
    });
    return response;
  } catch ({ response }) {
    return response;
    // internal
  }
};

export default literaryGet;
