import axios from 'axios';

const categoryGet = async hasNew => {
  try {
    const response = await axios({
      method: 'get',
      url: '/category'
    });
    if (response.status === 200) {
      response.objData = {};
      response.data.forEach(c => {
        response.objData[c.id] = {
          ...c,
          children: [],
          ancestors: []
        };
      });
      response.data.forEach(c => {
        let hasParent = c.parent_id;
        while (hasParent) {
          const parent = response.objData[hasParent];
          hasParent = parent.parent_id;
          response.objData[c.id].ancestors.push({ ...parent });
        }
        response.objData[c.id].ancestors.reverse();
        if (c.parent_id) {
          response.objData[c.parent_id].children.push({ ...c });
        }
      });

      const map = {};
      const roots = [];
      if (hasNew) {
        roots.push({ key: 'new' });
      }
      response.data.forEach((item, i) => {
        const parentId = item.id;
        map[parentId] = i;
        item.key = item.id;
        item.value = item.id;
        item.title = item.name;
        const children = [];
        if (hasNew) {
          children.push({
            parent_id: parentId,
            key: `new${parentId}`
          });
        }
        item.children = children;
      });
      response.data.forEach(item => {
        if (item.parent_id) {
          response.data[map[item.parent_id]].children.push(item);
        } else {
          roots.push(item);
        }
      });
      response.data = roots;
    }
    return response;
  } catch ({ response }) {
    return response;
    // internal
  }
};

export default categoryGet;
