import { useState, useContext, useEffect } from 'react';
import { Context, ImgUpload, Erratum, Utils } from 'shookt';
import { Form, Button } from 'antd';
import { FaUserAlt } from 'react-icons/fa';
import { authenticate } from '../../session';
import profilePost from './profilePost';

const ProfilePhoto = props => {
  const [state, dispatch] = useContext(Context);
  const [avatar, setAvatar] = useState('');

  const onFinish = async () => {
    if (!avatar) {
      return dispatch({ upsert: { error: 'No photo selected.' } });
    }
    if (avatar === state.user.avatar) {
      return dispatch({ delete: ['drawer'] });
    }
    const { status } = await profilePost({ avatar });
    if (status === 200) {
      await authenticate(dispatch);
      dispatch({ delete: ['drawer'] });
    }
  };

  useEffect(() => {
    setAvatar(state.user.avatar);
  }, []);

  return (
    <Form
      layout='vertical'
      onFinish={onFinish}
      onValuesChange={Utils.clearError(dispatch)}
    >
      <Form.Item className='text-center'>
        <ImgUpload
          message='Browse'
          imgStore={avatar}
          imgSet={setAvatar}
          icon={<FaUserAlt />}
          size={256}
          round
        />
      </Form.Item>

      <Erratum className='text-center' />

      <Form.Item>
        <Button
          block
          type='primary'
          htmlType='submit'
          disabled={state.loading}
          loading={state.loading}
        >
          Save profile photo
        </Button>
      </Form.Item>
    </Form>
  );
};

export default ProfilePhoto;
