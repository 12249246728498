import { useContext, useEffect, useState } from 'react';
import { Button, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { MdRefresh, MdKeyboardArrowLeft } from 'react-icons/md';
import { Context } from 'shookt';
import s from './market.module.less';

const MarketButton = props => {
  const [state, dispatch] = useContext(Context);
  const [loadout, setLoadout] = useState(null);
  const paramsObj = JSON.parse(state.params);
  const back = () => window.history.back();
  const reload = () => {
    dispatch({ upsert: { rotate: true } });
    if (loadout) {
      clearTimeout(loadout);
    }
    setLoadout(setTimeout(() => {
      dispatch({ upsert: { reload: true } });
    }, 500));
  };
  const before = <MdKeyboardArrowLeft className={s.icon} />;
  const refresh = (
    <div className={s.icon}>
      <MdRefresh className={(state.rotate || state.loading) ? 'anticon-spin' : ''} />
    </div>
  );

  useEffect(() => {
    if (state.loading) {
      dispatch({ upsert: { rotate: true } });
      setTimeout(() => {
        dispatch({ delete: ['rotate'] });
      }, 500);
    }
  }, [state.loading]);

  return (
    <div className={s.marketbtn}>
      <Button
        shape='circle'
        className={s.back}
        onClick={paramsObj.id ? back : reload}
        icon={paramsObj.id ? before : refresh}
      />
      <Spin
        className={s.under}
        spinning={((state.rotate || state.loading) && paramsObj.id !== undefined)}
        indicator={<LoadingOutlined className={s.spinner} />}
      />
    </div>
  );
};

export default MarketButton;
