import { useState, useContext } from 'react';
import { Form, Select, Button } from 'antd';
import { FaImage } from 'react-icons/fa';
import { Context, ImgUpload, Erratum, Utils } from 'shookt';
import { authenticate } from '../../session';
import profileDocumentsPost from './profileDocumentsPost';

const ProfileDocuments = props => {
  const [state, dispatch] = useContext(Context);
  const [file, setFile] = useState('');

  const onFinish = async values => {
    if (!file) {
      return dispatch({ upsert: { error: 'No photo selected.' } });
    }
    const { status } = await profileDocumentsPost({
      key: values.editdocu_type,
      file: file
    });
    if (status === 200) {
      await authenticate(dispatch);
      dispatch({ delete: ['drawer'] });
    }
  };

  return (
    <Form
      layout='vertical'
      onFinish={onFinish}
      onValuesChange={Utils.clearError(dispatch)}
    >
      <Form.Item className='text-center'>
        <ImgUpload
          message='Browse'
          imgStore={file}
          imgSet={setFile}
          icon={<FaImage />}
        />
      </Form.Item>

      <Form.Item
        name='editdocu_type'
        label='Document type'
        rules={[{
          required: true,
          message: 'Please select a type'
        }]}
      >
        <Select>
          <Select.Option value='proof_of_identification'>Proof of identification</Select.Option>
          <Select.Option value='proof_of_address'>Proof of address</Select.Option>
          <Select.Option value='proof_of_financial'>Proof of financial capacity</Select.Option>
        </Select>
      </Form.Item>

      <Erratum />

      <Form.Item>
        <Button
          block
          type='primary'
          htmlType='submit'
          disabled={state.loading}
          loading={state.loading}
        >
          Save document photo
        </Button>
      </Form.Item>
    </Form>
  );
};

export default ProfileDocuments;
