import { useContext } from 'react';
import { Row, Col } from 'antd';
import { Context } from 'shookt';
import s from './panel.module.less';

const rowProps = {
  justify: 'center',
  align: 'middle'
};

const Panel = props => {
  const [state] = useContext(Context);
  const head = (
    <Row className={s.row} {...rowProps}>
      <Col>
        <a href='/'>
          <img
            src={state.logo}
            alt={state.appName}
            className={s.logo}
          />
        </a>
      </Col>
    </Row>
  );
  const foot = (
    <Row className={s.row} {...rowProps}>
      <Col>
        &copy;{new Date().getFullYear()} {state.appName}
      </Col>
    </Row>
  );

  return (
    <>
      {props.noHead ? null : head}
      <Row className={s.row} {...rowProps}>
        <Col>
          <div className={props.clean ? '' : s.main} style={props.style}>
            {props.children}
          </div>
        </Col>
      </Row>
      {props.noFoot ? null : foot}
    </>
  );
};

export default Panel;
