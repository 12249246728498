import axios from 'axios';

const marketItemGet = async id => {
  try {
    const response = await axios({
      method: 'get',
      url: `/market/item/${id || 'all'}`
    });
    return response;
  } catch ({ response }) {
    return response;
    // internal
  }
};

export default marketItemGet;
