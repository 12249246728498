import { useContext, useEffect, useState } from 'react';
import { Typography, Divider, Form, Input, Button } from 'antd';
import { Context, Erratum, Utils } from 'shookt';
import { AiOutlineFileText, AiOutlineLock } from 'react-icons/ai';
import { PasswordInput } from 'antd-password-input-strength';
import { authenticate } from '../../session';
import { Panel } from '../../components';
import SetupPhoto from './SetupPhoto';
import profilePost from '../profile/profilePost';

const SetupProfile = props => {
  const [state, dispatch] = useContext(Context);
  const [done, setDone] = useState(false);
  const [form] = Form.useForm();

  const onFinish = async values => {
    const { status } = await profilePost({
      first_name: values.setup_first_name.trim(),
      middle_name: values.setup_middle_name.trim(),
      last_name: values.setup_last_name.trim(),
      password: values.setup_password
    });
    if (status === 200) {
      await authenticate(dispatch);
      setDone(true);
    }
  };

  useEffect(() => {
    if (state.user.first_name) {
      form.setFieldsValue({ setup_first_name: Utils.toTitleCase(state.user.first_name) });
    }
    if (state.user.middle_name) {
      form.setFieldsValue({ setup_middle_name: Utils.toTitleCase(state.user.middle_name) });
    }
    if (state.user.last_name) {
      form.setFieldsValue({ setup_last_name: Utils.toTitleCase(state.user.last_name) });
    }
  }, []);

  let body = (
    <Panel style={{ maxWidth: 345 }}>
      <Form
        form={form}
        layout='vertical'
        onFinish={onFinish}
        onValuesChange={Utils.clearError(dispatch)}
      >
        <Typography.Paragraph className='text-center'>
          Complete your account details.
        </Typography.Paragraph>

        <Divider plain>Full name</Divider>

        <Form.Item
          name='setup_first_name'
          hasFeedback
          rules={[{
            required: true,
            whitespace: true,
            pattern: '^[a-zA-ZñÑ. ]*$',
            message: 'Enter a valid first name'
          }]}
        >
          <Input
            minLength={1}
            maxLength={100}
            prefix={<AiOutlineFileText />}
            placeholder='First name'
            autoFocus
          />
        </Form.Item>

        <Form.Item
          name='setup_middle_name'
          hasFeedback
          rules={[{
            required: true,
            whitespace: true,
            pattern: '^[a-zA-ZñÑ. ]*$',
            message: 'Enter a valid middle name'
          }]}
        >
          <Input
            minLength={1}
            maxLength={100}
            prefix={<AiOutlineFileText />}
            placeholder='Middle name'
          />
        </Form.Item>

        <Form.Item
          name='setup_last_name'
          hasFeedback
          rules={[{
            required: true,
            whitespace: true,
            pattern: '^[a-zA-ZñÑ. ]*$',
            message: 'Enter a valid last name'
          }]}
        >
          <Input
            minLength={1}
            maxLength={100}
            prefix={<AiOutlineFileText />}
            placeholder='Last name'
          />
        </Form.Item>

        <Divider plain>Credentials</Divider>

        <Form.Item
          name='setup_password'
          rules={[Utils.passwordPolicy({
            minLength: 8,
            hasNumbers: true,
            hasLowerCase: true,
            hasUpperCase: true,
            hasNonalphas: true
          })]}
        >
          <PasswordInput
            prefix={<AiOutlineLock />}
            placeholder='New Password'
          />
        </Form.Item>

        <Form.Item
          name='setup_password2'
          rules={[{
            required: true,
            message: 'Please confirm your password'
          }, Utils.mustMatch('setup_password',
            'Both passwords must match')
          ]}
        >
          <Input.Password
            prefix={<AiOutlineLock />}
            placeholder='Repeat New Password'
          />
        </Form.Item>

        <Erratum />

        <Form.Item>
          <Button
            block
            type='primary'
            htmlType='submit'
            disabled={state.loading}
            loading={state.loading}
          >
            Save details
          </Button>
        </Form.Item>
      </Form>
    </Panel>
  );

  if (done) {
    body = <SetupPhoto />;
  }

  return body;
};

export default SetupProfile;
