import { useContext } from 'react';
import { Row, Col, Space, List, Typography } from 'antd';
import { Context } from 'shookt';
import { Avatar, Created } from '../';
import s from './datalist.module.less';
const noop = Function.prototype;

const RenderItem = ({
  noBottBox, rowUrl,
  hasAvatar, noHover, item,
  avatarHandler, noPadding,
  onRowClick = noop,
  bodyHandler = noop,
  titleHandler = noop,
  bottbox1Handler = noop,
  bottbox2Handler = noop,
  bottbox3Handler = noop,
  bottbox4Handler = noop
}) => {
  const [state] = useContext(Context);
  let avahtar = null;
  if (avatarHandler) {
    avahtar = avatarHandler(item);
  } else if (hasAvatar) {
    avahtar = <Avatar shape={hasAvatar.shape} size={hasAvatar.size || 42} user={item} thumb />;
  }

  let itemMeta = (
    <List.Item.Meta
      className={s.listmeta}
      onClick={() => onRowClick(item)}
      title={titleHandler(item)}
      avatar={avahtar}
      description={
        <>
          {bodyHandler(item)}
          {
            !noBottBox && (
              <div className={s.bottominfo}>
                <Row gutter={[10, 0]}>
                  <Col xs={0} md={8} lg={4} xl={7}>
                    {(bottbox1Handler && bottbox1Handler(item)) || (<Typography.Text type='secondary'>ID {item.id}</Typography.Text>)}
                  </Col>
                  <Col xs={0} md={8} lg={8} xl={6}>
                    {(bottbox2Handler && bottbox2Handler(item)) || (<Created item={item} />)}
                  </Col>
                  <Col xs={0} md={8} lg={8} xl={6}>
                    {(bottbox3Handler && bottbox3Handler(item)) || (<Created item={item} updated />)}
                  </Col>
                  <Col xs={0} md={0} lg={4} xl={5}>
                    {
                      (bottbox4Handler && bottbox3Handler(item)) || (
                        item.company &&
                        (item.company.id || item.company[0].company) &&
                        state.user &&
                        state.user.privileges.company && (
                          <Space align='center'>
                            <Typography.Text type='secondary'>
                              Distributor
                            </Typography.Text>
                            <Avatar
                              tooltip={item.company.name || item.company[0].company.name}
                              user={item.company.id ? item.company : item.company[0].company}
                              size={20}
                              thumb
                            />
                          </Space>
                        )
                      )
                    }
                  </Col>
                </Row>
              </div>
            )
          }
        </>
      }
    />
  );

  if (rowUrl) {
    itemMeta = (
      <a href={rowUrl(item)}>
        {itemMeta}
      </a>
    );
  }

  return (
    <List.Item className={`${s.listitem} ${noHover ? '' : s.listitemhover} ${noPadding ? s.nopadd : ''}`}>
      {itemMeta}
    </List.Item>
  );
};

export default RenderItem;
