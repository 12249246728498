import axios from 'axios';
import { Utils } from 'shookt';

const productGet = async (id, params) => {
  try {
    const response = await axios({
      method: 'get',
      url: `/product/${id || 'all'}?${Utils.qs(params)}`
    });
    return response;
  } catch ({ response }) {
    return response;
    // internal
  }
};

export default productGet;
