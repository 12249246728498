import { useEffect, useState } from 'react';
import { Card, Tree, Typography, message } from 'antd';
import { Dashboard, Header } from '../../components';
import { DownOutlined } from '@ant-design/icons';
import categoryDelete from './categoryDelete';
import categoryPost from './categoryPost';
import categoryGet from './categoryGet';
import CategoryForm from './CategoryForm';

const CategoryList = props => {
  const [data, setData] = useState([]);

  const loadData = async () => {
    setData((await categoryGet(true)).data);
  };

  const save = itemId => {
    return async val => {
      if (val && val.trim()) {
        const { status, data } = await categoryPost({ id: itemId, name: val });
        if (status === 200) {
          await loadData();
        } else {
          message.error(data.message);
        }
      } else {
        const { status, data } = await categoryDelete(itemId);
        if (status === 200) {
          await loadData();
        } else {
          message.error(data.message);
        }
      }
    };
  };

  useEffect(() => {
    (async () => {
      await loadData();
    })();
  }, []);

  return (
    <Dashboard>
      <Header
        noTop
        level={3}
        title='Categories'
      />

      <Card>
        <Tree
          showLine
          defaultExpandAll
          treeDefaultExpandAll
          treeData={data}
          switcherIcon={<DownOutlined />}
          titleRender={item => {
            if (!item.id) {
              return (<CategoryForm parentId={item.parent_id} onFinish={loadData} />);
            }
            return (
              <Typography.Text editable={{ onChange: save(item.id) }}>
                {item.name}
              </Typography.Text>
            );
          }}
        />
      </Card>
    </Dashboard>
  );
};

export default CategoryList;
