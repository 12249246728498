import { useContext, useEffect, useState } from 'react';
import { Row, Col, Dropdown, Button, List, Space, Modal, Tag, Typography, Tooltip, Popover, message } from 'antd';
import { Avatar, Address, Dashboard, Picture, Timestamp, Ellipsis, Header, Desc } from '../../components';
import { Context, GoogleMap, Utils } from 'shookt';
import { FiChevronLeft, FiChevronDown } from 'react-icons/fi';
import { BsCheckCircle } from 'react-icons/bs';
import s from './user.module.less';
import userGet from './userGet';
import userPost from './userPost';
import UserForm from './UserForm';
import verifyPost from '../verify/verifyPost';

const UserView = props => {
  const [state, dispatch] = useContext(Context);
  const [status, setStatus] = useState(0);
  const [modal, setModal] = useState(false);
  const [user, setUser] = useState({});
  const toDrawer = Dashboard.toDrawer(dispatch);
  const isAdmin = state.user.type === 'admin';
  const isManager = state.user.type === 'manager';
  const { address = {} } = user;
  const zoom = address.lat ? 16 : 9;
  const lat = address.lat ? parseFloat(address.lat) : '';
  const lng = address.lng ? parseFloat(address.lng) : '';

  const sendInvite = contact => async () => {
    const payload = { is_invite: true };
    if (contact.includes('@')) {
      payload.email = contact;
    } else {
      payload.mobile = contact;
    }
    const { status } = await verifyPost(payload);
    if (status === 200) {
      message.success(`Invite sent to ${contact}`);
    }
  };

  const suspendUser = async () => {
    const { status } = await userPost({
      is_enabled: !user.is_enabled,
      id: user.id
    });
    if (status === 200) {
      setTimeout(() => {
        loadData();
      }, 100);
    }
  };

  const promoteUser = async () => {
    setModal(false);
    const { status } = await userPost({
      is_admin: !user.is_admin,
      id: user.id
    });
    if (status === 200) {
      setTimeout(() => {
        loadData();
      }, 100);
    }
  };

  const loadData = () => {
    (async () => {
      const { status, data } = await userGet(props.params.id);
      if (status === 200) {
        setUser(data);
      }
      setStatus(status);
    })();
  };

  useEffect(() => {
    if (state.reload) {
      dispatch({ delete: ['reload'] });
    }
    loadData();
  }, [state.reload]);

  const userMenu = [];
  if (isAdmin || isManager) {
    userMenu.push({
      key: 'suspend',
      label: user.is_enabled ? 'Suspend' : 'Unsuspend',
      disabled: state.user.id === props.params.id,
      onClick: suspendUser
    });
    if (user.is_enabled && user.company) {
      userMenu.push({
        key: 'promote',
        label: user.is_admin ? 'Remove as admin' : 'Promote to admin',
        disabled: state.user.id === props.params.id,
        onClick: async () => {
          if (user.is_admin) {
            return await promoteUser();
          }
          setModal(true);
        }
      });
    }
  }
  if (!user.password) {
    userMenu.push({
      key: 'notverified',
      label: 'User not yet verified',
      disabled: true
    });
    if (user.is_enabled) {
      if (user.email) {
        userMenu.push({
          key: 'iEmail',
          label: 'Resend email invite',
          onClick: sendInvite(user.email)
        });
      }
      if (user.mobile) {
        userMenu.push({
          key: 'iMobile',
          label: 'Resend mobile invite',
          onClick: sendInvite(user.mobile)
        });
      }
    }
  }

  return (
    <Dashboard
      viewspin
      status={status}
      back='/user/all'
    >
      <Header
        noTop
        title={(
          <>
            <Button
              shape='circle'
              onClick={() => window.history.back()}
              icon={<FiChevronLeft />}
            />
            <Typography.Title className='no-bott-margin' level={4}>
              &nbsp;{Utils.formatName(user)}
            </Typography.Title>
          </>
        )}
        extra={(
          <Dropdown.Button
            onClick={toDrawer(<UserForm id={user.id} key={user.id} />, 'Update user information')}
            trigger={['click']}
            icon={<FiChevronDown />}
            menu={{ items: userMenu }}
          >
            Edit user
          </Dropdown.Button>
        )}
      />
      <Row gutter={[10, 10]}>
        <Col xs={24} sm={8} md={6} lg={5} className='text-center'>
          <Avatar size={128} user={user} />
          <br />
          {
            (user.is_enabled === false) && (
              <Typography.Paragraph>
                <Tag color='error' className='no-right-margin'>
                  SUSPENDED
                </Tag>
              </Typography.Paragraph>
            )
          }
          {
            (user.password === false) && (
              <Typography.Paragraph>
                <Tag className='no-right-margin'>
                  {(user.email || user.mobile) && 'Pending Verification'}
                  {!user.email && !user.mobile && 'User has no contacts yet'}
                </Tag>
              </Typography.Paragraph>
            )
          }
          {
            (user.password === true) && (
              <Typography.Paragraph>
                <Tag color='success' className='no-right-margin'>
                  ACTIVE
                </Tag>
              </Typography.Paragraph>
            )
          }
          {
            !user.is_admin &&
            user.role &&
            user.role.id && (
              <Popover
                placement='bottomLeft'
                content={
                  user.privileges && (
                    <List
                      header={<>Privileges</>}
                      dataSource={Object.keys(user.privileges).map(p => ({ code: p, description: user.privileges[p] }))}
                      renderItem={item => (
                        <List.Item>
                          <Space align='start'>
                            <Typography.Text type='success'>
                              <BsCheckCircle />
                            </Typography.Text>
                            <Typography.Text>
                              {item.description}
                            </Typography.Text>
                          </Space>
                        </List.Item>
                      )}
                    />
                  )
                }
              >
                <Tag color={`#${user.role.color}`} className='no-right-margin'>
                  <div className={s.userrolename}>
                    <Ellipsis className='text-white'>
                      {user.role.name}
                    </Ellipsis>
                  </div>
                </Tag>
              </Popover>
            )
          }
          {
            user.is_admin && (
              <Typography.Paragraph keyboard className={`text-center ${s.userstatus}`}>
                Company Admin
              </Typography.Paragraph>
            )
          }
        </Col>
        <Col xs={24} sm={16} md={18} lg={19}>
          <Desc>
            <Desc.Item label='Last name'>
              {Utils.toTitleCase(user.last_name) || '-'}
            </Desc.Item>
            <Desc.Item label='First name'>
              {Utils.toTitleCase(user.first_name) || '-'}
            </Desc.Item>
            <Desc.Item label='Middle name'>
              {Utils.toTitleCase(user.middle_name) || '-'}
            </Desc.Item>
            <Desc.Item label='Birthday'>
              <Tooltip title={user.birthdate ? Utils.getAge(user.birthdate) : '-'}>
                <Timestamp stamp={user.birthdate} ifEmpty='-' format='MMMM D, YYYY' />
              </Tooltip>
            </Desc.Item>
            <Desc.Item label='Mobile'>
              {user.mobile || '-'}
            </Desc.Item>
            <Desc.Item label='Email'>
              {user.email || '-'}
            </Desc.Item>
            <Desc.Item label='Sex'>
              {Utils.toTitleCase(user.sex) || '-'}
            </Desc.Item>
            <Desc.Item label='Civil status'>
              {Utils.toTitleCase(user.civil_status) || '-'}
            </Desc.Item>
            <Desc.Item label='Religion'>
              {Utils.toTitleCase(user.religion) || '-'}
            </Desc.Item>
            <Desc.Item label='Last update'>
              <Timestamp stamp={user.updated_at} />
            </Desc.Item>
            <Desc.Item label='Last update by'>
              {user.updated_by && user.updated_by.id && (Utils.formatName(user.updated_by))}
            </Desc.Item>
            <Desc.Item label='ID'>
              {user.id}
            </Desc.Item>
            <Desc.Item label='Created at'>
              <Timestamp stamp={user.created_at} />
            </Desc.Item>
            <Desc.Item label='Created by'>
              {user.created_by && user.created_by.id && Utils.formatName(user.created_by)}
            </Desc.Item>
            {
              user.company &&
              user.company.id &&
              state.user &&
              state.user.privileges.company && (
                <Desc.Item label='Distributor'>
                  {user.company.name}
                </Desc.Item>
              )
            }
          </Desc>
        </Col>
      </Row>

      <Desc
        label='Address'
      // extra={(
      //   <Button
      //     onClick={toDrawer(<ProfileAddress />, 'Change current address')}
      //     type='link'
      //   >
      //     Edit
      //   </Button>
      // )}
      >
        <Desc.Item label='House/Subdivision'>
          {address.housesubd || '-'}
        </Desc.Item>
        <Desc.Item label='Zip Code'>
          {address.zipcode || '-'}
        </Desc.Item>
        <Desc.Item label='Barangay'>
          {Utils.toTitleCase(address.barangay || '-')}
        </Desc.Item>
        <Desc.Item label='Town/City'>
          {Utils.toTitleCase(address.towncity || '-')}
        </Desc.Item>
        <Desc.Item label='Province'>
          {Utils.toTitleCase(address.province || '-')}
        </Desc.Item>
        <Desc.Item label='Region'>
          {Utils.toTitleCase(address.region || '-')}
        </Desc.Item>
        <Desc.Item>
          <GoogleMap
            theme={Address.theme}
            values={{ zoom, lat, lng, center: [lat, lng] }}
            reCenter
            readOnly
          />
          <br />
        </Desc.Item>
      </Desc>

      <Desc
        label='Documents'
      // extra={(
      //   <Button
      //     onClick={toDrawer(<ProfileDocuments />, 'Update document photos')}
      //     type='link'
      //   >
      //     Edit
      //   </Button>
      // )}
      >
        <Desc.Item label='Proof of identification'>
          <Picture
            width={200}
            height={200}
            src={user.proof_of_identification || ''}
          />
        </Desc.Item>
        <Desc.Item label='Proof of address'>
          <Picture
            width={200}
            height={200}
            src={user.proof_of_address || ''}
          />
        </Desc.Item>
        <Desc.Item label='Proof of financial capacity'>
          <Picture
            width={200}
            height={200}
            src={user.proof_of_financial || ''}
          />
        </Desc.Item>
      </Desc>
      <Modal
        onOk={promoteUser}
        width={360}
        open={modal}
        onCancel={() => setModal(false)}
        okText='I understand &amp; agree to proceed'
        title='Admin mode confirmation'
      >
        <Typography.Paragraph>Promoting a user to admin gives them the power to remove {isAdmin ? '' : 'you as admin'} and promote other admins as well.</Typography.Paragraph>
        <Typography.Paragraph>Do you understand and agree to this condition?</Typography.Paragraph>
      </Modal>
    </Dashboard>
  );
};

export default UserView;
