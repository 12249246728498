import { useContext, useEffect, useState } from 'react';
import { Form, Input, Button, InputNumber, Typography } from 'antd';
import { Context, Erratum, Utils } from 'shookt';
import inventoryItemGet from './inventoryItemGet';
import inventoryItemPost from './inventoryItemPost';

const InventoryForm = props => {
  const [state, dispatch] = useContext(Context);
  const [current, setCurrent] = useState(0);
  const [form] = Form.useForm();

  const onFinish = async values => {
    const { status } = await inventoryItemPost({
      inventory_item_id: props.inventoryitem.id,
      quantity: values.newledg_quantity,
      remarks: values.newledg_remarks.trim()
    });
    if (status === 200) {
      dispatch({ upsert: { reload: true } });
      dispatch({ delete: ['drawer'] });
    }
  };

  useEffect(() => {
    (async () => {
      const { status, data } = await inventoryItemGet(props.inventoryitem.id, { page: 1, pageSize: 1 });
      if (status === 200) {
        setCurrent(data.data[0].current_value);
      }
    })();
  }, []);

  return (
    <Form
      form={form}
      layout='vertical'
      onFinish={onFinish}
      onValuesChange={Utils.clearError(dispatch)}
    >
      <Typography.Paragraph type='secondary' className='no-bott-margin'>
        Stock Id:
      </Typography.Paragraph>
      <Typography.Paragraph>
        {props.inventoryitem.code}
      </Typography.Paragraph>

      <Typography.Paragraph type='secondary' className='no-bott-margin'>
        Product:
      </Typography.Paragraph>
      <Typography.Paragraph>
        {props.product.code}: {props.product.name}
      </Typography.Paragraph>

      <Typography.Paragraph type='secondary' className='no-bott-margin'>
        Current quantity:
      </Typography.Paragraph>
      <Typography.Paragraph>
        {Utils.toMonetary(current, 0)}
      </Typography.Paragraph>

      <Form.Item
        label='New quantity'
        name='newledg_quantity'
        rules={[{
          required: true,
          message: 'Please enter a quantity'
        }]}
      >
        <InputNumber
          minLength={1}
          maxLength={15}
          placeholder='New quantity'
          controls
        />
      </Form.Item>

      <Form.Item
        label='Remarks'
        name='newledg_remarks'
        rules={[{
          max: 100,
          required: true,
          whitespace: true,
          message: 'Please enter some remarks'
        }]}
      >
        <Input.TextArea placeholder='Remarks' maxLength={100} autoSize={{ minRows: 2 }} showCount />
      </Form.Item>

      <Erratum />

      <Form.Item>
        <Button
          block
          type='primary'
          htmlType='submit'
          disabled={state.loading}
          loading={state.loading}
        >
          Save new quantity
        </Button>
      </Form.Item>
    </Form>
  );
};

export default InventoryForm;
