import { useEffect, useContext, useState } from 'react';
import { List, Space, Typography, Collapse, Tag, Dropdown, Select, message } from 'antd';
import { Dashboard, Banko, Address, Header, DataList, Created, Spacer, Variations, Desc, Avatar, Timestamp, Ellipsis } from '../../components';
import { AiOutlineDown } from 'react-icons/ai';
import { Context, GoogleMap, Utils } from 'shookt';
import s from './market.module.less';
import orderGet from '../order/orderGet';
import marketOrderGet from './marketOrderGet';
import marketOrderPost from './marketOrderPost';

const MyOrders = props => {
  const [state, dispatch] = useContext(Context);
  const [values, setValues] = useState({});
  const [params, setParams] = useState({});
  const [status, setStatus] = useState(null);
  const [panel, setPanel] = useState(null);

  const orderAction = (event, order) => {
    const callBanko = () => window.location.replace(`/api/banko?state=${event === 'retry' ? 'transfer' : 'cancel'}`);
    const callApi = async action => {
      const { status } = await marketOrderPost({ id: order.id, action });
      if (status === 200) {
        dispatch({ upsert: { reload: true } });
      }
    };
    return async () => {
      if (event === 'receive' || (event === 'cancel' && (
        (order.method_payment !== 'cod' && order.status === 'topay') ||
        (order.method_payment === 'cod' && ['toship', 'topay'].includes(order.status))))) {
        await callApi('update');
      } else {
        await callApi('checkout');
        if (order.method_payment === 'banko') {
          const accts = await Banko.loadAccounts();
          if (accts.length) {
            callBanko();
          } else {
            message.error('Please link your Banko Account first');
          }
        }
        // TODO: add other payment method handlers
      }
    };
  };

  const loadData = () => {
    dispatch({ upsert: { loading: true } });
    setTimeout(async () => {
      const { data } = await marketOrderGet('all', {
        find: params.find,
        sort: params.sort,
        page: params.page || 1,
        pageSize: state.pageSize,
        status
      });
      dispatch({ upsert: { orders: data.data || [] } });
      setValues(data);
    }, 200);
  };

  useEffect(() => {
    if (state.reload) {
      dispatch({ delete: ['reload'] });
    }
    if (params && params.page) {
      loadData();
    }
  }, [params, status, state.reload]);

  useEffect(() => {
    const qry = JSON.parse(state.query);
    const type = qry.success ? 'success' : 'error';
    const msg = qry.success || qry.failed;
    msg && message[type](msg);
  }, []);

  return (
    <Dashboard>
      <Header
        noTop
        level={3}
        title='My orders'
      />
      <DataList
        noBottBox
        values={values}
        storeKey='myorder'
        onChange={setParams}
        countLabel='orders'
        dataSource={state.orders}
        searchPlaceholder='Search order code'
        renderItem={order => {
          const addr = order.address || {};
          const lat = addr.lat ? parseFloat(addr.lat) : '';
          const lng = addr.lng ? parseFloat(addr.lng) : '';
          let total = 0;
          order.items.forEach(item => (total += item.pricing.unit_price * item.quantity));
          return (
            <List
              bordered
              className={`${s.ordercompbox} market-order`}
              dataSource={order.items}
              renderItem={item => (
                <List.Item>
                  <List.Item.Meta
                    title={(
                      <Spacer center block>
                        {item.product.name}
                        {item.variation_log.length > 0 && <Variations item={item} noBott view />}
                        <Created prefix='Added' item={item} noAvatar />
                      </Spacer>
                    )}
                    description={(
                      <>
                        <Spacer split block>
                          <Space>
                            <Typography.Text type='secondary'>
                              Quantity:
                            </Typography.Text>
                            <Typography.Text className={s.qty}>
                              {Utils.toMonetary(item.quantity, 0)}
                            </Typography.Text>
                          </Space>
                          <Space align='start'>
                            <Typography.Text type='secondary'>
                              Price:
                            </Typography.Text>
                            <Typography.Text>
                              <span className={s.primary}>₱{Utils.toMonetary(item.pricing.unit_price)}</span>
                            </Typography.Text>
                          </Space>
                          <Space>
                            <Typography.Text type='secondary'>
                              Sub-total:
                            </Typography.Text>
                            <Typography.Text>
                              ₱{Utils.toMonetary(item.pricing.unit_price * item.quantity)}
                            </Typography.Text>
                          </Space>
                        </Spacer>
                      </>
                    )}
                    avatar={<Avatar size={46} user={item.product} square thumb />}
                  />
                </List.Item>
              )}
              header={(
                <Collapse key={order.id} bordered={false} expandIconPosition='end' accordion activeKey={panel} onChange={setPanel}>
                  <Collapse.Panel
                    key={order.id}
                    className={s.collapseheader}
                    extra={state.screens.lg && <Typography.Text>Total ₱{Utils.toMonetary(total)}</Typography.Text>}
                    header={(
                      <Spacer noBott block align='center' direction='horizontal'>
                        <Avatar size={24} user={order.company} thumb />
                        <Ellipsis style={!state.screens.lg && { width: 'calc(100vw - 130px)' }}>
                          <Tag color={orderGet.toStatusColor(order.status)}>
                            {orderGet.statusToLabel(order.status)}
                          </Tag>
                          {order.company.name}
                        </Ellipsis>
                        {state.screens && state.screens.lg && <Typography.Text type='secondary'>Ordered <Timestamp stamp={order.created_at} /></Typography.Text>}
                      </Spacer>
                    )}
                  >
                    <br />
                    <Desc>
                      <Desc.Item label='Order code'>
                        {order.code}
                      </Desc.Item>
                      <Desc.Item label='Date ordered'>
                        <Timestamp stamp={order.created_at} />
                      </Desc.Item>
                      <Desc.Item label='Distributor'>
                        {order.company.name}
                      </Desc.Item>
                      <Desc.Item label='Distributor mobile'>
                        {order.company.mobile ? <a href={`tel:${order.company.mobile.replace('+63', '0')}`}>{order.company.mobile}</a> : '-'}
                      </Desc.Item>
                      <Desc.Item label='Distributor email'>
                        {order.company.email ? <a href={`mailto:${order.company.email}`}>{order.company.email}</a> : '-'}
                      </Desc.Item>
                      <Desc.Item>
                        <Dropdown
                          className='cursor-pointer'
                          disabled={['canceled', 'received'].includes(order.status)}
                          trigger={['click']}
                          menu={{
                            items: [
                              {
                                key: 'retry',
                                label: 'Retry payment',
                                disabled: state.loading || !order.isRetriable,
                                onClick: orderAction('retry', order)
                              },
                              {
                                key: 'cancel',
                                label: 'Cancel order',
                                disabled: state.loading || !order.isCancelable,
                                onClick: orderAction('cancel', order)
                              },
                              {
                                key: 'receive',
                                label: 'Order received',
                                disabled: state.loading || order.status !== 'toreceive',
                                onClick: orderAction('receive', order)
                              }
                            ]
                          }}
                        >
                          <a onClick={(e) => e.preventDefault()}>
                            Order options <AiOutlineDown />
                          </a>
                        </Dropdown>
                      </Desc.Item>
                    </Desc>
                    <Desc
                      label='Payment details'
                      extra={(
                        <Tag color={orderGet.toStatusColor(order.status_payment)}>
                          {orderGet.statusToLabel(order.status_payment)}
                        </Tag>
                      )}
                    >
                      <Desc.Item label='Reference No.'>
                        {order.payment[0].code || '-'}
                      </Desc.Item>
                      <Desc.Item label='Method'>
                        {orderGet.methodCodeToName(order.method_payment)}
                      </Desc.Item>
                      <Desc.Item label='Total'>
                        ₱{Utils.toMonetary(total)}
                      </Desc.Item>
                    </Desc>
                    <Desc
                      label='Delivery details'
                      extra={(
                        <Tag color={orderGet.toStatusColor(order.status_delivery)}>
                          {orderGet.statusToLabel(order.status_delivery)}
                        </Tag>
                      )}
                    >
                      <Desc.Item label='Reference No.'>
                        {order.delivery.reference_code || '-'}
                      </Desc.Item>
                      <Desc.Item label='Method'>
                        {orderGet.methodCodeToName(order.method_delivery) || '-'}
                      </Desc.Item>
                      <Desc.Item label='Recipient'>
                        {order.recipient.name || '-'}. &nbsp;
                        {order.recipient.mobile || '-'}
                      </Desc.Item>
                      <Desc.Item label='House/Subdivision'>
                        {order.address.housesubd || '-'}
                      </Desc.Item>
                      <Desc.Item label='Zip Code'>
                        {order.address.zipcode || '-'}
                      </Desc.Item>
                      <Desc.Item label='Barangay'>
                        {Utils.toTitleCase(order.address.barangay || '-')}
                      </Desc.Item>
                      <Desc.Item label='Town/City'>
                        {Utils.toTitleCase(order.address.towncity || '-')}
                      </Desc.Item>
                      <Desc.Item label='Province'>
                        {Utils.toTitleCase(order.address.province || '-')}
                      </Desc.Item>
                      <Desc.Item label='Region'>
                        {Utils.toTitleCase(order.address.region || '-')}
                      </Desc.Item>
                      <Desc.Item>
                        <GoogleMap
                          reCenter
                          readOnly
                          theme={Address.theme}
                          values={{ zoom: 16, lat, lng, center: [lat, lng] }}
                        />
                      </Desc.Item>
                    </Desc>
                  </Collapse.Panel>
                </Collapse>
              )}
            />
          );
        }}
        sortOptions={[
          { label: 'Newest', value: { column: 'created_at', order: 'desc' } },
          { label: 'Oldest', value: { column: 'created_at', order: 'asc' } }
        ]}
        extra={(
          <Select
            allowClear
            placeholder='Select status'
            className={s.sort}
            onChange={setStatus}
            value={status}
            options={orderGet.typeStatus.order}
          />
        )}
      />
    </Dashboard>
  );
};

export default MyOrders;
