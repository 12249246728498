import { List, Dropdown } from 'antd';
import { useContext } from 'react';
import { AiOutlineMore } from 'react-icons/ai';
import { Context, Utils } from 'shookt';
import { Dashboard, Spacer } from '../../../components';
import CoverageAreaForm from './CoverageAreaForm';
import coverageDelete from './CoverageAreaDelete';

const CoverageAreaList = props => {
  const [state, dispatch] = useContext(Context);
  const toDrawer = Dashboard.toDrawer(dispatch);
  const isAdmin = state.user.type === 'admin';

  return (
    <List
      dataSource={props.dataSource}
      renderItem={item => (
        <List.Item
          className={isAdmin && 'cursor-pointer'}
          extra={isAdmin && (
            <Dropdown
              className='cursor-pointer'
              trigger={['click']}
              placement='topLeft'
              menu={{
                items: [{
                  key: 'delete',
                  label: 'Delete',
                  danger: true,
                  onClick: async () => {
                    const { status } = await coverageDelete(item.id);
                    if (status === 200) {
                      dispatch({ upsert: { reload: true } });
                    }
                  }
                }]
              }}
            >
              <AiOutlineMore />
            </Dropdown>
          )}
        >
          <List.Item.Meta
            title={(
              <Spacer center block>
                {[item.region.toUpperCase(),
                  Utils.toTitleCase(item.province),
                  Utils.toTitleCase(item.towncity),
                  Utils.toTitleCase(item.barangay)]
                  .filter(Boolean).join(', ')}
              </Spacer>
            )}
            onClick={isAdmin && toDrawer(<CoverageAreaForm item={item} companyId={props.companyId} />, 'Update coverage area')}
            className={isAdmin && 'cursor-pointer'}
          />
        </List.Item>
      )}
    />
  );
};

export default CoverageAreaList;
