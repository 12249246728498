import { useContext, useState } from 'react';
import { Form, Input, Button, Result } from 'antd';
import { Context, Erratum, Utils } from 'shookt';
import { AiOutlineLock } from 'react-icons/ai';
import { authenticate } from '../../session';
import profilePasswordPost from '../profile/profilePasswordPost';

const Reclaim = props => {
  const [state, dispatch] = useContext(Context);

  const onFinish = async values => {
    const { status } = await profilePasswordPost({
      password: values.reclaim_password,
      code: props.code
    });
    if (status === 200) {
      await authenticate(dispatch);
      setBody(
        <>
          <Result
            status='success'
            title='Account Recovered'
            subTitle='You have successfully changed your password.'
          />
          <Button href='/' type='link' block>
            Continue
          </Button>
        </>
      );
    }
  };

  const [body, setBody] = useState(
    <Form
      layout='vertical'
      onFinish={onFinish}
      onValuesChange={Utils.clearError(dispatch)}
    >
      <Form.Item className='text-center'>
        Please provide a new password for your account.
      </Form.Item>

      <Form.Item
        name='reclaim_password'
        rules={[{
          min: 8,
          max: 100,
          required: true,
          message: 'Password must be 8 or more characters'
        }]}
      >
        <Input.Password
          prefix={<AiOutlineLock />}
          placeholder='New Password'
        />
      </Form.Item>

      <Form.Item
        name='reclaim_password2'
        rules={[{
          required: true,
          message: 'Please confirm your password'
        }, Utils.mustMatch('reclaim_password',
          'Both passwords must match')
        ]}
      >
        <Input.Password
          prefix={<AiOutlineLock />}
          placeholder='Repeat New Password'
        />
      </Form.Item>

      <Erratum />

      <Form.Item>
        <Button
          block
          type='primary'
          htmlType='submit'
          disabled={state.loading}
          loading={state.loading}
        >
          Change password
        </Button>
      </Form.Item>
    </Form>
  );

  return body;
};

export default Reclaim;
