import { useContext, useEffect } from 'react';
import { Form, Input, Button } from 'antd';
import { Context, Erratum, Utils } from 'shookt';
import variationPost from './variationPost';

const VariationForm = props => {
  const [state, dispatch] = useContext(Context);
  const [form] = Form.useForm();
  const data = props.data || {};

  const onFinish = async values => {
    let hasDuplicate = false;
    let hasEmptyOption = false;
    const validOptions = [];
    if (Array.isArray(values.newvari_options)) {
      values.newvari_options.forEach(option => {
        if (option) {
          option = option.toLowerCase().trim();
          if (!option) {
            hasEmptyOption = true;
          }
          if (validOptions.includes(option)) {
            hasDuplicate = true;
          }
          validOptions.push(option);
        }
      });
    }

    if (hasEmptyOption) {
      return dispatch({ upsert: { error: 'An option must not be empty.' } });
    }
    if (hasDuplicate) {
      return dispatch({ upsert: { error: 'Options should be unique' } });
    }
    if (!validOptions.length) {
      return dispatch({ upsert: { error: 'Please add at least 1 option' } });
    }
    const payload = {
      name: values.newvari_name.trim(),
      code: values.newvari_code ? values.newvari_code.trim() : null,
      options: validOptions,
      product_id: props.productId
    };
    if (data.id) {
      payload.id = data.id;
    }
    if (JSON.stringify(payload.options) !== JSON.stringify(data.options) || payload.name !== data.name || payload.code !== data.code) {
      const { status } = await variationPost(payload);

      if (status === 200) {
        dispatch({ upsert: { reload: true } });
        dispatch({ delete: ['drawer'] });
      }
    } else {
      dispatch({ delete: ['drawer'] });
    }
  };

  useEffect(() => {
    if (data.id) {
      form.setFieldsValue({
        newvari_name: data.name,
        newvari_code: data.code,
        newvari_options: data.options
      });
    }
  }, []);

  return (
    <Form
      form={form}
      layout='vertical'
      onFinish={onFinish}
      onValuesChange={Utils.clearError(dispatch)}
    >
      <Form.Item
        label='Variation name'
        name='newvari_name'
        rules={[{
          required: true,
          whitespace: true,
          pattern: '^[a-zA-Z0-9ñÑ. ]*$',
          message: 'Please enter a variation name'
        }]}
      >
        <Input
          minLength={1}
          maxLength={50}
          placeholder='Variation name'
        />
      </Form.Item>

      <Form.Item label='Variation options' required>
        <Form.List name='newvari_options'>
          {(fields, { add, remove }) => (
            <>
              {fields.map(field => (
                <Form.Item key={field.key}>
                  <Form.Item
                    {...field}
                    noStyle
                    rules={[{
                      required: true,
                      whitespace: true,
                      pattern: '^[a-zA-Z0-9ñÑ. ]*$',
                      message: 'Please enter a valid option'
                    }]}
                  >
                    <Input
                      allowClear
                      minLength={1}
                      maxLength={50}
                      placeholder='Option'
                      onChange={e => e.currentTarget.value ? null : remove(field.name)}
                    />
                  </Form.Item>
                </Form.Item>
              ))}
              <Form.Item>
                <Button onClick={() => add()} type='dashed' block>
                  Add option
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
      </Form.Item>

      {/*
      <Form.Item
        label='Variation Code'
        name='newvari_code'
      >
        <Input
          minLength={1}
          maxLength={50}
          placeholder='Variation code'
        />
      </Form.Item>
      */}

      <Erratum />

      <Form.Item>
        <Button
          block
          type='primary'
          htmlType='submit'
          disabled={state.loading}
          loading={state.loading}
        >
          {data.id ? 'Save changes' : 'Add new variation'}
        </Button>
      </Form.Item>
    </Form>
  );
};

export default VariationForm;
