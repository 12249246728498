import page from 'page';
import PricingView from './PricingView';
import PricingList from './PricingList';

export const pricingRedirect = {
  path: '/pricing',
  priv: 'pricing',
  handler: () => page.redirect('/pricing/all')
};

export const pricingListRoute = {
  path: '/pricing/all',
  priv: 'pricing',
  handler: props => <PricingList {...props} />
};

export const pricingViewRoute = {
  path: '/pricing/:id',
  priv: 'pricing',
  handler: props => <PricingView {...props} />
};
