import { useContext } from 'react';
import { List, Empty, Button, Dropdown, ConfigProvider } from 'antd';
import { AiOutlineMore } from 'react-icons/ai';
import { Dashboard, Ellipsis, Created, Spacer } from '../../../components';
import { Context, Utils } from 'shookt';
import s from '../product.module.less';
import VariationForm from './VariationForm';
import variationDelete from './variationDelete';

const ProductVariations = props => {
  const [, dispatch] = useContext(Context);
  const toDrawer = Dashboard.toDrawer(dispatch);

  return (
    <ConfigProvider renderEmpty={() => (
      <Empty
        image={Empty.PRESENTED_IMAGE_SIMPLE}
        imageStyle={{ height: 60 }}
        description={<>No variations yet</>}
      >
        <Button
          type='primary'
          onClick={toDrawer(
            <VariationForm productId={props.productId} />,
            'New product variation'
          )}
        >
          Add product variation
        </Button>
      </Empty>
    )}
    >
      <List
        className='dashed-list-border'
        dataSource={props.dataSource || []}
        renderItem={item => (
          <List.Item
            extra={(
              <Dropdown
                className='cursor-pointer'
                trigger={['click']}
                menu={{
                  items: [{
                    key: 'delete',
                    label: 'Delete',
                    danger: true,
                    onClick: async () => {
                      const { status } = await variationDelete(item.id);
                      if (status === 200) {
                        dispatch({ upsert: { reload: true } });
                      }
                    }
                  }]
                }}
              >
                <AiOutlineMore />
              </Dropdown>
            )}
          >
            <List.Item.Meta
              title={(
                <Spacer center block>
                  <Ellipsis type='secondary'>
                    ID: {item.id}
                  </Ellipsis>
                  <Ellipsis className={s.variation}>
                    {item.code ? `${item.code}: ` : ''}{item.name}
                  </Ellipsis>
                  <Created item={item} />
                </Spacer>
              )}
              description={(
                <Spacer breakpoint={767} split block>
                  {item.options.map(o => Utils.toCapitalize(o))}
                </Spacer>
              )}
              onClick={toDrawer(<VariationForm productId={props.productId} data={item} key={item.id} />, 'Edit product variation')}
              className='cursor-pointer'
            />
          </List.Item>
        )}
      />
    </ConfigProvider>
  );
};

export default ProductVariations;
