import { useEffect, useContext, useState } from 'react';
import { List, Dropdown } from 'antd';
import { Dashboard, Header, Created, Spacer, Avatar, DataList, Ellipsis } from '../../components';
import { Context, Utils } from 'shookt';
import { AiOutlineMore } from 'react-icons/ai';
import s from './market.module.less';
import page from 'page';
import marketFavoriteGet from './marketFavoriteGet';
import marketFavoritePost from './marketFavoritePost';

const MyFavorites = props => {
  const [state, dispatch] = useContext(Context);
  const [params, setParams] = useState({});
  const [values, setValues] = useState({});

  useEffect(() => {
    if (state.reload) {
      dispatch({ delete: ['reload'] });
    }
    if (params && params.page) {
      dispatch({ upsert: { loading: true } });
      setTimeout(async () => {
        const { data } = await marketFavoriteGet('all', {
          find: params.find,
          sort: params.sort,
          page: params.page || 1,
          pageSize: state.pageSize
        });
        setValues(data);

        const favorites = {};
        const favoriteItems = data.data;

        if (favoriteItems.length) {
          favoriteItems.forEach(item => {
            favorites[item.company_id] = favorites[item.company_id] || { ...item.company, items: [] };
            favorites[item.company_id].items.push(item);
          });
          return dispatch({ upsert: { favorites: Object.keys(favorites).map(id => favorites[id]) } });
        }
        dispatch({ upsert: { favorites: [] } });
      }, 200);
    }
  }, [params, state.reload]);

  return (
    <Dashboard>
      <Header
        noTop
        level={3}
        title='My favorites'
      />
      <DataList
        noBottBox
        values={values}
        storeKey='myfavorites'
        onChange={setParams}
        countLabel='favorites'
        dataSource={state.favorites}
        renderItem={company => (
          <List
            bordered
            className={s.cartcompbox}
            dataSource={company.items}
            renderItem={item => {
              let pricing = 'No price yet';
              if (Array.isArray(item.pricing)) {
                let lowest, highest;
                item.pricing.forEach(p => {
                  if (!lowest || p.unit_price < lowest) {
                    lowest = p.unit_price;
                  }
                  if (!highest || p.unit_price > highest) {
                    highest = p.unit_price;
                  }
                });
                pricing = '';
                if (lowest) {
                  pricing = `₱${Utils.toMonetary(lowest)}`;
                }
                if (highest && highest !== lowest) {
                  if (lowest) {
                    pricing = `${pricing} - `;
                  }
                  pricing = `${pricing}₱${Utils.toMonetary(highest)}`;
                }
              }
              return (
                <List.Item
                  extra={(
                    <Dropdown
                      className='cursor-pointer'
                      trigger={['click']}
                      menu={{
                        items: [{
                          key: 'delete',
                          label: 'Delete',
                          danger: true,
                          onClick: async () => {
                            const { status } = await marketFavoritePost({
                              is_deleted: true,
                              product_id: item.product_id
                            });
                            if (status === 200) {
                              dispatch({ upsert: { reload: true } });
                            }
                          }
                        }]
                      }}
                    >
                      <AiOutlineMore />
                    </Dropdown>
                  )}
                >
                  <List.Item.Meta
                    title={(
                      <Spacer center block>
                        {item.product.name}
                        <Created prefix='Added' item={item} noAvatar />
                      </Spacer>
                    )}
                    avatar={<Avatar size={46} user={item.product} square thumb />}
                    className='cursor-pointer'
                    onClick={() => page(`/market/${item.product_id}`)}
                    description={(
                      <>
                        <Ellipsis type='secondary'>
                          {item.category[0].category.name}
                        </Ellipsis>
                        <Ellipsis className={`${s.price} ${item.pricing ? s.primary : ''}`} type={item.pricing ? '' : 'secondary'} noHover>
                          {pricing}
                        </Ellipsis>
                      </>
                    )}
                  />
                </List.Item>
              );
            }}
            header={(
              <Spacer noBott block align='center' direction='horizontal'>
                <Avatar size={24} user={company} thumb />
                {company.name}
              </Spacer>
            )}
          />
        )}
        sortOptions={[
          { label: 'Newest', value: { column: 'created_at', order: 'desc' } },
          { label: 'Oldest', value: { column: 'created_at', order: 'asc' } }
        ]}
      />

    </Dashboard>
  );
};

export default MyFavorites;
