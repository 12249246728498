import { useEffect, useState } from 'react';
import { Timeline, Typography, Tag } from 'antd';
import { FaMoneyBillWave, FaTruck, FaUser, FaShoppingCart } from 'react-icons/fa';
import { Created } from '../../components';
import orderGet from './orderGet';

const OrderLogs = ({ order }) => {
  const [logs, setLogs] = useState([]);

  useEffect(() => {
    (async () => {
      if (order.id) {
        const { status, data } = await orderGet(order.id, { logs: true });
        if (status === 200) {
          setLogs(data);
        }
      }
    })();
  }, []);

  const timeLines = [];
  logs.forEach(log => {
    let icon = <FaShoppingCart />;
    if (log.type === 'payment') {
      icon = <FaMoneyBillWave />;
    }
    if (log.type === 'delivery') {
      icon = <FaTruck />;
    }
    if (log.type === 'assign') {
      icon = <FaUser />;
    }
    timeLines.push(
      <Timeline.Item
        key={log.id}
        dot={icon}
        color={orderGet.statusToColor(log.status)}
      >
        <Tag color={orderGet.statusToColor(log.status)}>
          {orderGet.statusToLabel(log.status)}
        </Tag>
        <br />
        <Created prefix='Logged' item={log} />
        <br />
        <Typography.Text>
          {log.remarks}
        </Typography.Text>
      </Timeline.Item>
    );
  });

  return (
    <>
      <Typography.Paragraph>
        <Typography.Text type='secondary'>
          Order Id
        </Typography.Text>
        <br />
        {order.code}
      </Typography.Paragraph>
      <br />
      <Timeline>
        {timeLines}
      </Timeline>
    </>
  );
};

export default OrderLogs;
