import { useContext, useEffect, useState } from 'react';
import { Divider, Button, Typography } from 'antd';
import { Dashboard, Timestamp, Header, Desc } from '../../../components';
import { Context, Utils } from 'shookt';
import { FiChevronLeft } from 'react-icons/fi';
import page from 'page';
import batchGet from './batchGet';
import BatchItemList from './BatchItemList';

const BatchView = props => {
  const [state, dispatch] = useContext(Context);
  const [status, setStatus] = useState(0);
  const [inventory, setInventory] = useState({});

  const loadData = () => {
    (async () => {
      const { status, data } = await batchGet(props.params.id);
      if (status === 200) {
        setInventory(data);
      }
      setStatus(status);
    })();
  };

  useEffect(() => {
    if (state.reload) {
      dispatch({ delete: ['reload'] });
    }
    loadData();
  }, [state.reload]);

  return (
    <Dashboard
      viewspin
      status={status}
      back='/inventory/batch'
    >
      <Header
        noTop
        title={(
          <>
            <Button
              shape='circle'
              onClick={() => page('/inventory/batch')}
              icon={<FiChevronLeft />}
            />
            <Typography.Title className='no-bott-margin' level={4}>
              &nbsp;{inventory.batch_no}
            </Typography.Title>
          </>
        )}
      // extra={(
      //   <Button onClick={() => page(`/inventory/form?id=${props.params.id}`)}>
      //     Edit inventory
      //   </Button>
      // )}
      />
      <Desc>
        <Desc.Item label='Batch No./ID'>
          {inventory.batch_no}
        </Desc.Item>
        <Desc.Item label='Arrival date'>
          <Timestamp stamp={inventory.arrival_date} ifEmpty='-' format='MMMM D, YYYY' />
        </Desc.Item>
        <Desc.Item label='ID'>
          {inventory.id}
        </Desc.Item>
        <Desc.Item label='Created at'>
          <Timestamp stamp={inventory.created_at} />
        </Desc.Item>
        <Desc.Item label='Created by'>
          {inventory.created_by && inventory.created_by.id && (Utils.formatName(inventory.created_by))}
        </Desc.Item>
        {
          inventory.company &&
          inventory.company.id &&
          state.user &&
          state.user.privileges.company && (
            <Desc.Item label='Distributor'>
              {inventory.company.name}
            </Desc.Item>
          )
        }
      </Desc>
      <Header title='Products' noBottom />
      <BatchItemList dataSource={inventory.items} />
      <Divider />
    </Dashboard>
  );
};

export default BatchView;
