import { Popover } from 'antd';
import { ChromePicker } from 'react-color';

const ColorPicker = props => {
  return (
    <Popover
      content={
        <ChromePicker
          color={props.value}
          onChange={clr => props.onChange(clr.hex.replace('#', ''))}
        />
      }
    >
      {props.children}
    </Popover>
  );
};

export default ColorPicker;
