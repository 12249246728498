import axios from 'axios';

const imageDelete = async (id, params) => {
  try {
    const response = await axios({
      method: 'delete',
      url: `/product/image/${id}`
    });
    return response;
  } catch ({ response }) {
    return response;
    // internal
  }
};

export default imageDelete;
