import { Result } from 'antd';
import { Panel } from '../../components';

const TooMany = () => {
  return (
    <Panel>
      <Result
        status='warning'
        title='Too Many Requests'
        subTitle='We have received too many requests from your IP address. Please try again later.'
      />
    </Panel>
  );
};

export default TooMany;
