import { useState, useContext } from 'react';
import { Context, Erratum, Utils } from 'shookt';
import { Form, Button } from 'antd';
import { Address } from '../../components';

const CheckoutAddress = props => {
  const [state, dispatch] = useContext(Context);
  const [address] = useState(props.address);
  const [form] = Form.useForm();

  const onFinish = async values => {
    if (!values.delivadd_lat) {
      return dispatch({ upsert: { error: 'Please pin your address in the map' } });
    }
    dispatch({
      upsert: {
        delivadd: {
          housesubd: values.delivadd_housesubd.trim(),
          zipcode: values.delivadd_zipcode,
          region: values.delivadd_region,
          province: values.delivadd_province,
          towncity: values.delivadd_towncity,
          barangay: values.delivadd_barangay,
          lat: values.delivadd_lat,
          lng: values.delivadd_lng
        }
      }
    });
    dispatch({ delete: ['drawer'] });
  };

  return (
    <Form
      form={form}
      layout='vertical'
      onFinish={onFinish}
      onValuesChange={Utils.clearError(dispatch)}
      autoComplete='off'
    >
      <Address
        form={form}
        values={address}
        prefix='delivadd_'
      />

      <Erratum />

      <Form.Item>
        <Button
          block
          type='primary'
          htmlType='submit'
          disabled={state.loading}
          loading={state.loading}
        >
          Update delivery address
        </Button>
      </Form.Item>
    </Form>
  );
};

export default CheckoutAddress;
