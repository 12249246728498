import { Form, Space, Select, Typography } from 'antd';
import { Utils } from 'shookt';
import { Spacer } from '../';

const Variations = props => {
  const body = [];
  const onChange = (name, value) => {
    props.onChange && props.onChange({ [name]: value });
  };

  if (props.view) {
    const variants = [];
    const variations = props.item ? props.item.variation_log || [] : [];
    variations.forEach(v => {
      variants.push(
        <Space direction='horizontal' align='start' key={v.id}>
          <Typography.Text type='secondary'>
            {v.name}:
          </Typography.Text>
          <Typography.Text>
            {Utils.toCapitalize(v.value)}
          </Typography.Text>
        </Space>
      );
    });
    if (!variants.length) {
      variants.push(<Typography.Text key='none' type='secondary'>No variation</Typography.Text>);
    }
    body.push(<Spacer key='view' noBott={props.noBott} split block>{variants}</Spacer>);
  }
  if (props.form) {
    const variations = props.product ? props.product.variations || [] : [];
    const variationLog = props.item ? props.item.variation_log || [] : [];
    variations.forEach(v => {
      let initialValue = null;
      const match = variationLog.filter(l => l.name === v.name)[0];
      if (match) {
        initialValue = match.value;
      }
      if (!initialValue && v.options.length === 1) {
        initialValue = v.options[0];
      }
      body.push(
        <Form.Item
          initialValue={initialValue}
          label={v.name}
          name={`variation:${v.name}`}
          key={v.id}
          rules={[{
            required: true,
            message: 'Please select an option'
          }]}
        >
          <Select
            placeholder={`Select ${v.name.toLowerCase()}`}
            options={v.options.map(o => ({ label: Utils.toCapitalize(o), value: o }))}
            onChange={val => onChange(v.name, val)}
          />
        </Form.Item>
      );
    });
  }

  return body;
};

Variations.getVariations = values => {
  const val = {};
  Object.keys(values).forEach(name => {
    if (name.startsWith('variation:')) {
      val[name.split(':')[1]] = values[name];
    }
  });
  return val;
};

Variations.getVariationLog = (values, product) => {
  const variations = Variations.getVariations(values);
  return product.variations.map(v => ({
    value: variations[v.name],
    options: v.options,
    name: v.name,
    id: v.id
  }));
};

Variations.format = values => {
  const keys = Object.keys(values).sort();
  const formatted = {};
  keys.forEach(k => { formatted[k] = values[k]; });
  return JSON.parse(JSON.stringify(formatted).toLowerCase());
};

export default Variations;
