
import { useContext } from 'react';
import { List, Space, Typography } from 'antd';
import { Context, Utils } from 'shookt';
import { Dashboard, Avatar, Spacer, Variations, Timestamp, Ellipsis } from '../../../components';
import s from '../inventory.module.less';
import BatchItemForm from './BatchItemForm';

const BatchItemList = props => {
  const [, dispatch] = useContext(Context);
  const toDrawer = Dashboard.toDrawer(dispatch);

  return (
    <List
      itemLayout='vertical'
      dataSource={props.dataSource || []}
      renderItem={item => {
        const product = item.product || (item.ledger && item.ledger.product);
        let body = (
          <List.Item.Meta
            avatar={<Avatar shape='square' size={46} user={product} thumb />}
            title={(
              <Ellipsis>
                {product.code}: {product.name}
              </Ellipsis>
            )}
            description={(
              <>
                <Spacer block>
                  <Space>
                    <Typography.Text type='secondary'>
                      Stock Id:
                    </Typography.Text>
                    <Typography.Text>
                      {item.code}
                    </Typography.Text>
                  </Space>
                  <Space>
                    <Typography.Text type='secondary'>
                      Expiry:
                    </Typography.Text>
                    <Typography.Text>
                      {item.expiry && (<Timestamp stamp={item.expiry} format='YYYY/MM/DD' />)}
                      {!item.expiry && 'None'}
                    </Typography.Text>
                  </Space>
                  <Space>
                    <Typography.Text type='secondary'>
                      Initial quantity:
                    </Typography.Text>
                    <Typography.Text className={s.qty}>
                      {Utils.toMonetary(item.quantity, 0)}
                    </Typography.Text>
                  </Space>
                  <Space>
                    <Typography.Text type='secondary'>
                      Unit cost:
                    </Typography.Text>
                    <Typography.Text>
                      ₱{Utils.toMonetary(item.unit_cost)}
                    </Typography.Text>
                  </Space>
                  <Space>
                    <Typography.Text type='secondary'>
                      Total cost:
                    </Typography.Text>
                    <Typography.Text>
                      ₱{Utils.toMonetary(item.unit_cost * item.quantity)}
                    </Typography.Text>
                  </Space>
                </Spacer>
                <Variations item={item} view />
              </>
            )}
          />
        );

        if (item.id) {
          body = (
            <List.Item className='cursor-pointer'>
              <a href={`/inventory/${item.id}`} className={s.block}>
                {body}
              </a>
            </List.Item>
          );
        } else {
          body = (
            <List.Item
              className='cursor-pointer'
              onClick={toDrawer(<BatchItemForm companyId={props.company} item={item} key={item.index} />, 'Add inventory product')}
            >
              {body}
            </List.Item>
          );
        }

        return body;
      }}
    />
  );
};

export default BatchItemList;
