import { useContext, useEffect, useState } from 'react';
import { Dropdown, Typography } from 'antd';
import { CompanySelect, Dashboard, Timestamp, DataList, Spacer, Header } from '../../../components';
import { FiChevronDown } from 'react-icons/fi';
import { Context } from 'shookt';
import page from 'page';
import s from '../inventory.module.less';
import batchGet from './batchGet';

const BatchList = props => {
  const [state, dispatch] = useContext(Context);
  const [values, setValues] = useState({});
  const [params, setParams] = useState({});
  const [company, setCompany] = useState();

  useEffect(() => {
    if (params && params.page) {
      dispatch({ upsert: { loading: true } });
      setTimeout(async () => {
        const { data } = await batchGet('all', {
          company: company || 0,
          find: params.find,
          sort: params.sort,
          page: params.page || 1,
          pageSize: state.pageSize
        });
        dispatch({ upsert: { batches: data.data || [] } });
        setValues(data);
      }, 200);
    }
  }, [params, company]);

  return (
    <Dashboard>
      <Header
        noTop
        level={3}
        title='Stock batches'
        extra={(
          <Dropdown.Button
            type='primary'
            onClick={() => page('/inventory/batch/form')}
            trigger={['click']}
            icon={<FiChevronDown />}
            menu={{
              items: [{
                key: '0',
                label: 'View product ledger',
                onClick: () => page('/inventory/all')
              }]
            }}
          >
            Add new stocks
          </Dropdown.Button>
        )}
      />

      <DataList
        values={values}
        storeKey='batches'
        onChange={setParams}
        countLabel='batches'
        dataSource={state.batches}
        rowUrl={item => `/inventory/batch/${item.id}`}
        extra={CompanySelect.get(state, { allowClear: true, storeKey: 'batches', onChange: setCompany })}
        bodyHandler={item => (
          <div className={s.listtitle}>
            <Spacer>
              <Typography.Paragraph className='no-bott-margin'>
                {item.batch_no}
              </Typography.Paragraph>
              <Typography.Paragraph className='no-bott-margin' type='secondary'>
                Arrival <Timestamp stamp={item.arrival_date} format='MMMM D, YYYY' />
              </Typography.Paragraph>
            </Spacer>
          </div>
        )}
        sortOptions={[
          { label: 'Newest', value: { column: 'created_at', order: 'desc' } },
          { label: 'Oldest', value: { column: 'created_at', order: 'asc' } },
          { label: 'Batch A-Z', value: { column: 'batch_no', order: 'asc' } },
          { label: 'Batch Z-A', value: { column: 'batch_no', order: 'desc' } }
        ]}
      />
    </Dashboard>
  );
};

export default BatchList;
