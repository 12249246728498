import { useState, useContext } from 'react';
import { Context, Mobile, Erratum, Captcha, Code6, Utils } from 'shookt';
import { Form, Typography, Button } from 'antd';
import { authenticate } from '../../session';
import s from './profile.module.less';
import verifyPut from '../verify/verifyPut';
import verifyPost from '../verify/verifyPost';

const ProfileMobile = props => {
  const [state, dispatch] = useContext(Context);
  const [phone, setPhone] = useState('');
  const [done, setDone] = useState(false);
  const [form] = Form.useForm();

  const onFinishCode = async values => {
    const { status } = await verifyPut({ code: values.editprof_code });
    if (status === 200) {
      await authenticate(dispatch);
      dispatch({ delete: ['drawer'] });
    }
  };

  const onFinish = async () => {
    if (state.user.mobile === phone) {
      return dispatch({ delete: ['drawer'] });
    }
    const { status } = await verifyPost({ mobile: phone });
    if (status === 200) {
      setDone(true);
    }
  };

  let body = (
    <Form
      form={form}
      layout='vertical'
      onFinish={onFinish}
      className={s.profileminform}
      onValuesChange={Utils.clearError(dispatch)}
    >
      <Form.Item
        label='New mobile number'
      >
        <Mobile onPhone={setPhone} country='ph' className='ant-input' />
      </Form.Item>

      <Captcha />
      <Erratum />

      <Form.Item>
        <Button
          block
          type='primary'
          htmlType='submit'
          disabled={state.loading}
          loading={state.loading}
        >
          Save changes
        </Button>
      </Form.Item>
    </Form>
  );

  if (done) {
    body = (
      <Form
        layout='vertical'
        onFinish={onFinishCode}
        className={s.profileminform}
        onValuesChange={Utils.clearError(dispatch)}
      >
        <Typography.Paragraph type='secondary' className='text-center'>
          A verification code was sent to your mobile. Please enter the code below to continue.
        </Typography.Paragraph>

        <Form.Item
          label='Verification Code &nbsp;'
          name='editprof_code'
          className='text-center'
          rules={[{
            required: true,
            message: 'Please enter a valid code'
          }]}
        >
          <Code6 centered='true' />
        </Form.Item>

        <Captcha />
        <Erratum />

        <Form.Item>
          <Button
            block
            type='primary'
            htmlType='submit'
            disabled={state.loading}
            loading={state.loading}
          >
            Continue
          </Button>
        </Form.Item>

        <Typography.Paragraph type='warning' className='text-center'>
          It may take some time for the SMS to arrive.
        </Typography.Paragraph>
      </Form>
    );
  }

  return body;
};

export default ProfileMobile;
