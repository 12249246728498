import axios from 'axios';

const distributorGet = async (province= null) => {
  try {
    const url = province ? `/distributor?provincest=${province}` : '/distributor';
    const response = await axios({
      method: 'get',
      url,
    });
    return response;
  } catch ({ response }) {
    return response;
    // Handle errors as needed
  }
};

export default distributorGet;
