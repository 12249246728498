import axios from 'axios';

const recoverPut = async params => {
  try {
    params.useragent = navigator.userAgent;
    const response = await axios({
      method: 'put',
      url: '/recover',
      data: params
    });
    return response;
  } catch ({ response }) {
    return response;
    // notfound
    // conflict
    // internal
  }
};

export default recoverPut;
