import { useContext, useEffect, useState } from 'react';
import { Form, Input, InputNumber, DatePicker, Button, Select } from 'antd';
import { Context, Erratum, Utils } from 'shookt';
import { Variations, Timestamp } from '../../../components';
import productGet from '../../product/productGet';

const BatchItemForm = ({ companyId, item = {} }) => {
  const [state, dispatch] = useContext(Context);
  const [prodOpts, setProdOpts] = useState([]);
  const [product, setProduct] = useState(item.product || null);
  const [findout, setFindout] = useState(null);
  const [form] = Form.useForm();

  const onFinish = async values => {
    const newItem = {
      variation_log: Variations.getVariationLog(values, product),
      variations: Variations.getVariations(values),
      code: values.newitem_code.trim(),
      unit_cost: values.newitem_cost,
      quantity: values.newitem_qty,
      product: {
        id: product.id,
        code: product.code,
        name: product.name,
        avatar: product.avatar,
        avatar_thumb: product.avatar_thumb,
        description: product.description,
        variations: product.variations
      }
    };
    if (values.newitem_expiry) {
      newItem.expiry = values.newitem_expiry.format('YYYY-MM-DD');
    }
    if (item.product) {
      newItem.index = item.index;
    } else if (state.inventoryItems) {
      newItem.index = state.inventoryItems.length;
    } else {
      newItem.index = 0;
    }
    if (item.product) {
      state.inventoryItems[item.index] = newItem;
      dispatch({ upsert: { inventoryItems: state.inventoryItems } });
    } else {
      dispatch({ upsert: { inventoryItems: [...state.inventoryItems || [], newItem] } });
    }
    dispatch({ delete: ['drawer'] });
  };

  const onProdSelect = async val => {
    const { data } = await productGet(val);
    setProduct(data);
  };

  const onProdSearch = val => {
    if (findout) {
      clearTimeout(findout);
    }
    setFindout(setTimeout(async () => {
      if (val) {
        const { data } = await productGet('all', { find: val, company: companyId || 0 });
        setProdOpts(data.data.map(p => ({ label: p.name, value: p.id })));
      }
    }, 250));
  };

  useEffect(() => {
    if (item.product) {
      setProdOpts([{ label: item.product.name, value: item.product.id }]);
      form.setFieldsValue({
        newitem_name: item.product.id,
        newitem_qty: item.quantity,
        newitem_cost: item.unit_cost,
        newitem_code: item.code,
        newitem_expiry: item.expiry ? Timestamp.dayjs(item.expiry) : null
      });
    }
  }, []);

  return (
    <Form
      form={form}
      layout='vertical'
      onFinish={onFinish}
      onValuesChange={Utils.clearError(dispatch)}
    >
      <Form.Item
        label='Product'
        name='newitem_name'
        rules={[{
          required: true,
          message: 'Please select a product'
        }]}
      >
        <Select
          showSearch
          options={prodOpts}
          onSearch={onProdSearch}
          onSelect={onProdSelect}
          placeholder='Search product'
          filterOption={() => true}
        />
      </Form.Item>

      <Variations item={item} product={product} form />

      <Form.Item
        label='Stock Id (Serial No., Stock No., etc.)'
        name='newitem_code'
        rules={[{
          required: true,
          whitespace: true,
          pattern: '^[a-zA-Z0-9ñÑ. ]*$',
          message: 'Please enter a stock identifier'
        }]}
      >
        <Input
          minLength={1}
          maxLength={100}
          placeholder='Stock Id'
        />
      </Form.Item>

      <Form.Item
        label='Quantity'
        name='newitem_qty'
        rules={[{
          required: true,
          message: 'Please enter a quantity above zero'
        }]}
      >
        <InputNumber
          precision={0}
          minLength={1}
          maxLength={15}
          placeholder='Quantity'
          controls
        />
      </Form.Item>

      <Form.Item
        label='Unit cost'
        name='newitem_cost'
        rules={[{
          required: true,
          message: 'Please enter a unit cost above zero'
        }]}
      >
        <InputNumber
          prefix={<>₱</>}
          minLength={1}
          maxLength={15}
          placeholder='0.00'
          controls
        />
      </Form.Item>

      <Form.Item
        label='Expiry date'
        name='newitem_expiry'
      >
        <DatePicker placeholder='Expiry date' format='YYYY/MM/DD' />
      </Form.Item>

      <Erratum />

      <Form.Item>
        <Button
          block
          type='primary'
          htmlType='submit'
          disabled={state.loading}
          loading={state.loading}
        >
          {item.product ? 'Save changes' : 'Add inventory product'}
        </Button>
      </Form.Item>
    </Form>
  );
};

export default BatchItemForm;
