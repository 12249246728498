import { useContext, useEffect } from 'react';
import { Form, Input, Button } from 'antd';
import { Context, Erratum, Utils } from 'shookt';
import { CompanySelect } from '../../components';
import page from 'page';
import pricingGet from './pricingGet';
import pricingPost from './pricingPost';

const PricingForm = props => {
  const [state, dispatch] = useContext(Context);
  const [form] = Form.useForm();

  const onFinish = async values => {
    const payload = {
      name: values.newprici_name.trim(),
      description: values.newprici_description.trim()
    };
    if (values.newprici_company) {
      payload.company_id = values.newprici_company;
    }
    if (props.id) {
      payload.id = props.id;
    }
    const { status, data } = await pricingPost(payload);
    if (status === 200) {
      if (props.id) {
        dispatch({ upsert: { reload: true } });
      } else {
        page(`/pricing/${data.id}`);
      }
      dispatch({ delete: ['drawer'] });
    }
  };

  useEffect(() => {
    (async () => {
      if (props.id) {
        const { status, data } = await pricingGet(props.id);
        if (status === 200) {
          form.setFieldsValue({
            newprici_company: data.company_id,
            newprici_name: data.name,
            newprici_description: data.description
          });
        }
      }
    })();
  }, []);

  return (
    <Form
      form={form}
      layout='vertical'
      onFinish={onFinish}
      onValuesChange={Utils.clearError(dispatch)}
    >
      <CompanySelect
        name='newprici_company'
        rules={[{
          required: true,
          message: 'Please select a distributor'
        }]}
      />

      <Form.Item
        label='Pricing name'
        name='newprici_name'
        rules={[{
          required: true,
          whitespace: true,
          pattern: '^[a-zA-Z0-9ñÑ. ]*$',
          message: 'Please enter a pricing name'
        }]}
      >
        <Input
          minLength={1}
          maxLength={100}
          placeholder='Pricing name'
        />
      </Form.Item>

      <Form.Item
        label='Description'
        name='newprici_description'
        rules={[{
          max: 300,
          required: true,
          whitespace: true,
          message: 'Please enter a description'
        }]}
      >
        <Input.TextArea placeholder='Description' maxLength={300} autoSize={{ minRows: 2 }} showCount />
      </Form.Item>

      <Erratum />

      <Form.Item>
        <Button
          block
          type='primary'
          htmlType='submit'
          disabled={state.loading}
          loading={state.loading}
        >
          {props.id ? 'Save changes' : 'Add new pricing'}
        </Button>
      </Form.Item>
    </Form>
  );
};

export default PricingForm;
