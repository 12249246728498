import { useContext, useEffect, useState } from 'react';
import { Form, Select } from 'antd';
import { Context } from 'shookt';
import s from './companyselect.module.less';
import companyGet from '../../pages/company/companyGet';

const CompanySelect = props => {
  const [state] = useContext(Context);
  const [options, setOptions] = useState([]);
  const [company, setCompany] = useState(null);

  const onChange = (val = null) => {
    props.onChange && props.onChange(val || 0);
    if (val) {
      props.storeKey && sessionStorage.setItem(`${props.storeKey}_company`, val);
    } else {
      props.storeKey && sessionStorage.removeItem(`${props.storeKey}_company`);
    }
    setCompany(val);
  };

  useEffect(() => {
    (async () => {
      if (!state.user || !state.user.privileges.company) {
        return null;
      }

      const comp = sessionStorage.getItem(`${props.storeKey}_company`);
      props.onChange && props.onChange(comp || 0);
      const { status, data } = await companyGet('all');
      if (status === 200) {
        setOptions(data.data.map(i => ({
          label: i.name,
          value: i.id
        })));
        if (props.storeKey) {
          setCompany(comp ? String(comp) : null);
        }
      }
    })();
  }, []);

  if (!state.user || !state.user.privileges.company) {
    return null;
  }

  let body = (
    <Select
      showSearch
      value={company}
      style={props.style || {}}
      options={options}
      onChange={onChange}
      disabled={props.disabled}
      className={s.select}
      allowClear={props.allowClear || false}
      placeholder='Select distributor'
      optionFilterProp='children'
      filterOption={(input, option) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
    />
  );
  if (props.name) {
    body = (
      <Form.Item
        name={props.name}
        label={props.label || 'Distributor'}
        rules={props.rules || []}
      >
        {body}
      </Form.Item>
    );
  }

  return body;
};

CompanySelect.get = (state, props) => {
  if (!state.user || !state.user.privileges.company) {
    return null;
  }
  return <CompanySelect {...props} />;
};

export default CompanySelect;
