import page from 'page';
import InventoryList from './InventoryList';
import InventoryView from './InventoryView';
import BatchForm from './batch/BatchForm';
import BatchList from './batch/BatchList';
import BatchView from './batch/BatchView';

export const inventoryRedirect = {
  path: '/inventory',
  priv: 'inventory',
  handler: () => page.redirect('/inventory/all')
};

export const inventoryListRoute = {
  path: '/inventory/all',
  priv: 'inventory',
  handler: props => <InventoryList {...props} />
};

export const inventoryViewRoute = {
  path: '/inventory/:id',
  priv: 'inventory',
  handler: props => <InventoryView {...props} />
};

export const batchListRoute = {
  path: '/inventory/batch',
  priv: 'inventory',
  handler: props => <BatchList {...props} />
};

export const batchFormRoute = {
  path: '/inventory/batch/form',
  priv: 'inventory',
  handler: props => <BatchForm {...props} />
};

export const batchViewRoute = {
  path: '/inventory/batch/:id',
  priv: 'inventory',
  handler: props => <BatchView {...props} />
};
