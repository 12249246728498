import { useContext, useState, useEffect } from 'react';
import { Row, Col, Tag, Space, Select } from 'antd';
import { FaMoneyBillWave, FaShoppingCart } from 'react-icons/fa';
import { Context } from 'shookt';
import { Dashboard, Header, DataList, Ellipsis, Spacer, CompanySelect } from '../../components';
import orderGet from '../order/orderGet';
import deliveryGet from './deliveryGet';

const DeliveryList = props => {
  const [state, dispatch] = useContext(Context);
  const [values, setValues] = useState({});
  const [params, setParams] = useState({});
  const [company, setCompany] = useState();
  const [status, setStatus] = useState(null);

  useEffect(() => {
    if (params && params.page) {
      dispatch({ upsert: { loading: true } });
      setTimeout(async () => {
        const { data } = await deliveryGet('all', {
          company: company || 0,
          find: params.find,
          sort: params.sort,
          page: params.page || 1,
          pageSize: state.pageSize,
          status
        });
        dispatch({ upsert: { delivery: data.data || [] } });
        setValues(data);
      }, 200);
    }
  }, [params, company, status]);

  return (
    <Dashboard>
      <Header
        noTop
        level={3}
        title='Deliveries'
      />
      <DataList
        values={values}
        storeKey='delivery'
        onChange={setParams}
        countLabel='deliveries'
        dataSource={state.delivery}
        searchPlaceholder='Search order code'
        topPager={CompanySelect.get(state, { allowClear: true, storeKey: 'order', onChange: setCompany })}
        rowUrl={item => `/delivery/${item.id}`}
        extra={(
          <Select
            allowClear
            placeholder='Select status'
            onChange={setStatus}
            value={status}
            style={{ width: '100%' }}
            options={orderGet.typeStatus.delivery}
          />
        )}
        bodyHandler={item => (
          <Row gutter={[10, 10]}>
            <Col xs={24} lg={5} xl={7}>
              <Ellipsis>
                <Tag color={orderGet.toStatusColor(item.order && item.order.status_delivery)}>
                  <Space align='center'>
                    <FaMoneyBillWave />
                    {orderGet.statusToLabel(item.order && item.order.status_delivery)}
                  </Space>
                </Tag>&nbsp;
                {item.method ? orderGet.methodCodeToName(item.method) : 'No method yet'}
              </Ellipsis>
            </Col>
            <Col xs={24} lg={19} xl={17}>
              <Spacer size='small' direction='horizontal'>
                <Ellipsis>
                  <Tag color={orderGet.toStatusColor(item.order && item.order.status)}>
                    <Space align='center'>
                      <FaShoppingCart />
                      {orderGet.statusToLabel(item.order && item.order.status)}
                    </Space>
                  </Tag>&nbsp;
                  {item.order.code}
                </Ellipsis>
              </Spacer>
            </Col>
          </Row>
        )}
        sortOptions={[
          { label: 'Newest', value: { column: 'created_at', order: 'desc' } },
          { label: 'Oldest', value: { column: 'created_at', order: 'asc' } }
        ]}
      />
    </Dashboard>
  );
};

export default DeliveryList;
