import page from 'page';
import RoleView from './RoleView';
import RoleList from './RoleList';

export const roleRedirect = {
  path: '/role',
  priv: 'role',
  handler: () => page.redirect('/role/all')
};

export const roleListRoute = {
  path: '/role/all',
  priv: 'role',
  handler: props => <RoleList {...props} />
};

export const roleViewRoute = {
  path: '/role/:id',
  priv: 'role',
  handler: props => <RoleView {...props} />
};
