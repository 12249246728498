import { useContext } from 'react';
import { Avatar as Avahtar, Tooltip } from 'antd';
import { Context, Utils } from 'shookt';
import { FaUserAlt } from 'react-icons/fa';
import { Ellipsis } from '..';
import s from './avatar.module.less';

const generateCode = usr => {
  if (usr.last_name && usr.first_name) {
    return `${usr.first_name[0]}${usr.last_name[0]}`.toUpperCase();
  }
  if (usr.name) {
    const strArr = usr.name.split(' ');
    return `${strArr[0][0]}${strArr[1] ? strArr[1][0] : (usr.name[1] || '')}`.trim().toUpperCase();
  }
  return '';
};

const Avatar = props => {
  const [state] = useContext(Context);
  const user = props.user || state.user || {};
  const size = props.size || 36;
  let color = `#${Utils.strToHex(user.name || user.fullName || Utils.formatName(user) || '')}`;
  let src = props.src || user.avatar || <>{generateCode(user)}</>;
  if (user.avatar_thumb && props.thumb) {
    src = user.avatar_thumb;
  }
  if (color === '#000000' || props.src || user.avatar) {
    color = '#fff';
  }
  let lineHeight;
  switch (size) {
    case 20:
      lineHeight = 18;
      break;
    case 36:
      lineHeight = 34;
      break;
    case 46:
      lineHeight = 40;
      break;
    case 128:
      lineHeight = 116;
      break;
    case 132:
      lineHeight = 119;
      break;
    default:
      lineHeight = parseInt(size * 0.90);
  }

  let body = (
    <div
      className={s['avatar-box']}
      onClick={props.onClick || null}
    >
      <Avahtar
        className={s.avatar}
        shape={props.square ? 'square' : (props.shape || 'circle')}
        icon={<FaUserAlt />}
        size={size}
        src={src}
        style={{
          lineHeight: `${lineHeight}px`,
          backgroundColor: color,
          border: `${size < 40 ? 1 : 2}px solid ${props.borderColor || '#f2f2f2'}`,
          ...props.style
        }}
      />
    </div>
  );

  if (props.tooltip) {
    body = (
      <Tooltip
        trigger={['click']}
        title={(
          <Ellipsis className='text-white'>
            {props.tooltip}
          </Ellipsis>
        )}
      >
        <div onClick={e => { e.preventDefault(); e.stopPropagation(); }}>
          {body}
        </div>
      </Tooltip>
    );
  }

  return body;
};

export default Avatar;
