import { useContext, useEffect, useState } from 'react';
import { Dropdown, Typography, Space, Collapse, Row, Col } from 'antd';
import { CompanySelect, Dashboard, Avatar, Ellipsis, DataList, Spacer, Header } from '../../components';
import { FiChevronDown } from 'react-icons/fi';
import { Context, Utils } from 'shookt';
import page from 'page';
import s from './inventory.module.less';
import productGet from '../product/productGet';
import InventoryItems from './InventoryItems';

const InventoryList = props => {
  const [state, dispatch] = useContext(Context);
  const [values, setValues] = useState({});
  const [params, setParams] = useState({});
  const [company, setCompany] = useState();

  useEffect(() => {
    if (params && params.page) {
      dispatch({ upsert: { loading: true } });
      setTimeout(async () => {
        const { data } = await productGet('all', {
          inventory: true,
          company: company || 0,
          find: params.find,
          sort: params.sort,
          page: params.page || 1,
          pageSize: state.pageSize
        });
        dispatch({ upsert: { inventory: data.data || [] } });
        setValues(data);
      }, 200);
    }
  }, [params, company]);

  return (
    <Dashboard>
      <Header
        noTop
        level={3}
        title='Inventory'
        extra={(
          <Dropdown.Button
            type='primary'
            onClick={() => page('/inventory/batch/form')}
            trigger={['click']}
            icon={<FiChevronDown />}
            menu={{
              items: [{
                key: '0',
                label: 'View stock batches',
                onClick: () => page('/inventory/batch')
              }]
            }}
          >
            Add new stocks
          </Dropdown.Button>
        )}
      />
      <DataList
        noHover
        noPadding
        noBottBox
        values={values}
        storeKey='inventory'
        onChange={setParams}
        countLabel='products'
        dataSource={state.inventory}
        bodyHandler={item => {
          let quantity = 'Out of stock';
          if (Array.isArray(item.inventory)) {
            quantity = 0;
            item.inventory.forEach(stock => {
              if (stock.ledger.current_value) {
                quantity += stock.ledger.current_value;
              }
            });
            quantity = `Total Quantity: ${Utils.toMonetary(quantity, 0)}`;
          }
          return (
            <Collapse key={item.id} bordered={false} className='collapse-list dashed-list-border' expandIconPosition='end' ghost>
              <Collapse.Panel
                header={(
                  <Row gutter={[10, 10]} className={s.panel} wrap={false}>
                    <Col flex='none'>
                      <Avatar shape='square' size={46} user={item} thumb />
                    </Col>
                    <Col flex='auto'>
                      <Row gutter={[13, 10]} className={s.top} wrap={false}>
                        <Col flex='none'>
                          <Ellipsis className={s.product}>
                            {item.code}: {item.name}
                          </Ellipsis>
                        </Col>
                        <Col flex='auto'>
                          <Ellipsis className={s.desc} type='secondary'>
                            {Utils.toCapitalize(item.description)}
                          </Ellipsis>
                        </Col>
                      </Row>
                      <Spacer block center className={s.bott}>
                        <Ellipsis className={s.qty2} type={item.inventory ? '' : 'danger'}>
                          {quantity}
                        </Ellipsis>
                        <Ellipsis className={s.category} type='secondary'>
                          {item.category && item.category.name}
                        </Ellipsis>
                        {
                          state.user &&
                          state.user.privileges.company && (
                            <Space align='center'>
                              <Typography.Text type='secondary'>
                                Distributor
                              </Typography.Text>
                              <Avatar
                                tooltip={item.company.name}
                                user={item.company}
                                size={20}
                                thumb
                              />
                            </Space>
                          )
                        }
                      </Spacer>
                    </Col>
                  </Row>
                )}
              >
                <InventoryItems inventory={item.inventory} />
              </Collapse.Panel>
            </Collapse>
          );
        }}
        extra={CompanySelect.get(state, { allowClear: true, storeKey: 'inventory', onChange: setCompany })}
        sortOptions={[
          { label: 'Newest', value: { column: 'created_at', order: 'desc' } },
          { label: 'Oldest', value: { column: 'created_at', order: 'asc' } },
          { label: 'Updated', value: { column: 'updated_at', order: 'desc' } },
          { label: 'Name A-Z', value: { column: 'name', order: 'asc' } },
          { label: 'Name Z-A', value: { column: 'name', order: 'desc' } }
        ]}
      />
    </Dashboard>
  );
};

export default InventoryList;
