import axios from 'axios';

const pricingPost = async params => {
  try {
    const response = await axios({
      method: 'post',
      url: '/pricing',
      data: params
    });
    return response;
  } catch ({ response }) {
    return response;
    // notfound
    // internal
  }
};

export default pricingPost;
