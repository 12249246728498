import { useEffect, useContext, useState } from 'react';
import { Button, List, Typography, Dropdown, Space } from 'antd';
import { Dashboard, Header, Created, Spacer, Variations, Avatar, Ellipsis } from '../../components';
import { ShoppingCartOutlined } from '@ant-design/icons';
import { AiOutlineMore } from 'react-icons/ai';
import { Context, Utils } from 'shookt';
import s from './market.module.less';
import marketItemGet from './marketItemGet';
import marketItemDelete from './marketItemDelete';
import MarketForm from './MarketForm';

const MyCart = props => {
  const [state, dispatch] = useContext(Context);
  const [total, setTotal] = useState(0);
  const toDrawer = Dashboard.toDrawer(dispatch);

  useEffect(() => {
    dispatch({ delete: ['buy'] });
    (async () => {
      if (state.reload) {
        dispatch({ delete: ['reload'] });
      }
      const { data } = await marketItemGet('all');
      const cart = {};
      if (data.length) {
        let total = 0;
        data.forEach(item => {
          cart[item.company_id] = cart[item.company_id] || { ...item.company, items: [], total: 0 };
          const selections = JSON.parse(JSON.stringify(item.variations).toLowerCase());
          const price = item.pricing.filter(p => {
            let match = true;
            for (const s in selections) {
              if (p.variations[s] !== selections[s]) {
                match = false;
              }
            }
            return match;
          })[0];
          if (price) {
            item.price = parseFloat(price.unit_price);
            cart[item.company_id].total += item.price;
            total += item.price * item.quantity;
          }
          cart[item.company_id].items.push(item);
        });
        setTotal(total);
        return dispatch({ upsert: { cart: Object.keys(cart).map(id => cart[id]) } });
      }
      setTotal(0);
      dispatch({ upsert: { cart: [] } });
    })();
  }, [state.reload]);

  return (
    <Dashboard>
      <Header
        noTop
        level={3}
        title='My cart'
        extra={<span className={s.total}>Total ₱{Utils.toMonetary(total)}</span>}
      />
      <List
        dataSource={state.cart}
        renderItem={company => (
          <List
            bordered
            className={s.cartcompbox}
            dataSource={company.items}
            renderItem={item => (
              <List.Item
                extra={(
                  <Dropdown
                    className='cursor-pointer'
                    trigger={['click']}
                    menu={{
                      items: [{
                        key: 'delete',
                        label: 'Delete',
                        danger: true,
                        onClick: async () => {
                          const { status } = await marketItemDelete(item.id);
                          if (status === 200) {
                            dispatch({ upsert: { reload: true } });
                          }
                        }
                      }]
                    }}
                  >
                    <AiOutlineMore />
                  </Dropdown>
                )}
              >
                <List.Item.Meta
                  title={(
                    <Spacer center block>
                      {item.product.name}
                      {item.variation_log.length > 0 && <Variations item={item} noBott view />}
                      <Created prefix='Added' item={item} noAvatar />
                    </Spacer>
                  )}
                  description={(
                    <>
                      <Spacer split block>
                        <Space>
                          <Typography.Text type='secondary'>
                            Quantity:
                          </Typography.Text>
                          <Typography.Text className={s.qty}>
                            {Utils.toMonetary(item.quantity, 0)}
                          </Typography.Text>
                        </Space>
                        <Space align='start'>
                          <Typography.Text type='secondary'>
                            Price:
                          </Typography.Text>
                          <Typography.Text>
                            {item.price && (<span className={s.primary}>₱{Utils.toMonetary(item.price)}</span>)}
                            {!item.price && (<>No price yet</>)}
                          </Typography.Text>
                        </Space>
                        {
                          item.price && (
                            <Space>
                              <Typography.Text type='secondary'>
                                Sub-total:
                              </Typography.Text>
                              <Typography.Text>
                                ₱{Utils.toMonetary(item.price * item.quantity)}
                              </Typography.Text>
                            </Space>
                          )
                        }
                      </Spacer>
                    </>
                  )}
                  avatar={<Avatar size={46} user={item.product} square thumb />}
                  className='cursor-pointer'
                  onClick={toDrawer(<MarketForm productId={item.product_id} itemPrice={item.price} item={item} key={item.id} />, 'Update cart item')}
                />
              </List.Item>
            )}
            header={(
              <Spacer noBott block align='center' direction='horizontal'>
                <Avatar size={24} user={company} thumb />
                <Ellipsis style={!state.screens.lg && { width: 'calc(100vw - 130px)' }}>
                  {company.name}
                </Ellipsis>
              </Spacer>
            )}
          />
        )}
      />
      <Header
        noTop
        level={3}
        extra={(
          <Spacer direction='horizontal' align='center' size='large'>
            <span className={s.total}>Total ₱{Utils.toMonetary(total)}</span>
            <Button
              block
              href='/checkout'
              type='primary'
              icon={<ShoppingCartOutlined />}
              disabled={!total}
            >
              Checkout
            </Button>
          </Spacer>
        )}
      />
    </Dashboard>
  );
};

export default MyCart;
