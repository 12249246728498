import { Row, Col, List } from 'antd';
import { Ellipsis } from '../../components';
import s from './company.module.less';
import page from 'page';

const CompanyRoles = props => {
  // TODO improve this, show privs
  return (
    <List
      dataSource={props.dataSource}
      renderItem={item => (
        <List.Item
          onClick={() => page(`/role/${item.id}`)}
          className='cursor-pointer'
        >
          <Row gutter={[10, 10]} className={s.rolebox}>
            <Col xs={8} md={6}>
              <div
                style={{
                  borderLeft: `2px solid #${item.color || 'cccccc'}`,
                  paddingLeft: 7
                }}
              >
                <Ellipsis className='lisht-content'>
                  {item.name}
                </Ellipsis>
              </div>
            </Col>
            <Col xs={16} md={18}>
              <Ellipsis type='secondary'>
                {item.description}
              </Ellipsis>
            </Col>
          </Row>
        </List.Item>
      )}
    />
  );
};

export default CompanyRoles;
