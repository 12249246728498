import { useContext, useEffect, useState } from 'react';
import { Row, Col, List, Space, Button, Card, Typography, Avatar } from 'antd';
import { Dashboard, Timestamp, Avatar as Avatahr, Header, Desc, Ellipsis } from '../../components';
import { Context, Utils } from 'shookt';
import { FiChevronLeft } from 'react-icons/fi';
import { AiOutlineStop } from 'react-icons/ai';
import { BsCheckCircle } from 'react-icons/bs';
import s from './role.module.less';
import page from 'page';
import roleGet from './roleGet';
import RoleForm from './RoleForm';

const RoleView = props => {
  const [state, dispatch] = useContext(Context);
  const [status, setStatus] = useState(0);
  const [role, setRole] = useState({});
  const toDrawer = Dashboard.toDrawer(dispatch);
  const privileges = [];
  if (role.privileges) {
    for (const priv in state.user.privileges) {
      privileges.push({
        isEnabled: priv in role.privileges,
        description: state.user.privileges[priv]
      });
    }
  }

  const loadData = () => {
    (async () => {
      const { status, data } = await roleGet(props.params.id);
      if (status === 200) {
        setRole(data);
      }
      setStatus(status);
    })();
  };

  useEffect(() => {
    if (state.reload) {
      dispatch({ delete: ['reload'] });
    }
    loadData();
  }, [state.reload]);

  return (
    <Dashboard
      viewspin
      status={status}
      back='/role/all'
    >
      <Header
        noTop
        title={(
          <>
            <Button
              shape='circle'
              onClick={() => window.history.back()}
              icon={<FiChevronLeft />}
            />
            <Typography.Title className='no-bott-margin' level={4}>
              &nbsp;{role.name}
            </Typography.Title>
            <Avatar
              size={12}
              className={s.avatar}
              style={{ background: `#${role.color}` }}
            />
          </>
        )}
        extra={(
          <Button
            disabled={state.user.role_id === props.params.id}
            onClick={toDrawer(<RoleForm id={role.id} key={role.id} />, 'Update role information')}
          >
            Edit role
          </Button>
        )}
      />
      <Row gutter={[20, 20]}>
        <Col xs={24} sm={7} lg={7}>
          <List
            bordered
            header={<>Privileges</>}
            dataSource={privileges}
            renderItem={item => {
              const icon = item.isEnabled ? <BsCheckCircle /> : <AiOutlineStop />;
              return (
                <List.Item>
                  <Space align='start' size='large'>
                    <Typography.Text type={item.isEnabled ? 'success' : 'secondary'}>
                      {icon}
                    </Typography.Text>
                    <Typography.Text type={item.isEnabled ? 'primary' : 'secondary'}>
                      {item.description}
                    </Typography.Text>
                  </Space>
                </List.Item>
              );
            }}
          />
        </Col>
        <Col xs={24} sm={17} lg={17}>
          <Desc>
            <Desc.Item span={3}>
              {role.description}
            </Desc.Item>
            <Desc.Item label='Name'>
              {role.name}
            </Desc.Item>
            <Desc.Item label='Last update'>
              <Timestamp stamp={role.updated_at} />
            </Desc.Item>
            <Desc.Item label='Last update by'>
              {role.updated_by && role.updated_by.id && (Utils.formatName(role.updated_by))}
            </Desc.Item>
            <Desc.Item label='ID'>
              {role.id}
            </Desc.Item>
            <Desc.Item label='Created at'>
              <Timestamp stamp={role.created_at} />
            </Desc.Item>
            <Desc.Item label='Created by'>
              {role.created_by && role.created_by.id && (Utils.formatName(role.created_by))}
            </Desc.Item>
            {
              role.company &&
              role.company.id &&
              state.user &&
              state.user.privileges.company && (
                <Desc.Item label='Distributor'>
                  {role.company.name}
                </Desc.Item>
              )
            }
          </Desc>
        </Col>
      </Row>
      <Header title='Users' />
      <List
        dataSource={role.users}
        renderItem={item => (
          <List.Item>
            <Card
              onClick={() => page(`/user/${item.id}`)}
              className='cursor-pointer'
            >
              <Avatahr thumb size={64} user={item} />
              <div className={s.usersbox}>
                <Ellipsis className={`text-center ${s.usersname}`}>
                  {Utils.formatName(item)}
                </Ellipsis>
              </div>
            </Card>
          </List.Item>
        )}
        grid={{ gutter: 16, xs: 1, sm: 2, md: 3, lg: 4 }}
      />
    </Dashboard>
  );
};

export default RoleView;
