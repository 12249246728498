import { useContext, useEffect, useState } from 'react';
import { Button, Typography, Space, Tag } from 'antd';
import { Context, Utils } from 'shookt';
import { FiChevronLeft } from 'react-icons/fi';
import { Dashboard, Header, DataList, Timestamp, Variations, Ellipsis, Created, Spacer } from '../../components';
import s from './inventory.module.less';
import inventoryItemGet from './inventoryItemGet';
import InventoryForm from './InventoryForm';

const InventoryView = props => {
  const [state, dispatch] = useContext(Context);
  const [status, setStatus] = useState(0);
  const [values, setValues] = useState({});
  const [product, setProduct] = useState({});
  const [inventoryItem, setInventoryItem] = useState({});
  const toDrawer = Dashboard.toDrawer(dispatch);

  const loadData = ({ find, sort, page = 1 } = {}) => {
    dispatch({ upsert: { loading: true } });
    setTimeout(async () => {
      const { status, data } = await inventoryItemGet(props.params.id, {
        sort, page, find, pageSize: state.pageSize
      });
      setStatus(status);
      setValues(data);
      if (data.data.length) {
        setProduct(data.data[0].product);
        setInventoryItem(data.data[0].inventory_item);
      }
    }, 200);
  };

  useEffect(() => {
    if (state.reload) {
      dispatch({ delete: ['reload'] });
    }
    loadData();
  }, [state.reload]);

  return (
    <Dashboard
      viewspin
      status={status}
      back='/inventory/all'
    >
      <Header
        noTop
        title={(
          <>
            <Button
              shape='circle'
              onClick={() => window.history.back()}
              icon={<FiChevronLeft />}
            />
            <Typography.Title className='no-bott-margin' level={4}>
              <Space>
                <Typography.Text type='secondary'>
                  Stock Id:
                </Typography.Text>
                <Ellipsis>
                  {inventoryItem.code}
                </Ellipsis>
              </Space>
            </Typography.Title>
          </>
        )}
      />
      <Spacer block center>
        <Space>
          <Typography.Text type='secondary'>
            Product:
          </Typography.Text>
          <Ellipsis>
            {product.code}: {product.name}
          </Ellipsis>
        </Space>
        <Space>
          <Typography.Text type='secondary'>
            Unit price:
          </Typography.Text>
          <Ellipsis>
            ₱{Utils.toMonetary(inventoryItem.unit_cost)}
          </Ellipsis>
        </Space>
        <Space>
          <Typography.Text type='secondary'>
            Expiry:
          </Typography.Text>
          <Typography.Text>
            {inventoryItem.expiry && (<Timestamp stamp={inventoryItem.expiry} format='YYYY/MM/DD' />)}
            {!inventoryItem.expiry && 'None'}
          </Typography.Text>
        </Space>
      </Spacer>
      <Variations item={inventoryItem} noBott view />
      <Header
        title='Ledger'
        bottom={5}
        extra={(
          <Button danger onClick={toDrawer(<InventoryForm product={product} inventoryitem={inventoryItem} />, 'Quantity correction')}>
            Amend
          </Button>
        )}
      />
      <DataList
        noHover
        noBottBox
        values={values}
        onChange={loadData}
        dataSource={values.data}
        bodyHandler={item => (
          <>
            <Spacer className={s.ledge}>
              <Tag className={s.ledgetype}>{Utils.toCapitalize(item.type)}</Tag>
              <Space>
                <Typography.Text type='secondary'>Current value: </Typography.Text>
                <Typography.Text>{Utils.toMonetary(item.current_value, 0)}</Typography.Text>
              </Space>
              <Space>
                <Typography.Text type='secondary'>Previous value: </Typography.Text>
                <Typography.Text>{Utils.toMonetary(item.previous_value, 0)}</Typography.Text>
              </Space>
            </Spacer>
            <Spacer>
              <Created prefix='Updated' item={item} />
              <Space>
                <Typography.Text type='secondary'>Remarks: </Typography.Text>
                <Typography.Text>{item.remarks || 'None'}</Typography.Text>
              </Space>
            </Spacer>
          </>
        )}
        sortOptions={[
          { label: 'Newest', value: { column: 'created_at', order: 'desc' } },
          { label: 'Oldest', value: { column: 'created_at', order: 'asc' } }
        ]}
      />
    </Dashboard>
  );
};

export default InventoryView;
