import { useContext, useEffect } from 'react';
import { useWindowSize } from 'rooks';
import { Context, GoogleMap, Utils } from 'shookt';
import { FiChevronDown } from 'react-icons/fi';
import { Row, Col, Button, Dropdown, Tooltip, notification } from 'antd';
import { Dashboard, Address, Avatar, Timestamp, Picture, Header, Desc, Banko } from '../../components';
import s from './profile.module.less';
import ProfileDocuments from './ProfileDocuments';
import ProfilePassword from './ProfilePassword';
import ProfileAddress from './ProfileAddress';
import ProfileMobile from './ProfileMobile';
import ProfileEmail from './ProfileEmail';
import ProfilePhoto from './ProfilePhoto';
import ProfileForm from './ProfileForm';

const ProfileView = props => {
  const [state, dispatch] = useContext(Context);
  const toDrawer = Dashboard.toDrawer(dispatch);
  const { innerWidth } = useWindowSize();
  let user = {};
  if (state.user) {
    user = state.user;
  }
  let address = {};
  if (user && user.address) {
    address = user.address;
  }
  const zoom = address.lat ? 16 : 9;
  const lat = address.lat ? parseFloat(address.lat) : '';
  const lng = address.lng ? parseFloat(address.lng) : '';
  const imgCol = { xs: { order: 1 }, lg: { order: 2 } };
  const desCol = { xs: { order: 2 }, lg: { order: 1 } };
  if (innerWidth < 992) {
    imgCol.xs.span = 24;
    desCol.xs.span = 24;
  }

  useEffect(() => {
    const qry = JSON.parse(state.query);
    if (qry.success) {
      notification.success({
        message: 'BanKo Account Linked',
        description: 'You have successfully linked your BanKo account.'
      });
    } else if (qry.failed) {
      notification.error({
        message: 'BanKo Account Link Failed',
        description: 'Your BanKo account link failed. Please try again.'
      });
    }
  }, []);

  return (
    <Dashboard>
      <Header
        noTop
        level={3}
        title='My profile'
        extra={(
          <Dropdown.Button
            onClick={toDrawer(<ProfileForm />, 'Update profile information')}
            trigger={['click']}
            icon={<FiChevronDown />}
            menu={{
              items: [
                {
                  key: '0',
                  label: 'Change password',
                  onClick: toDrawer(<ProfilePassword />, 'Change account password')
                },
                {
                  key: '1',
                  label: 'Change address',
                  onClick: toDrawer(<ProfileAddress />, 'Change home address')
                },
                {
                  key: '2',
                  label: 'Update documents',
                  onClick: toDrawer(<ProfileDocuments />, 'Update document photos')
                },
                {
                  key: '3',
                  label: 'Update photo',
                  onClick: toDrawer(<ProfilePhoto />, 'Update profile photo')
                },
                {
                  key: '4',
                  label: 'Edit mobile',
                  onClick: toDrawer(<ProfileMobile />, 'Edit account mobile')
                },
                {
                  key: '5',
                  label: 'Edit email',
                  onClick: toDrawer(<ProfileEmail />, 'Edit account email')
                }
              ]
            }}
          >
            Edit profile
          </Dropdown.Button>
        )}
      />

      <Row wrap={innerWidth < 992}>
        <Col flex='auto' {...desCol}>
          <Desc>
            <Desc.Item label='Last name'>
              {Utils.toTitleCase(user.last_name) || '-'}
            </Desc.Item>
            <Desc.Item label='First name'>
              {Utils.toTitleCase(user.first_name) || '-'}
            </Desc.Item>
            <Desc.Item label='Middle name'>
              {Utils.toTitleCase(user.middle_name) || '-'}
            </Desc.Item>
            <Desc.Item label='Birthday'>
              <Tooltip title={user.birthdate ? Utils.getAge(user.birthdate) : '-'}>
                <Timestamp stamp={user.birthdate} ifEmpty='-' format='MMMM D, YYYY' />
              </Tooltip>
            </Desc.Item>
            <Desc.Item label='Mobile'>
              {user.mobile || '-'}
            </Desc.Item>
            <Desc.Item label='Email'>
              {user.email || '-'}
            </Desc.Item>
            <Desc.Item label='Sex'>
              {Utils.toTitleCase(user.sex) || '-'}
            </Desc.Item>
            <Desc.Item label='Civil status'>
              {Utils.toTitleCase(user.civil_status) || '-'}
            </Desc.Item>
            <Desc.Item label='Religion'>
              {Utils.toTitleCase(user.religion) || '-'}
            </Desc.Item>
          </Desc>
        </Col>
        <Col {...imgCol}>
          <Avatar size={132} className={s.profileavatar} />
        </Col>
      </Row>

      <Banko />

      <Desc
        label='Address'
        extra={(
          <Button
            onClick={toDrawer(<ProfileAddress />, 'Change current address')}
            type='link'
          >
            Edit
          </Button>
        )}
      >
        <Desc.Item label='House/Subdivision'>
          {address.housesubd || '-'}
        </Desc.Item>
        <Desc.Item label='Zip Code'>
          {address.zipcode || '-'}
        </Desc.Item>
        <Desc.Item label='Barangay'>
          {Utils.toTitleCase(address.barangay || '-')}
        </Desc.Item>
        <Desc.Item label='Town/City'>
          {Utils.toTitleCase(address.towncity || '-')}
        </Desc.Item>
        <Desc.Item label='Province'>
          {Utils.toTitleCase(address.province || '-')}
        </Desc.Item>
        <Desc.Item label='Region'>
          {Utils.toTitleCase(address.region || '-')}
        </Desc.Item>
        <Desc.Item>
          <GoogleMap
            theme={Address.theme}
            values={{ zoom, lat, lng, center: [lat, lng] }}
            reCenter
            readOnly
          />
          <br />
        </Desc.Item>
      </Desc>

      <Desc
        label='Documents'
        extra={(
          <Button
            onClick={toDrawer(<ProfileDocuments />, 'Update document photos')}
            type='link'
          >
            Edit
          </Button>
        )}
      >
        <Desc.Item label='Proof of identification'>
          <Picture
            width={200}
            height={200}
            src={user.proof_of_identification || ''}
          />
        </Desc.Item>
        <Desc.Item label='Proof of address'>
          <Picture
            width={200}
            height={200}
            src={user.proof_of_address || ''}
          />
        </Desc.Item>
        <Desc.Item label='Proof of financial capacity'>
          <Picture
            width={200}
            height={200}
            src={user.proof_of_financial || ''}
          />
        </Desc.Item>
      </Desc>
    </Dashboard>
  );
};

export default ProfileView;
