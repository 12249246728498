import { useContext, useEffect, useState } from 'react';
import { Button, Dropdown, Collapse, Typography, Tag } from 'antd';
import { Dashboard, DataList, Timestamp, Header, Desc, Ellipsis } from '../../components';
import { Context, Utils } from 'shookt';
import { FiChevronLeft, FiChevronDown } from 'react-icons/fi';
import pricingGet from './pricingGet';
import pricingPost from './pricingPost';
import PricingForm from './PricingForm';
import PriceList from './price/PriceList';
import productGet from '../product/productGet';

const PricingView = props => {
  const [state, dispatch] = useContext(Context);
  const [status, setStatus] = useState(0);
  const [values, setValues] = useState({});
  const [params, setParams] = useState({});
  const [pricing, setPricing] = useState({});
  const [products, setProducts] = useState(null);
  const [spinning, setSpinning] = useState(false);
  const toDrawer = Dashboard.toDrawer(dispatch);

  const enablePricing = async () => {
    const { status } = await pricingPost({
      is_enabled: !pricing.is_enabled,
      id: pricing.id
    });
    if (status === 200) {
      setTimeout(() => {
        loadData();
      }, 100);
    }
  };

  const loadData = () => {
    (async () => {
      const { status, data } = await pricingGet(props.params.id);
      if (status === 200) {
        setPricing(data);
      }
      setStatus(status);
    })();
  };

  useEffect(() => {
    !products && setSpinning(true);
    if (state.reload) {
      dispatch({ delete: ['reload'] });
    }
    loadData();
  }, [state.reload]);

  useEffect(() => {
    if (params && params.page) {
      !products && setSpinning(true);
      dispatch({ upsert: { loading: true } });
      setTimeout(async () => {
        const { data } = await productGet('all', {
          company: pricing.company_id,
          pricing: pricing.id,
          find: params.find,
          sort: params.sort,
          page: params.page || 1,
          pageSize: state.pageSize
        });
        setSpinning(false);
        setProducts(data.data || []);
        setValues(data);
      }, 200);
    }
  }, [params]);

  return (
    <Dashboard
      viewspin
      status={status}
      back='/pricing/all'
    >
      <Header
        noTop
        title={(
          <>
            <Button
              shape='circle'
              onClick={() => window.history.back()}
              icon={<FiChevronLeft />}
            />
            <Typography.Title className='no-bott-margin' level={4}>
              &nbsp;{pricing.name}
            </Typography.Title>
          </>
        )}
        extra={(
          <Dropdown.Button
            onClick={toDrawer(<PricingForm id={pricing.id} key={pricing.id} />, 'Update pricing information')}
            trigger={['click']}
            icon={<FiChevronDown />}
            menu={{
              items: [{
                key: 'enable',
                label: pricing.is_enabled ? <>Set this pricing <Tag>Offline</Tag></> : <>Set this pricing as <Tag color='green'>LIVE</Tag></>,
                onClick: enablePricing
              }]
            }}
          >
            Edit pricing
          </Dropdown.Button>
        )}
      />
      <Collapse key={pricing.id} bordered={false} expandIconPosition='end'>
        <Collapse.Panel
          header={(
            <Ellipsis>
              <Tag color={pricing.is_enabled ? 'green' : ''}>
                {pricing.is_enabled ? 'LIVE' : 'Offline'}
              </Tag>
              {pricing.description}
            </Ellipsis>
          )}
        >
          <Desc>
            <Desc.Item label='Name'>
              {pricing.name}
            </Desc.Item>
            <Desc.Item label='Last update'>
              <Timestamp stamp={pricing.updated_at} />
            </Desc.Item>
            <Desc.Item label='Last update by'>
              {pricing.updated_by && pricing.updated_by.id && (Utils.formatName(pricing.updated_by))}
            </Desc.Item>
            <Desc.Item label='ID'>
              {pricing.id}
            </Desc.Item>
            <Desc.Item label='Created at'>
              <Timestamp stamp={pricing.created_at} />
            </Desc.Item>
            <Desc.Item label='Created by'>
              {pricing.created_by && pricing.created_by.id && (Utils.formatName(pricing.created_by))}
            </Desc.Item>
            {
              pricing.company &&
              pricing.company.id &&
              state.user &&
              state.user.privileges.company && (
                <Desc.Item label='Distributor'>
                  {pricing.company.name}
                </Desc.Item>
              )
            }
          </Desc>
        </Collapse.Panel>
      </Collapse>
      <Header
        title='Products'
        bottom={5}
      />
      <DataList
        noHover
        noPadding
        noBottBox
        values={values}
        spinning={spinning}
        onChange={setParams}
        countLabel='products'
        dataSource={products || []}
        bodyHandler={item => <PriceList product={item} pricing={pricing} />}
        sortOptions={[
          { label: 'Newest', value: { column: 'created_at', order: 'desc' } },
          { label: 'Oldest', value: { column: 'created_at', order: 'asc' } },
          { label: 'Updated', value: { column: 'updated_at', order: 'desc' } },
          { label: 'Name A-Z', value: { column: 'name', order: 'asc' } },
          { label: 'Name Z-A', value: { column: 'name', order: 'desc' } }
        ]}
      />
    </Dashboard>
  );
};

export default PricingView;
