import page from 'page';
import { useState, useContext } from 'react';
import { Context, Captcha, Erratum, Code6, Mobile, Utils } from 'shookt';
import { Typography, Button, Result, Radio, Form, Input } from 'antd';
import { AiOutlineMail } from 'react-icons/ai';
import { authenticate } from '../../session';
import { Panel } from '../../components';
import verifyPut from './verifyPut';
import verifyPost from './verifyPost';

const Register = props => {
  const [state, dispatch] = useContext(Context);
  const [done, setDone] = useState('');
  const [phone, setPhone] = useState('');
  const [idMode, setIdMode] = useState('email');
  const [form] = Form.useForm();

  const onFinishCode = async values => {
    const { status } = await verifyPut({ code: values.register_code });
    if (status === 200) {
      await authenticate(dispatch);
      page(state.SETUPPATH);
    }
  };

  const onFinish = async values => {
    if (idMode === 'mobile' && !phone) {
      return dispatch({ upsert: { error: 'Please enter a valid mobile number.' } });
    }
    const { status } = await verifyPost({
      email: values.register_email,
      mobile: phone
    });
    if (status === 200) {
      setDone(idMode);
    }
  };

  const onIdChange = e => {
    setPhone('');
    setIdMode(e.target.value);
    dispatch({ delete: ['error'] });
    form.resetFields();
  };

  let idInput;

  if (idMode === 'mobile') {
    idInput = (
      <Form.Item>
        <Mobile onPhone={setPhone} country='ph' className='ant-input' />
      </Form.Item>
    );
  }

  if (idMode === 'email') {
    idInput = (
      <Form.Item
        name='register_email'
        rules={[{
          type: 'email',
          required: true,
          message: 'Please enter a valid email'
        }]}
      >
        <Input
          prefix={<AiOutlineMail />}
          minLength={10}
          maxLength={80}
          placeholder='Email'
        />
      </Form.Item>
    );
  }

  let body = (
    <Form
      form={form}
      layout='vertical'
      onFinish={onFinish}
      onValuesChange={Utils.clearError(dispatch)}
    >
      <Form.Item>
        <Radio.Group onChange={onIdChange} value={idMode}>
          <Radio value='email'>Email</Radio>
          <Radio value='mobile'>Mobile</Radio>
        </Radio.Group>
      </Form.Item>

      {idInput}

      <Captcha />
      <Erratum />

      <Typography.Paragraph type='secondary' className='text-center'>
        Please read our <a href='/termsofservice'>terms of service</a>, <a href='/privacypolicy'>privacy policy</a>, and <a href='/cookiepolicy'>cookies policy</a> before registering.
        By registering, you therefore agree and consent to these terms, policies, and rules.
      </Typography.Paragraph>

      <Form.Item>
        <Button
          block
          type='primary'
          htmlType='submit'
          disabled={state.loading}
          loading={state.loading}
        >
          Register account
        </Button>
      </Form.Item>

      <Button href='/login' type='link' block>
        Already registered?
      </Button>
    </Form>
  );

  if (done === 'email') {
    body = (
      <Result
        status='success'
        title='Verification Sent'
        subTitle={
          <>
            <Typography.Paragraph>
              An email verification was sent to your email address. Please click the link inside that email to continue.
            </Typography.Paragraph>

            <Typography.Paragraph type='warning'>
              It may take some time for the email to arrive. Check your spam folder as well just in case.
            </Typography.Paragraph>
          </>
        }
      />
    );
  }

  if (done === 'mobile') {
    body = (
      <Form
        layout='vertical'
        onFinish={onFinishCode}
        onValuesChange={Utils.clearError(dispatch)}
      >
        <Typography.Paragraph type='secondary' className='text-center'>
          A verification code was sent to your mobile. Please enter the code below to continue.
        </Typography.Paragraph>

        <Form.Item
          label='Verification Code &nbsp;'
          name='register_code'
          className='text-center'
          rules={[{
            required: true,
            message: 'Please enter a valid code'
          }]}
        >
          <Code6 centered='true' />
        </Form.Item>

        <Captcha />
        <Erratum />

        <Form.Item>
          <Button
            block
            type='primary'
            htmlType='submit'
            disabled={state.loading}
            loading={state.loading}
          >
            Continue
          </Button>
        </Form.Item>

        <Typography.Paragraph type='warning' className='text-center'>
          It may take some time for the SMS to arrive.
        </Typography.Paragraph>
      </Form>
    );
  }

  return (
    <Panel style={{ maxWidth: 344 }}>
      {body}
    </Panel>
  );
};

export default Register;
