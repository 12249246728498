import { useContext, useEffect, useState } from 'react';
import { Form, Select } from 'antd';
import { Context } from 'shookt';
import s from './provinceselect.module.less';
import provinceGet from '../../pages/province/provinceGet';

const ProvinceSelect = props => {
  const [state] = useContext(Context);
  const [provinceOptions, setProvinceOptions] = useState([]);
  const [province, setProvince] = useState(null);

  const onChange = (val = null, id = null, label=null) => { // Add an "id" parameter
    props.onChange && props.onChange(val || 0, id); // Pass the "id" value
    if (val) {
      sessionStorage.setItem(`_province`, val);
      sessionStorage.setItem(`_provinceopt`, label);
      sessionStorage.removeItem('_company');
      sessionStorage.removeItem('_companyopt');
    
    } 
    setProvince(val);
    
  };
  useEffect(() => {
    (async () => {
      
        // Fetch province options using provinceGet() if session storage value is not available
        if (!state.province) {
        const { status, data } = await provinceGet();
        if (status === 200) {
          // Filter the data array to include only distinct provinces
          const uniqueProvinces = [...new Set(data.map(i => i.province))];
          

          setProvinceOptions(
            uniqueProvinces.map(province => ({
              key: `${data.find(i => i.province === province).model_id}_${data.find(i => i.province === province).id}`,
              label: province,
              value: province,
              id: data.find(i => i.province === province).model_id
            }))
          );
        }
      }
      
    })();
  }, []);
  

  let provincedt;
  const prov = sessionStorage.getItem(`_provinceopt`);
  if (prov) {
    provincedt=prov;
  }
   else {
    provincedt = province;
  }

  let body = (
    <Select
      showSearch
      value={provincedt}
      style={props.style || {}}
      options={provinceOptions}
      onChange={(val, option) => onChange(val, option.id,option.label)}
      disabled={props.disabled}
      className={s.select}
      allowClear
      placeholder='Select Province'
      optionFilterProp='children'
      filterOption={(input, option) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
    />
  );

  if (props.name) {
    body = (
      <Form.Item
        name={props.name}
        label={props.label || 'Province'}
        rules={props.rules || []}
      >
        {body}
      </Form.Item>
    );
  }

  return body;
};

ProvinceSelect.get =  (props) => {
  
  return <ProvinceSelect {...props} />;
};

export default ProvinceSelect;
