import { useState, useContext } from 'react';
import { Context, Captcha, Erratum, Utils } from 'shookt';
import { Form, Input, Button, Result, Typography } from 'antd';
import s from './profile.module.less';
import verifyPost from '../verify/verifyPost';

const ProfileEmail = props => {
  const [state, dispatch] = useContext(Context);
  const [done, setDone] = useState(false);
  const [form] = Form.useForm();

  const onFinish = async values => {
    if (state.user.email === values.editprof_email) {
      return dispatch({ delete: ['drawer'] });
    }
    const { status } = await verifyPost({ email: values.editprof_email });
    if (status === 200) {
      setDone(true);
    }
  };

  let body = (
    <Form
      form={form}
      layout='vertical'
      onFinish={onFinish}
      className={s.profileminform}
      onValuesChange={Utils.clearError(dispatch)}
    >
      <Form.Item
        label='New email address'
        name='editprof_email'
        rules={[{
          type: 'email',
          required: true,
          message: 'Please enter a valid email'
        }]}
      >
        <Input
          minLength={10}
          maxLength={80}
          placeholder='New email address'
        />
      </Form.Item>

      <Captcha />
      <Erratum />

      <Form.Item>
        <Button
          block
          type='primary'
          htmlType='submit'
          disabled={state.loading}
          loading={state.loading}
        >
          Save changes
        </Button>
      </Form.Item>
    </Form>
  );

  if (done) {
    body = (
      <Result
        status='success'
        title='Verification Sent'
        className={s.profileemailres}
        subTitle={
          <>
            <Typography.Paragraph>
              An email verification was sent to your email address.<br />
              Please click the link inside that email to continue.
            </Typography.Paragraph>
            <Typography.Paragraph type='warning'>
              It may take some time for the email to arrive.<br />
              Check your spam folder as well just in case.
            </Typography.Paragraph>
          </>
        }
      />
    );
  }

  return body;
};

export default ProfileEmail;
