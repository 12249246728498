import Register from './Register';
import Verify from './Verify';

export const registerRoute = {
  path: '/register',
  code: 'register',
  auth: false,
  handler: props => <Register {...props} />
};

export const verifyRoute = {
  path: '/verify/:code',
  code: 'verify',
  handler: props => <Verify {...props} />
};
