import { useState, useContext } from 'react';
import { Context, Captcha, Code6, Erratum, Mobile, Utils } from 'shookt';
import { Typography, Radio, Button, Form, Input, Result } from 'antd';
import { AiOutlineMail } from 'react-icons/ai';
import { Panel } from '../../components';
import recoverPost from './recoverPost';
import recoverPut from './recoverPut';
import Reclaim from './Reclaim';

const Forgot = props => {
  const [state, dispatch] = useContext(Context);
  const [done, setDone] = useState('');
  const [code, setCode] = useState('');
  const [phone, setPhone] = useState('');
  const [idMode, setIdMode] = useState('email');
  const [form] = Form.useForm();

  const onFinishCode = async values => {
    setCode(values.forgot_code);
    const { status } = await recoverPut({ code: values.forgot_code });
    if (status === 200) {
      setDone('claim');
    }
  };

  const onFinish = async values => {
    if (idMode === 'mobile' && !phone) {
      return dispatch({ upsert: { error: 'Please enter a valid mobile number.' } });
    }
    const { status } = await recoverPost({
      email: values.forgot_email,
      mobile: phone
    });
    if (status === 200) {
      setDone(idMode);
    }
  };

  const onIdChange = e => {
    setPhone('');
    setIdMode(e.target.value);
    dispatch({ delete: ['error'] });
    form.resetFields();
  };

  let idInput;

  if (idMode === 'mobile') {
    idInput = (
      <Form.Item>
        <Mobile onPhone={setPhone} country='ph' className='ant-input' />
      </Form.Item>
    );
  }

  if (idMode === 'email') {
    idInput = (
      <Form.Item
        name='forgot_email'
        rules={[{
          type: 'email',
          required: true,
          message: 'Please enter a valid email'
        }]}
      >
        <Input
          prefix={<AiOutlineMail />}
          minLength={10}
          maxLength={80}
          placeholder='Email'
        />
      </Form.Item>
    );
  }

  let body = (
    <Form
      layout='vertical'
      onFinish={onFinish}
      onValuesChange={Utils.clearError(dispatch)}
    >
      <Form.Item>
        <Radio.Group onChange={onIdChange} value={idMode}>
          <Radio value='email'>Email</Radio>
          <Radio value='mobile'>Mobile</Radio>
        </Radio.Group>
      </Form.Item>

      {idInput}

      <Captcha />
      <Erratum />

      <Form.Item>
        <Button
          block
          type='primary'
          htmlType='submit'
          disabled={state.loading}
          loading={state.loading}
        >
          Send recovery code
        </Button>
      </Form.Item>

      <Button href='/login' type='link' block>
        Login account
      </Button>
    </Form>
  );

  if (done === 'claim') {
    body = <Reclaim code={code} />;
  }

  if (done === 'email') {
    body = (
      <Result
        status='info'
        title='Recovery in Progress'
        subTitle={
          <>
            <Typography.Paragraph>
              If an account is found with the provided contact detail, a recovery code will be sent.
            </Typography.Paragraph>

            <Typography.Paragraph type='warning'>
              It may take some time for the email to arrive. Check your spam folder as well just in case.
            </Typography.Paragraph>
          </>
        }
      />
    );
  }

  if (done === 'mobile') {
    body = (
      <Form
        form={form}
        layout='vertical'
        onFinish={onFinishCode}
        onValuesChange={Utils.clearError(dispatch)}
      >
        <Typography.Paragraph type='secondary' className='text-center'>
          If an account is found with the provided contact detail, a recovery code will be sent.
        </Typography.Paragraph>

        <Form.Item
          label='Recovery Code &nbsp;'
          name='forgot_code'
          className='text-center'
          rules={[{
            required: true,
            message: 'Please enter a valid code'
          }]}
        >
          <Code6 centered='true' />
        </Form.Item>

        <Captcha />
        <Erratum />

        <Form.Item>
          <Button
            block
            type='primary'
            htmlType='submit'
            disabled={state.loading}
            loading={state.loading}
          >
            Continue
          </Button>
        </Form.Item>

        <Typography.Paragraph type='warning' className='text-center'>
          It may take some time for the SMS to arrive.
        </Typography.Paragraph>
      </Form>
    );
  }

  return (
    <Panel style={{ maxWidth: 344 }}>
      {body}
    </Panel>
  );
};

export default Forgot;
