import { useContext, useEffect, useState } from 'react';
import { Form, Input, InputNumber, Button, Divider, Descriptions, Typography } from 'antd';
import { Context, Erratum, Utils } from 'shookt';
import { Variations } from '../../../components';
import productGet from '../../product/productGet';
import pricePost from './pricePost';
import lodash from 'lodash';

const PriceForm = ({ item, pricing, productId }) => {
  const [state, dispatch] = useContext(Context);
  const [product, setProduct] = useState({ code: '', variations: [] });
  const [form] = Form.useForm();

  const onFinish = async values => {
    const newItem = {
      product_id: productId,
      pricing_id: pricing.id,
      unit_price: values.newpricitem_price,
      variations: Variations.getVariations(values),
      code: values.newpricitem_code ? values.newpricitem_code.trim() : null
    };
    let noChanges = true;
    if (item) {
      newItem.id = item.id;
      const arrayVar = Object.values(item.variations);
      const arrayNewVar = Object.values(newItem.variations);
      noChanges = lodash.isEqual(arrayVar.sort(), arrayNewVar.sort());
    }
    if (!item || !noChanges || newItem.code !== item.code || parseFloat(newItem.unit_price) !== parseFloat(item.unit_price)) {
      const { status, data } = await pricePost(newItem);
      if (status === 200) {
        dispatch({ upsert: { [`reload${productId}`]: true } });
        dispatch({ delete: ['drawer'] });
      }
      if (status === 400 && !Object.keys(newItem.variations).length &&
        data.message.startsWith('Pricing code is already used')) {
        dispatch({ upsert: { error: `${data.message} Product has no variation. You can just edit the current price.` } });
      }
    } else {
      dispatch({ delete: ['drawer'] });
    }
  };

  useEffect(() => {
    (async () => {
      const { data } = await productGet(productId);
      setProduct(data);
      if (item) {
        form.setFieldsValue({
          newpricitem_price: item.unit_price,
          newpricitem_code: item.code
        });
      }
    })();
  }, []);

  return (
    <Form
      form={form}
      layout='vertical'
      onFinish={onFinish}
      onValuesChange={Utils.clearError(dispatch)}
    >
      <Descriptions column={1} colon={false} layout='vertical'>
        <Descriptions.Item label={<Typography.Text type='secondary'>Product</Typography.Text>}>
          {product.code}: {product.name}
        </Descriptions.Item>
        <Descriptions.Item label={<Typography.Text type='secondary'>Pricing</Typography.Text>}>
          {pricing.name}
        </Descriptions.Item>
      </Descriptions>

      <Divider className='no-top-margin' />
      <Variations item={item} product={product} form />

      <Form.Item
        label='Unit price'
        name='newpricitem_price'
        rules={[{
          required: true,
          message: 'Please enter a price above zero'
        }]}
      >
        <InputNumber
          prefix={<>₱</>}
          minLength={1}
          maxLength={15}
          placeholder='0.00'
          controls
        />
      </Form.Item>

      <Form.Item
        label='Price Code'
        name='newpricitem_code'
        rules={[{
          whitespace: true,
          pattern: '^[a-zA-Z0-9ñÑ. ]*$',
          message: 'Please enter a house/subd'
        }]}
      >
        <Input
          minLength={1}
          maxLength={100}
          placeholder='Price code'
        />
      </Form.Item>

      <Erratum />

      <Form.Item>
        <Button
          block
          type='primary'
          htmlType='submit'
          disabled={state.loading}
          loading={state.loading}
        >
          Save new product price
        </Button>
      </Form.Item>
    </Form>
  );
};

export default PriceForm;
