import { Space, Divider } from 'antd';
import { useWindowSize } from 'rooks';
import s from './spacer.module.less';

const Spacer = props => {
  const { innerWidth } = useWindowSize();
  const breakpoint = props.breakpoint || 991;
  return (
    <div className={`${props.noBott ? '' : s.spacer} ${props.className}`} style={props.style}>
      <Space
        direction={props.direction || (innerWidth > breakpoint ? 'horizontal' : 'vertical')}
        size={props.size || (innerWidth > breakpoint && !props.split ? 'middle' : 'small')}
        align={props.align || (innerWidth > breakpoint && props.center ? 'center' : 'start')}
        split={props.split && innerWidth > breakpoint ? <Divider type='vertical' /> : null}
        style={props.block ? { width: '100%' } : {}}
      >
        {props.children}
      </Space>
    </div>
  );
};

export default Spacer;
