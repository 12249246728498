import { useContext, useEffect, useState } from 'react';
import { Typography, Button, Tag, Space, Divider } from 'antd';
import { Context, Utils } from 'shookt';
import { FaMoneyBillWave } from 'react-icons/fa';
import { FiChevronLeft } from 'react-icons/fi';
import { Dashboard, Header, Timestamp, Desc } from '../../components';
import page from 'page';
import orderGet from '../order/orderGet';
import deliveryGet from './deliveryGet';

const DeliveryView = props => {
  const [state, dispatch] = useContext(Context);
  const [status, setStatus] = useState(0);
  const [item, setItem] = useState({ order: {} });

  const loadData = () => {
    (async () => {
      const { status, data } = await deliveryGet(props.params.id);
      if (status === 200) {
        setItem(data);
      }
      setStatus(status);
    })();
  };

  useEffect(() => {
    if (state.reload) {
      dispatch({ delete: ['reload'] });
    }
    loadData();
  }, [state.reload]);

  return (
    <Dashboard
      viewspin
      status={status}
      back='/delivery/all'
    >
      <Header
        noTop
        title={(
          <>
            <Button
              shape='circle'
              onClick={() => window.history.back()}
              icon={<FiChevronLeft />}
            />
            <Typography.Title className='no-bott-margin' level={4}>
              &nbsp;{orderGet.methodCodeToName(item.method) || 'No method yet'} {item.id}
            </Typography.Title>
          </>
        )}
        extra={(
          <Button onClick={() => page(`/order/${item.order_id}`)}>
            View order
          </Button>
        )}
      />
      <Desc>
        <Desc.Item label='Status'>
          <Tag color={orderGet.toStatusColor(item.order && item.order.status_delivery)}>
            <Space align='center'>
              <FaMoneyBillWave />
              {orderGet.statusToLabel(item.order && item.order.status_delivery)}
            </Space>
          </Tag>
        </Desc.Item>
        <Desc.Item label='Reference no.'>
          {item.code || '-'}
        </Desc.Item>
        <Desc.Item label='Created at'>
          <Timestamp stamp={item.created_at} />
        </Desc.Item>
        <Desc.Item label='Order ID'>
          {item.order.code}
        </Desc.Item>
        <Desc.Item label='ID'>
          {item.id}
        </Desc.Item>
        <Desc.Item label='Created by'>
          {item.created_by && item.created_by.id && (Utils.formatName(item.created_by))}
        </Desc.Item>
      </Desc>

      <Divider />

    </Dashboard>
  );
};

export default DeliveryView;
