import { Image } from 'antd';
import fallback from './fallback';
import s from './picture.module.less';

const Picture = props => {
  const { ...image } = props;
  image.src = image.src || fallback;

  return (
    <Image
      {...{ fallback }}
      {...image}
      className={`${s.picture} ${props.className}`}
    // placeholder={<Image src={fallback} />}
    />
  );
};

export default Picture;
